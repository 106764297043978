import React, { Component, createRef } from "react";
import filtersStore from "../../stores/filtersStore";
import playlistStore from "../../stores/playlistStore";
import history from "../common/history.js";
import DemoPlayButton from "../common/DemoPlayButton";
import { Link } from "react-router-dom";
import { ReactComponent as DownloadIcon } from "#images/download-high.svg";
import Download from "../../helpers/Download";

class ItemTile extends Component {
  constructor(props) {
    super(props);
    this.labelNameLink = createRef();
    this.demoButton = createRef();
  }

  redirectToProduct = (e) => {
    e.preventDefault();
    if (
      this.labelNameLink.current == e.target ||
      this.labelNameLink.current?.contains(e.target)
    )
      return;
    if (
      this.demoButton.current == e.target ||
      this.demoButton.current?.contains(e.target)
    )
      return;

    const { item, id, product, path, ignoreParams } = this.props;
    if (item && item.type === "File") {
      this.play(e);
    } else {
      filtersStore.page = 1;
      const params = ignoreParams ? "" : `?${filtersStore.toParams()}`;
      const productId = (product && product.seo_product_id) || id;
      history.push(
        path ? `${path}${productId}${params}` : `/product/${productId}`,
      );
    }
  };

  play(e) {
    const { item } = this.props;
    e.preventDefault();
    if (item.audio) {
      playlistStore.play(item, false);
      playlistStore.traversingMode = "itemlist";
    }
  }

  get cover() {
    return this.props.cover;
  }

  download = (e) => {
    e.stopPropagation();

    const { uuid, name, download_audio } = this.props.item;

    if (!download_audio) {
      console.error("No download in item", uuid);
      return;
    }

    const itemName = name.replace(/[^\.\w\s]+/g, "_");
    Download.attachment(download_audio, itemName);
  };

  render() {
    const { item, myFile, labelID, labelName, product, grid, search, title } =
      this.props;
    const labelId = (product && product.seo_label_id) || labelID;
    return (
      <div className={`${!grid && search ? "pb-2.5" : ""} h-full`}>
        <div
          onClick={this.redirectToProduct}
          className="flex h-full w-full cursor-pointer flex-col"
        >
          <div className="group relative">
            <div className="aspect-w-1 aspect-h-1">
              <img
                src={this.cover || "blank.gif"}
                loading="lazy"
                className="w-full object-cover"
              />
            </div>
            {product?.demo_song_url && (
              <DemoPlayButton
                product={product}
                variant="bottomRight"
                elementRef={this.demoButton}
              />
            )}
          </div>
          <div className="mt-2.5 flex">
            <p className="hover:text-orange-1 line-clamp-2 grow font-bold leading-4 lg:text-sm 2xl:text-[15px] lg:h-10">
              {title}
            </p>
            {myFile && item.type === "File" && (
              <div className="ml-2">
                <DownloadIcon width="16" height="20" onClick={this.download} />
              </div>
            )}
          </div>
          <div className="text-gray-4 mt-auto text-[13px] font-bold lg:text-xs 2xl:text-[11px]">
            <span
              className={`hover:text-orange-1 line-clamp-1 ${!grid && search ? "mt-2.5" : ""}`}
            >
              {labelID ? (
                <Link to={"/label/" + labelId} ref={this.labelNameLink}>
                  {labelName}
                </Link>
              ) : (
                labelName
              )}
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default ItemTile;

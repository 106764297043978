import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { MODALS } from '#stores/modalStore'

@inject('modalStore')
@observer
class ModalRenderer extends Component {
  render() {
    const { visibleModal, props } = this.props.modalStore
    const RenderableModal = MODALS[visibleModal]
    return <RenderableModal { ...props} />
  }
}

export default ModalRenderer
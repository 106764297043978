import React from 'react'
import Browser from '../common/Browser'
import { inject, observer } from 'mobx-react'
import { Slide } from "pure-react-carousel"
import SmallSliderLoader from '../loaders/SmallSliderLoader'

@inject('homeStore')
@observer
class HomeBrowser extends Browser {
  get data() {
    const { homeStore, collectionName, genreId } = this.props

    if (genreId) {
      return homeStore.userGenres.find((genre) => genre.id === genreId)
    } else {
      return homeStore[collectionName]
    }
  }

  get title() { return this.data.title }

  get totalEntries() { return this.data.ids.length }

  get products() { return this.data.products }

  get collections() { return this.data.array }

  beforeProductsHook() {
    const { homeStore, collectionName } = this.props

    if (homeStore[collectionName] && homeStore[collectionName].isLoading) {
      return [0, 1, 2, 3, 4, 5].map((slideIndex) => (
        <Slide key={slideIndex} index={slideIndex} className='slide'>
          <SmallSliderLoader />
        </Slide>
      ))
    }

    return false
  }
}

export default HomeBrowser

import React, { Component, createRef } from 'react'
import { inject, observer } from 'mobx-react'
import { Motion, spring } from 'react-motion'
import ContextMenuItem from './ContextMenuItem'
import collectionStore from '#stores/collectionStore'
import { ReactComponent as CancelCross } from '#images/cancel-cross.svg'
import modalStore from '#stores/modalStore'

@inject('myCollectionsMenuStore')
@observer
class CloudItemMenu extends Component {
  componentDidMount() {
    this.menuRef = createRef()

    const { myCollectionsMenuStore } = this.props
    if (myCollectionsMenuStore.menu.length === 0) myCollectionsMenuStore.fetch()

    document.addEventListener("click", this.onClick)
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.onClick)
  }

  closeAction = (e) => {
    this.menuRef.current && this.menuRef.current.scrollTo(0, 0)
    this.props.myCollectionsMenuStore.cleanUp()
    modalStore.hide()
  }

  onClick = (e) => {
    if (this.menuRef.current == e.target || this.menuRef.current.contains(e.target)) return

    this.closeAction()
  }

  get menu() {
    const { myCollectionsMenuStore } = this.props

    return myCollectionsMenuStore.menu.map((collection) => {
      let checked = !!myCollectionsMenuStore.itemMenu.find((im) => { return im.id === collection.id })

      return (
        <ContextMenuItem
          key={collection.id}
          collection={{...collection}}
          checked={checked}
          closeAction={this.closeAction}
          removalCallback={this.props.fetchProductItems}
        />
      )
    });
  };

  get menuWidth() {
    return 222
  }

  yPosition(yPos, clientY) {
    if (clientY + 300 > document.documentElement.clientHeight) {
      return (yPos - 260)+"px"
    } else {
      return (yPos - 15)+"px"
    }
  }

  cross = () => (
    <div
      onClick={this.closeAction}
      className="min-w-[20px] max-w-[20px] ml-1.5"
    >
      <CancelCross className='[&_path]:fill-gray-5 rotate-45 scale-75 group-hover:brightness-75' />
    </div>
  )

  get desktopStyle() {
    if (window.innerWidth < 768) return {}

    const { yPos, xPos, clientY } = this.props.myCollectionsMenuStore
    return {
      width: this.menuWidth+"px",
      top: this.yPosition(yPos, clientY),
      left: `${xPos + 10 - this.menuWidth}px`,
    }
  }

  render() {
    return (
      <Motion
        defaultStyle={{ opacity: 0 }}
        style={{ opacity: spring(1) }}
      >
        {
          motionValues => (
            <div
              ref={this.menuRef}
              style={{ opacity: motionValues.opacity, ...this.desktopStyle }}
              className='fixed flex flex-col inset-y-0 inset-x-0 bg-gray-16 z-50 px-5 py-9 md:absolute md:inset-x-auto md:inset-y-auto md:h-[300px] md:bg-gray-15 md:p-3 md:border-gray-12 md:border md:rounded'
            >
              <div className='flex items-center mb-9 h-fit md:hidden'>
                <span
                  className='font-bold text-[28px] leading-5'
                >
                  Item collections
                </span>
                <button
                  type='button'
                  className='ml-auto'
                  onClick={this.closeAction}
                >
                  <CancelCross className='brightness-125 w-[22px] h-[22px]' />
                </button>
              </div>

              <div
                className='flex flex-col overflow-y-scroll overflow-x-hidden max-h-5/6 cursor-pointer [scrollbar-width:none] [&::-webkit-scrollbar]:w-0'
              >
                <div
                  className='flex mb-1 hover:bg-beige-1 hover:text-gray-16 group rounded text-lg md:text-base py-0.5'
                  onClick={collectionStore.showCreateModal}
                >
                  <button
                    type='button'
                    className='pr-0.5'
                  >
                    {this.cross()}
                  </button>
                  <span className='ml-4'>
                    New Collection
                  </span>
                </div>

                {this.menu}
              </div>

              <button
                type='button'
                className='mt-6 h-fit bg-orange-1 text-[22px] font-medium text-gray-16 py-1 rounded-[40px] md:hidden'
                onClick={this.closeAction}
              >
                OK
              </button>
            </div>
          )
        }
      </Motion>
    )
  }
}

export default CloudItemMenu;

import axios from 'axios'
import { Cookies } from 'react-cookie'
import history from '#components/common/history.js'
import authStore from '#stores/authStore'

console.log(process.env)
const apiEnv = new URL(document.location.href).searchParams.get('apiEnv') || (window.location.hostname == 'localhost' ? 'local' : null)
// const apiEnv = 'local'
const envUrls = {
  local: {
    cloud: 'http://localhost:3001',
    loop: 'http://localhost:3000'
  },
  development: {
    cloud: 'https://dev-web.loopcloud.com',
    loop: 'https://dev.loopcloud.com'
  },
  staging: {
    cloud: 'https://staging-cloud.loopmasters.com',
    loop: 'https://staging.loopmasters.com'
  },
  production: {
    cloud: 'https://cloud.loopmasters.com',
    loop: 'https://loopcloud.com'
  }
}
export const cloudUrl = apiEnv ? envUrls[apiEnv].cloud : process.env.REACT_APP_API_URL
export const loopUrl = apiEnv ? envUrls[apiEnv].loop : process.env.REACT_APP_LOOP_API_URL

const axiosInstance = axios.create({
  baseURL: cloudUrl
});

const loopAxiosInstance = axios.create({
  baseURL: loopUrl
});

const tokenReq = { fetching: false };
window.tokenReq = tokenReq;

export const tokenExpired = () => {
  const expiresAt = parseInt(authStore.tokenExpiresAt)

  return (expiresAt - (Date.now() / 1000) < 1)
}

export const refreshOAuthToken = () => {
  const token = authStore.token
  const refreshToken = authStore.refreshToken
  const refreshInstanceUrl = apiEnv ? envUrls[apiEnv].cloud : process.env.REACT_APP_API_URL
  const refreshInstance = axios.create({ baseURL: refreshInstanceUrl })
  refreshInstance.defaults.headers.Authorization = `Token ${token}`

  return refreshInstance.post('/api-web/v1/refresh_token', { 'refresh_token': refreshToken })
};

const cookieDomain = () => {
  if(apiEnv === 'local') {
    return 'localhost';
  } else {
    let tokens = loopUrl.split('.');
    let len = tokens.length;
    return '.' + tokens[len-2] + '.' + tokens[len-1];
  }
};

async function fetchToken() {
  window.tokenReq.fetching = true
  let newToken = null
  try {
    const { data } = await refreshOAuthToken()
    authStore.setTokens({
      token: data.access_token,
      tokenExpiresAt: data.expires_at,
      refreshToken: data.refresh_token
    })
    newToken = data.access_token
  } catch (e) {
    console.error("token: failed", e);
    console.warn("token: removing token...")
    removeToken();
    window.tokenReq.fetching = false;
    return history.push('/');
  }
  axiosInstance.defaults.headers.Authorization = `Token ${newToken}`
  axiosInstance.defaults.headers.common['Authorization'] = `Token ${newToken}`
  window.tokenReq.fetching = false
  // Fix adblocks and latency problems
  setTimeout(function(){ return window.history.go(0); }, 700)
  return newToken;
}

axiosInstance.interceptors.request.use(
  config => {
    if (!config.headers.Authorization && authStore.token) {
      config.headers.Authorization = `Token ${authStore.token}`
    }
    if (config.headers.Authorization && tokenExpired() && !window.tokenReq.fetching) {
      const newToken = fetchToken()
      config.headers.Authorization = `Token ${newToken}`
    }
    return config;
  },
  error => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  response => response,
  (error) => {
    const originalRequest = error.config
    if (error.response && error.response.status === 401 && authStore.refreshToken && !originalRequest._retry) {
      originalRequest._retry = true
      originalRequest.headers.Authorization = `Token ${authStore.token}`
      return axios(originalRequest)
    }
    return Promise.reject(error.response && error.response.data);
  },
);

const setAuthenticationToken = () => {
  const cookies = new Cookies()
  const urlParams = new URL(document.location.href).searchParams
  const token = urlParams.get('laccess_token') || cookies.get('laccess_token')
  const expiresAt = urlParams.get('ltoken_expires_at') || cookies.get('ltoken_expires_at')
  const refreshToken = urlParams.get('lrefresh_token') || cookies.get('lrefresh_token')

  if(token && expiresAt) {
    authStore.setTokens({
      token,
      tokenExpiresAt: expiresAt,
      refreshToken

    })

    var domain = cookieDomain();
    cookies.set('laccess_token', token, { domain: domain, path: '/' });

    urlParams.delete('laccess_token')
    urlParams.delete('ltoken_expires_at')
    urlParams.delete('lrefresh_token')
    history.replace({
      search: urlParams.toString()
    })

    axiosInstance.defaults.headers.common['Authorization'] = `Token ${token}`;
  }
};

const removeToken = () => {
  if(authStore.token) {
    authStore.resetTokens()
  }
  var cookies = new Cookies()
  if(cookies.get('laccess_token')) {
    var domain = cookieDomain()
    cookies.remove('laccess_token', { domain })
  }
};

const createLoopAPI = () => {
  const { token } = authStore
  if (token) {
    loopAxiosInstance.defaults.headers.Authorization = `Token ${token}`
  }
  return loopAxiosInstance;
}

export const APIFunctions = { setAuthenticationToken, createLoopAPI, removeToken, cookieDomain}

export default axiosInstance


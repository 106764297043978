import React, { Component } from "react"
import ContentLoader from "react-content-loader"

export default class SmallSliderLoader extends Component {
  get calculateWidth() {
    return Math.min((window.visualViewport.width - 440), 1480) * 0.156
  }

  get calculateHeight() {
    return Math.min((window.visualViewport.width - 440), 1480) * 0.222
  }

  render() {
    return <ContentLoader
        speed={2}
        width={this.calculateWidth}
        height={this.calculateHeight}
        viewBox="0 0 250 315"
        backgroundColor="#363f45"
        foregroundColor="#e0dceb"
        {...this.props}
      >
        <rect x="0" y="0" rx="4" ry="4" width="240" height="240" />
        <rect x="0" y="260" rx="4" ry="4" width="240" height="20" />
        <rect x="0" y="290" rx="4" ry="4" width="240" height="15" />
      </ContentLoader>
  }
}

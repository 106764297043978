import { action, extendObservable } from 'mobx';
import API from '../config/api';
import { API_ROUTES } from '../config/api_routes';

const initialState = {
  isLoading: false,
  labels: [],
  error: null,
};

export class labelsStore {
  constructor() {
    extendObservable(this, initialState);
  }

  @action reset = () => {
    Object.keys(initialState).forEach((key) => {
      this[key] = initialState[key];
    });
  };

  @action fetchLabels = async () => {
    this.isLoading = true;
    try {
      const { data } = await API(API_ROUTES.LABELS);
      this.labels = data.tags;
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }
  };
}

export default new labelsStore();

import React from 'react'

import { Link } from 'react-router-dom'

export default function MobileIconLink({ to, icon }) {
  return (
    <li className={`2xl:hidden`}>
      <Link to={to}>
        <img src={icon} className='pl-9.5' />
      </Link>
    </li>
  )
}

import { ReactComponent as MagnifierIcon } from '#images/magnifier.svg'

export default function MagnifierButton({ toggleMobileSearchMenu }) {
  return (
    <li className='md:hidden'>
      <button type='button' onClick={toggleMobileSearchMenu}>
        <MagnifierIcon className='cursor-pointer text-white' />
      </button>
    </li>
  )
}

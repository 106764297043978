import { action, extendObservable } from 'mobx';

const initialState = {
  item: null
};

export class favouriteStore{
  constructor() {
    extendObservable(this, initialState);
  }

  @action markItem = (updatedItem) => {
    this.item = updatedItem
  }
}

export default new favouriteStore();

import { action, computed, extendObservable } from 'mobx'


const initialState = {
  token: localStorage.getItem('oauth_token') || '',
  tokenExpiresAt: localStorage.getItem('oauth_token_expires_at') || '',
  refreshToken: localStorage.getItem('oauth_refresh_token') || '',
}

export class authStore {
  constructor() {
    extendObservable(this, initialState)
  }

  @action setTokens = ({ token, tokenExpiresAt, refreshToken }) => {
    this.token = token
    this.tokenExpiresAt = tokenExpiresAt
    this.refreshToken = refreshToken
    localStorage.setItem('oauth_token', token)
    localStorage.setItem('oauth_token_expires_at', tokenExpiresAt)
    localStorage.setItem('oauth_refresh_token', refreshToken)
  }

  @action resetTokens = () => {
    Object.keys(initialState).forEach((key) => {
      this[key] = ''
    })
    localStorage.removeItem('oauth_token')
    localStorage.removeItem('oauth_token_expires_at')
    localStorage.removeItem('oauth_refresh_token')
  }

  @computed get isLoggedIn() {
    return !!(this.token.trim())
  }
}

export default new authStore()

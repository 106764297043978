import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import PlayIcon from '#images/demo-play.svg'
import PauseIcon from '#images/demo-pause.svg'

@inject('playlistStore')
@observer
class DemoPlayButton extends Component {
  get playingDemo() {
    const { product, playlistStore } = this.props
    const { currentlyPlays, isPlaying } = playlistStore

    return currentlyPlays && isPlaying && currentlyPlays.demo_for == product.id
  }

  togglePlay = () => {
    const { product, playlistStore } = this.props
    this.playingDemo ? playlistStore.pause() : playlistStore.playDemo(product)
  }

  get icon() { return this.playingDemo ? PauseIcon : PlayIcon }

  get variantClasses() {
    return {
      get center() { return `top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 max-w-[80px] max-h-[80px]` },
      get bottomRight() { return `bottom-0 right-0 mb-2 mr-2 max-w-[50px] max-h-[50px]` },
      get bottomRightToCenter() { return `${this.bottomRight} sm:bottom-auto sm:right-auto sm:mb-0 sm:mr-0 sm:top-1/2 sm:left-1/2 sm:-translate-y-1/2 sm:-translate-x-1/2 sm:max-w-[80px] sm:max-h-[80px]` }
    }
  }

  get variant () { return this.props.variant || 'center' }

  render() {
    return (
      <div
        onClick={this.togglePlay}
        className={`group-hover:block absolute cursor-pointer ${this.variantClasses[this.variant] ?? ''} ${this.props.className || ''} ${this.playingDemo ? '' : 'lg:hidden'}`}
        ref={this.props.elementRef}
      >
        <img src={this.icon} />
      </div>
    );
  }
}

export default DemoPlayButton;

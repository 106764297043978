import { action, extendObservable } from 'mobx';
import API from '../config/api';
import { API_ROUTES } from '../config/api_routes';
import packsStore from './packsStore';
import qs from 'qs';

const initialState = {
  page: 1,
  related: [],
  perPage: 60,
  pages_count: 0,
  total: 0,
  isLoading: false,
  byId: {} // collection uuid => collection's data
};

export class relatedStore {
  constructor() {
    extendObservable(this, initialState);
  }

  get currentSlice() {
    return this.related.slice((this.page -1) * this.perPage, this.page * this.perPage);
  }

  currentPageIds(key) {
    return this.currentSlice.filter((item) => item[key]).map((item) => item[key]);
  }

  @action reset = () => {
    Object.keys(initialState).forEach((key) => {
      this[key] = initialState[key];
    });
  };

  get currentRelated() {
    const formatted = this.currentSlice.map((item) => {
      if(item["collection"]) {
        return this.byId[item["collection"]];
      } else if(item["product"]) {
        return packsStore.byId[item["product"]];
      }
      return null;
    });
    return formatted.filter((item) => item);
  }

  @action fetch = async (params = {}) => {
    this.isLoading = true;
    if (this.total === 0) this.total = Object.keys(this.related).length;
    if (this.pages_count === 0) this.pages_count = Math.ceil(this.total / this.perPage)
    const ids = this.currentPageIds("collection");
    const toFetch = ids.filter(id => !this.byId[id]);
    const stringify = qs.stringify({ ids: toFetch }, { arrayFormat: 'brackets' });
    const route = API_ROUTES.COLLECTIONS_BULK_SHOW + '?' + stringify;
    try {
      const { data } = await API.get(route);
      data.collections.forEach((collection) => this.byId[collection.uuid] = collection);
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }
    return this.currentRelated;
  };

  get hasCollections() {
    return !!this.related.find((item) => item["collection"]);
  }

  get hasProduct() {
    return !!this.related.find((item) => item["product"]);
  }

  get header() {
    const { related, hasCollections, hasProduct } = this

    var label = ""
    if(hasProduct) { label += "Packs" }
    if(hasProduct && hasCollections) { label += " & " }
    if(hasCollections) { label += "Collections" }
    return label
  }
}

export default new relatedStore();

import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { Link } from 'react-router-dom'
import { inject, observer } from 'mobx-react'

import cloudPageUrl from '#helpers/cloudPageUrl.js'

import SearchInput from './header/SearchInput.js'
import MobileMenu from './header/MobileMenu.js'
import MobileSearchMenu from './header/MobileSearchMenu.js'
import DesktopMenu from './header/DesktopMenu.js'
import MobileIconLink from './header/MobileIconLink.js'
import DesktopLink from './header/DesktopLink.js'
import HamburgerButton from './header/HamburgerButton.js'
import UserIconButton from './header/UserIconButton.js'
import TryFreeButton from './header/TryFreeButton.js'
import LogInButton from './header/LogInButton.js'
import MagnifierButton from './header/MagnifierButton.js'

import BeatportLogoMobileIcon from '#images/beatport-yellow-logo-mobile.svg'
import BeatportLogoTabletIcon from '#images/beatport-yellow-logo-tablet.svg'
import UserIcon from '#images/user-2.svg'
import HeartIcon from '#images/heart-white.svg'
import CollectionsIcon from '#images/collections.svg'

@inject('authStore', 'infoStore', 'menuStore')
@observer
class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      mobileSearchMenuVisible: false,
      mobileMenuVisible: false,
      mobileMenuHover: false,
      desktopMenuVisible: false
    }
    this.modalElement = document.querySelector('#modal-root')
  }

  get mobileMenuShown() { return this.state.mobileMenuVisible || this.state.mobileMenuHover }

  async componentDidMount() {
    const { infoStore, authStore } = this.props
    if (authStore.isLoggedIn && !infoStore.email) return

    infoStore.cloudApp = false
  }

  toggleMobileSearchMenu = () => {
    this.setState((state) => {
      return { mobileSearchMenuVisible: !state.mobileSearchMenuVisible }
    })
  }

  toggleDesktopMenu = () => {
    this.setState((state) => { return { desktopMenuVisible: !state.desktopMenuVisible } })
  }

  toggleMobileMenu = (e) => {
    this.setState((state) => {
      return { mobileMenuVisible: !state.mobileMenuVisible }
    })
  }

  toggleMobileMenuHover = () => {
    if (window.innerWidth < 1024) return
    this.setState((state) => { return { mobileMenuHover: !state.mobileMenuHover } })
  }

  render() {
    const { isLoggedIn } = this.props.authStore
    return (
      <>
        <div className='flex px-4 lg:px-8 py-2.5 bg-gray-16 justify-between items-center flex-wrap max-w-[1920px] w-full'>
          <Link to='/'>
            <img src={BeatportLogoMobileIcon} className='lg:hidden' />
            <img src={BeatportLogoTabletIcon} className='hidden lg:block' />
          </Link>

          <div className='flex hidden md:block'>
            <SearchInput />
          </div>

          <ul className='flex justify-between h-6 grow pl-8 max-w-[325px] lg:pl-0 lg:max-w-[252px] 2xl:justify-normal 2xl:h-auto 2xl:grow-0 2xl:pl-auto 2xl:max-w-fit 2xl:text-lg'>
            {
              !isLoggedIn && (
                <>
                  <TryFreeButton href={cloudPageUrl('subscriptions/plans')} />
                  <LogInButton href={cloudPageUrl('login?lcw=true')} />
                </>
              )
            }
            <MagnifierButton toggleMobileSearchMenu={this.toggleMobileSearchMenu} />
            {
              isLoggedIn && (
                <>
                  <MobileIconLink to='/library' icon={UserIcon} />
                  <MobileIconLink to='/products-favourited' icon={HeartIcon} />
                  <MobileIconLink to='/collections' icon={CollectionsIcon} />
                </>
              )
            }
            <DesktopLink href={cloudPageUrl('features')} text='Features' />
            <DesktopLink href={cloudPageUrl('plugins')} text='Plugin Suite' />
            <DesktopLink href={cloudPageUrl('subscriptions/plans')} text='Pricing' />
            <DesktopLink href={cloudPageUrl('tutorials')} text='Tutorials' />
            <DesktopLink href={cloudPageUrl('blog')} text='Blog' />
            <DesktopLink href='/' text='Sounds' anchorTag={'Link'} />
            <HamburgerButton
              toggleMobileMenuHover={this.toggleMobileMenuHover}
              toggleMobileMenu={this.toggleMobileMenu}
            >
              { this.mobileMenuShown && <MobileMenu closeMenu={this.toggleMobileMenu} /> }
            </HamburgerButton>
            {
              isLoggedIn && (
                <UserIconButton toggleDesktopMenu={this.toggleDesktopMenu}>
                  { this.state.desktopMenuVisible && <DesktopMenu /> }
                </UserIconButton>
              )
            }
          </ul>
        </div>

        {
          this.state.mobileSearchMenuVisible && (
            ReactDOM.createPortal(
              <MobileSearchMenu closeMenu={this.toggleMobileSearchMenu} />,
              this.modalElement
            )
          )
        }
      </>
    );
  }
}

export default Header;

import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import history from '../common/history.js';

import ScaleFilterButton from './ScaleFilterButton';

@inject('filtersStore', 'menuStore')
@observer
class Piano extends Component {
  constructor(props) {
    super(props);
    this.state = { key: this.initialKey() }
  }

  redirect = () => {
    const { filtersStore } = this.props
    const params = filtersStore.toParams()
    let path = '/search'
    const blank = filtersStore.isBlank()
    if (blank) path = '/'
    history.push(`${path}?${params}`);
  }

  keyBgClass = (key, sharp = false) => {
    const uuid = this.props.menuStore.tags[key]
    const found = this.props.filtersStore.and_tags.find((tag) => { return tag === uuid })
    const initialColor = sharp ? 'bg-black' : 'bg-white'
    return found ? 'bg-orange-1' : initialColor
  }

  onKeyClick = (key) => {
    return () => {
      if (this.state.key) {
        const prev = this.props.menuStore.tags[this.state.key]
        this.props.filtersStore.changeAndTags(prev)
      }

      if (this.state.key === key) {
        this.setState({ ...this.state, key: null })
      } else {
        const uuid = this.props.menuStore.tags[key]
        this.props.filtersStore.changeAndTags(uuid)
        this.setState({ ...this.state, key: key })
      }

      this.props.filtersStore.page = 1
      this.redirect()
      window.scrollTo(0, 0);
    }
  }

  initialKey = () => {
    const allKeys = this.blackKeys.concat(this.whiteKeys)
    return allKeys.find((key) => {
      return this.props.filtersStore.and_tags.includes(this.props.menuStore.tags[key])
    })
  }

  get blackKeys() { return ['C#', 'D#', 'F#', 'G#', 'A#'] }

  get whiteKeys() { return ['C', 'D', 'E', 'F', 'G', 'A', 'B'] }

  get blackKeysOffsets() {
    return {
      0: 'left-[10%]',
      1: 'left-[24%]',
      2: 'left-[52%]',
      3: 'left-[66%]',
      4: 'left-[80%]'
    }
  }

  render() {
    if (this.state.key) {
      const uuid = this.props.menuStore.tags[this.state.key]
      const found = this.props.filtersStore.and_tags.find((tag) => { return tag === uuid })
      if(!found) this.setState({...this.state, key: null})
    }

    return (
      <div className='flex flex-col'>
        <p className='text-[23px] font-bold'>Key</p>
        <div className='flex items-center mt-8'>
          <div className='flex flex-col'>
            <ScaleFilterButton type='Major' redirect={this.redirect}/>
            <div className='py-1'></div>
            <ScaleFilterButton type='Minor' redirect={this.redirect}/>
          </div>
          <div className='ml-3.5'>
            <div className='relative w-[180px] h-[70px]'>
              {
                this.blackKeys.map((key, i) => {
                  return (
                    <div
                      key={i}
                      className={`cursor-pointer absolute h-[40px] w-[8%] top-[1px] ${this.blackKeysOffsets[i]} ${this.keyBgClass(key, true)}`}
                      onClick={this.onKeyClick(key)}
                    >
                    </div>
                  )
                })
              }
              <div className='flex h-full w-[98%]'>
                {
                  this.whiteKeys.map((key, i) => {
                    const roundingBorderClass = (i === 0) ? 'rounded-l-md ' : (i === this.whiteKeys.length - 1) ? 'rounded-r-md' : ''
                    return (
                      <div
                        key={i}
                        onClick={this.onKeyClick(key)}
                        className={`cursor-pointer border border-black grow ${roundingBorderClass} ${this.keyBgClass(key)}`}
                      >
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
          <div className={`flex flex-col items-center ml-3.5 min-w-[24px]`}>
            <p>{ this.state.key }</p>
          </div>
        </div>
      </div>
    )
  }
}

export default Piano;

import { action, extendObservable } from 'mobx';
import qs from 'qs';
import cloudItemsStore from './cloudItemsStore';
import packsStore from './packsStore';
import relatedStore from './relatedStore';
import menuStore from './menuStore';

const initialState = {
  q: '',
  partial_query: '',
  user: true,
  shop: true,
  randomize: false,
  seed: null,
  favs: false,
  loop: false,
  oneshot: false,
  wave: true,
  drumOrPlay: false,
  tags: [],
  and_tags: [],
  bpm: {},
  page: 1,
  sort: {},
  fetch: false,
  forceCustomUrl: false
};

export class filtersStore {
  constructor() {
    extendObservable(this, initialState);
  }

  get hasQuery() {
    return this.partial_query != ''
  }
  get hasTags() {
    return this.tags.length > 0 || this.and_tags.length > 0 || this.hasBpm
  }
  get hasBpm() {
    return this.bpm.min != undefined && this.bpm.max != undefined
  }
  get active() {
    return this.hasQuery || this.hasTags
  }

  get bpmName() {
    if (!this.hasBpm) { return null }
    return `BPM ${this.bpm.min}-${this.bpm.max}`
  }

  clearFilters = () => {
    this.resetRelated();
    this.q = ''
    this.partial_query = ''
    this.tags = []
    this.and_tags = []
    this.bpm = {}
  }

  @action loadBpm() {
    this.page = 1
    this.fetch = true
  }

  @action setPhrase(q) {
    this.resetRelated();
    this.page = 1;
    var extracted_tags = [];
    this.partial_query = '';
    let partial_q = '';
    if (!q.trim().length) { this.fetch = true; return; }

    const tagWordsSorter = (a, b) => {
      const aWords = a.name.split(' ').length
      const bWords = b.name.split(' ').length
      if (aWords > bWords) return -1
      if (aWords < bWords) return 1
      return 0
    }

    let allTags = ([...menuStore.all.filter(tag => tag.name)]).sort(tagWordsSorter)
    let tags = []
    partial_q = q
    allTags.forEach(tag => {
      let r = new RegExp(`\\b${tag.name.toLowerCase().replace(/\s+/g, '\\s+')}(\\s|\\t|$)`, 'i')
      partial_q = partial_q.replace(r, i => {
        tags.push(tag.uuid)
        return ''
      })
    })

    if (tags.length) {
      extracted_tags = extracted_tags.concat(tags);

    } else {
      partial_q = partial_q.trim()
    }

    extracted_tags = extracted_tags.filter(t =>
      !this.tags.find(tag => tag === t)
    );
    this.tags = this.tags.concat(extracted_tags);
    if (extracted_tags.length && !partial_q.length) {
      this.q = '';

    } else {
      this.q = q;
      this.partial_query = partial_q.trim();
    }

    this.fetch = true
  }

  isBlank() {
    const { partial_query, tags, and_tags, hasBpm } = this
    const blank = partial_query.trim() === '' && tags.length === 0 && and_tags.length === 0 && !hasBpm
    console.debug("filtersStore#isBlank: ", blank)
    return blank
  }

  isCustomUrl() {
    return this.tags.length === 1 && this.and_tags.length === 0
  }

  @action setCustomUrl() {
    if(this.isCustomUrl()) {
      this.forceCustomUrl = true
    } else {
      this.forceCustomUrl = false
    }
  }

  @action removePhrase() {
    this.resetRelated();
    this.page = 1;
    this.q = '';
    this.partial_query = '';
    this.fetch = true

    this.setCustomUrl()
  }

  @action clearBpm() {
    this.page = 1
    this.bpm = {}
    this.fetch = true

    this.setCustomUrl()
  }

  @action changeTags(uuid) {
    this.resetRelated();
    var found = this.tags.find((tag) => { return tag === uuid; });
    this.page = 1;

    if(found) { // remove
      this.tags.remove(uuid)
    } else { // add
      this.tags.push(uuid)
    }

    this.fetch = true
    // updating additional attribute cause modifying filtersStore.tags array does not always trigger update
    this.setCustomUrl()

    window.scrollTo(0,0);
  }

  @action removeNonInstrumentTags() {
    var items = menuStore.items.filter(item => item.menu !== 'Instruments');
    var toRemove = {};
    items.forEach((item) => { toRemove[item.uuid] = item; });

    this.and_tags.forEach((tag) => {
      if (toRemove[tag]) this.and_tags.remove(tag);
    });
    this.tags.forEach((tag) => {
      if (toRemove[tag]) this.tags.remove(tag);
    });

    this.fetch = true
  }

  changeAndTags(uuid) {
    this.resetRelated();
    var found = this.and_tags.find((tag) => { return tag === uuid; });
    this.page = 1;

    if(found) { // remove
      this.and_tags.remove(uuid);
    } else { // add
      this.and_tags.push(uuid);
    }

    this.fetch = true
    this.setCustomUrl()
  }

  @action toggleUser() {
    this.resetRelated();
    this.page = 1;
    if (this.user) {
      this.user = false;
      this.shop = true;

    } else {
      this.user = true;
    }

    this.fetch = true
  }

  @action toggleShop() {
    this.resetRelated();
    this.page = 1;

    if (this.shop) {
      this.shop = false;
      this.user = true;
    } else {
      this.shop = true;
    }

    this.fetch = true
  }

  @action toggleRandomize = () => {
    this.resetRelated();
    this.page = 1;
    if (!this.randomize) this.seed = parseInt(Math.random() * 10000);
    this.randomize = !this.randomize;
    this.fetch = true
  }

  @action toggleFavs() {
    this.resetRelated();
    this.page = 1;
    this.favs = !this.favs;
    this.fetch = true
  }

  @action toggleLoop() {
    this.resetRelated();
    this.page = 1;
    if(this.oneshot && !this.loop) {
      this.oneshot = false;
    }
    this.loop = !this.loop;
    this.fetch = true
  }

  @action toggleOneshot() {
    this.resetRelated();
    this.page = 1;
    if(this.loop && !this.oneshot) {
      this.loop = false;
    }
    this.oneshot = !this.oneshot;
    this.fetch = true
  }

  @action toggleWave() {
    this.resetRelated();
    this.page = 1;
    window.waveButton = !this.wave;
    this.wave = !this.wave;
    this.fetch = true
  }

  @action reset = (params = {}) => {
    console.debug(`filtersStore#reset...`)

    const keys = Object.keys(params);
    Object.keys(initialState).filter(k => !keys.includes(k)).forEach((key) => {
      if (key !== 'wave') {
        this[key] = initialState[key];
      }
    });
    keys.forEach(key => this[key] = params[key]);
  };

  @action collectionFilters = () => {
    this.reset();
    this.wave = false;
    this.load();
    this.fetch = true;
  };

  resetRelated() {
    cloudItemsStore.products.replace([]);
    packsStore.products = [];
    packsStore.product_ids = [];
    relatedStore.reset();
  }

  params() {
    var questionMark = window.location.href.indexOf('?');
    var urlParams = {};
    if(questionMark) {
      urlParams = qs.parse(window.location.href.slice(questionMark + 1));
      urlParams = this.clearParams(urlParams);
    }
    return urlParams;
  }

  mergedWithParams() {
    return { ...this.params(), ...this }
  }

  @action load(passedParams) {
    let currentParams = passedParams || this.params();
    Object.keys(this).forEach((key) => {
      if(currentParams[key]) {
        if(typeof initialState[key] === 'boolean') {
          this[key] = currentParams[key] === 'false' ? false : !!currentParams[key];
        } else {
          this[key] = currentParams[key];
        }
      }
    });

    this.page = parseInt(this.page);
  }

  clearParams(el) {
    Object.keys(el).forEach(key => {
      if (key === 'fetch' || key === 'forceCustomUrl' || (key != 'collection' && key != 'shop' && key != 'user') && (!el[key] || el[key] === '0' || el[key] === 'false')) {
        delete el[key];
      }
    });
    return el;
  }

  toParams() {
    return qs.stringify(this.clearParams({...this}), { arrayFormat: 'brackets' });
  }

  shortParams() {
    return qs.stringify(this.clearParams({...this}), { arrayFormat: 'brackets' });
  }

  releatedParams(page) {
    const params = { page: page, user: this.user, shop: this.shop, wave: this.wave }
    return qs.stringify(params, { arrayFormat: 'brackets' });
  }
}

export default new filtersStore();

import React from 'react'
import { inject, observer } from 'mobx-react'
import Modal from '../common/Modal'
import { Link } from 'react-router-dom'

@inject('modalStore')
@observer
class SuccessModal extends Modal {
  get title() {
    return 'Success!'
  }

  get content() {
    return (
      <div className='font-bold'>
        <p>All set.</p>
        <p>
          You can download your files from the&nbsp;below list or <Link onClick={this.close} to='/library' className='underline underline-offset-2'>Your Library</Link>
        </p>
      </div>
    )
  }

  get buttons() {
    return (
      <button
        className='bg-orange-1 text-gray-16 text-[22px] font-medium w-full rounded-[40px] py-1 tracking-tight'
        onClick={this.close}
        type='button'
      >
        Got it
      </button>
    )
  }

  close = () => {
    this.props.modalStore.hide()
  }
}

export default SuccessModal
import React from 'react'
import { inject, observer } from 'mobx-react'
import Modal from '../common/Modal'

@inject('modalStore')
@observer
class BuyFreeItemsExceededModal extends Modal {
  get title() {
    return 'Limit exceeded'
  }

  get content() {
    return ( <p className='font-bold'>Free items download limit exceeded</p> )
  }

  get buttons() {
    return (
      <button
        className='bg-orange-1 text-gray-16 text-[22px] font-medium w-full rounded-[40px] py-1 tracking-tight'
        onClick={this.close}
        type='button'
      >
        OK
      </button>
    )
  }

  close = () => {
    this.props.modalStore.hide()
  }
}

export default BuyFreeItemsExceededModal

import React from 'react'

export default function TryFreeBtn({ href }) {
  return (
    <li className='2xl:order-1 2xl:ml-3.5 2xl:py-2.5'>
      <a
        href={href}
        className='cursor-pointer py-1.5 px-3.5 rounded-3xl text-black font-bold bg-orange-1 text-base'
      >
        Try Free
      </a>
    </li>
  )
}

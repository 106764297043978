import { loopUrl } from '../config/api'
import authStore from '#stores/authStore'

const toPlanIfGuest = () => {
  if (!authStore.isLoggedIn) {
    return window.location.href = loopUrl + '/cloud/subscriptions/plans';
  }
  return false;
}

const toPlan = () => {
  return window.location.href = loopUrl + '/cloud/subscriptions/plans';
}

const toProduct = (product) => {
  return window.location.href = loopUrl + '/cloud/shop/product/' + product.seo_product_id
}

const toBuyPoints = () => {
  return window.location.href = loopUrl + '/cloud/shop/points'
}

const toHomeIfGuest = () => {
  const urlParams = new URL(document.location.href).searchParams
  if (!authStore.isLoggedIn && !urlParams.get('laccess_token')) {
    return window.location.href = '/';
  }
  return false;
}

const toDownloadPack = (download_token) => {
  const url = loopUrl + '/download/' + download_token + "?token=" + authStore.token

  window.open(url, "_blank", 'noopener noreferrer')
}

const Redirect = { toPlanIfGuest, toProduct, toBuyPoints, toHomeIfGuest, toPlan, toDownloadPack };
export default Redirect;

import React, { Component } from 'react'

import CategoryTileDropdownItem from './CategoryTileDropdownItem.js'

export default class CategoryTileDropdownItems extends Component {
  render() {
    return (
      <div className='text-left w-full h-full'>
        <ul className='gap-5 sm:columns-2 md:columns-3 lg:columns-6 xl:columns-9 2xl:columns-11'>
          {
            this.props.groupedItems.map(([parentId, products]) => {
              const listItems = products.map((product) => {
                return <CategoryTileDropdownItem
                  key={product.id}
                  isParent={product.id === parseInt(parentId)}
                  item={product}
                  upcaseParent={this.props.upcaseParents}
                />
              })
              return listItems
            })
          }
        </ul>
      </div>
    )
  }
}

import { action, extendObservable } from 'mobx';
import API from '../config/api';
import { API_ROUTES } from '../config/api_routes';
import filtersStore from './filtersStore';
import playlistStore from './playlistStore';
import packsStore from './packsStore';
import packStore from './packStore';
import collectionStore from './collectionStore';
import generateQuery from '../search/generate_query.js'
import sidebarStore  from './sidebarStore'
import relatedStore from './relatedStore'
import favouriteStore from './favouriteStore'
import authStore from './authStore'

const initialState = {
  isLoading: false,
  cloudItems: [],
  products: [],
	bottomMargin: 0,
  topMargin: 0,
  meta: {
    pages_count: 1,
    per_page: 100,
    total_entries: 0
  },
  error: null,
};

const itemHeight = 67;

export class cloudItemsStore{
  constructor() {
    extendObservable(this, initialState);
  }

  @action reset = () => {
    Object.keys(initialState).forEach((key) => {
      this[key] = initialState[key];
    });
  };

  doNotShowEmptyQuery() {
    return !(collectionStore.id || packStore.id || filtersStore.q || filtersStore.tags.length || filtersStore.and_tags.length || filtersStore.bpm.min);
  }

  @action fetchCloudItems = async (params = {}) => {
    if(!(params['product_id'] || params['user_favourites'] || params['collection']) && this.doNotShowEmptyQuery()) { return []; }
    this.isLoading = true;
    var fromFilters = filtersStore.mergedWithParams();
    var paramsObj = filtersStore.clearParams({ ...fromFilters, ...params });
    if(paramsObj['collection']) { delete paramsObj['q'] }

    if(!authStore.isLoggedIn && paramsObj['shop']) { // todo: ?
      paramsObj['user'] = true;
    }

    if (params['user_favourites']) paramsObj['favs'] = true;

    try {
      const stringify = await generateQuery(paramsObj)
      let path = stringify ? `${API_ROUTES.SEARCH}?${stringify}` : API_ROUTES.SEARCH;
      const { data } = await API.get(path);
      this.cloudItems.replace(data.cloud_items);
      this.meta.pages_count = data.pages_count;
      this.meta.per_page = data.per_page;
      this.meta.total_entries = data.total_entries;
      packsStore.product_ids = data.products;
      const pageProductIds = [...new Set(data.cloud_items.map(item => item.product_id)) ]
      console.debug('page product ids:', pageProductIds)
      const data_products = data.products && data.products.slice(0, 30)
      packsStore.fillProducts({ ids: pageProductIds, products: data_products || [] })
      if (data.related) relatedStore.related.replace(data.related);
      relatedStore.fetch();

      console.debug("cloudItemsStore#fetchCloudItems, packsStore.product_ids: ", packsStore.product_ids, "data.products:", data.products)
    } catch (e) {
      console.error(e);
    } finally {
      this.isLoading = false;
    }
    this.calculateTopMargin();
    this.calculateBottomMargin();
    if (!params['product_id']) { sidebarStore.refresh() }
    return this.cloudItems;
  };

  @action favourite = async (item, date) => {
    var paramsObj = { cloud_item: { uuid: item.uuid, favourite_added_date: date } };
    var value = !(date === -1);
    try {
      const { data } = await API.post(API_ROUTES.CLOUD_ITEMS, paramsObj);
      item.fav = value;
      var index = this.cloudItems.findIndex((i) => i.uuid === data.uuid );
      if(index > -1) {
        this.cloudItems[index] = {...this.cloudItems[index], fav: value};
      }

      playlistStore.fav(item, value)
      favouriteStore.markItem(item)
    } catch (e) {
      console.log(e);
      alert('Server Error. Please try again later');
    }
  };

  calculateTopMargin() {
    let per = this.meta.per_page;
    let page = filtersStore.page;

    this.topMargin = itemHeight * (page - 1) * per;
  }

  bottomMarginMinusOnePage() {
    let per = this.meta.per_page;

    this.bottomMargin = this.bottomMargin - (itemHeight * per);
    if(this.bottomMargin < 0) { this.bottomMargin = 0 }
  }

  topMarginMinusOnePage() {
    let per = this.meta.per_page;

    this.topMargin = this.topMargin - (itemHeight * per);
    if(this.topMargin < 0) { this.topMargin = 0 }
  }

  calculateBottomMargin() {
    let all = this.meta.pages_count;
    let per = this.meta.per_page;
    let page = filtersStore.page;

    var pages = all - (page - 1);
    if(pages < 0) pages = 0;

    this.bottomMargin = itemHeight * pages * per;
  }
}

export default new cloudItemsStore();

import React from 'react'
import layerStore from '../../stores/layerStore'
import collectionStore from '../../stores/collectionStore'
import { inject, observer } from 'mobx-react'
import history from '../common/history.js'
import Modal from '../common/Modal'

@inject('layerStore', 'modalStore')
@observer
class DeleteCollectionModal extends Modal {
  constructor(props) {
    super(props)
    this.state = { deleted: this.props.deleted || false }
  }

  async confirmDelete (e) {
    e.preventDefault();
    e.stopPropagation();

    const { modalStore } = this.props
    modalStore.show('loading')
    await collectionStore.delete(this.props.layerStore.deleteCollectionId)
    modalStore.show('deleteCollection', { deleted: true })
  }

  closeModal = (e) => {
    e.preventDefault()
    e.stopPropagation()

    const deletedId = layerStore.deleteCollectionId

    this.props.modalStore.hide()
    layerStore.deleteCollectionId = null

    if (this.state.deleted && window.location.pathname.match(`collection/${deletedId}`)) {
      history.push('/my-collections')
    }

    this.setState({ deleted: false })
  }

  get content() {
    return <p className='font-bold text-center'>
      { this.state.deleted ? "Collection deleted" : "Are you sure you'd like to delete this collection?" }
    </p>
  }

  get buttons() {
    return (
      <>
        {
          !this.state.deleted && (
            <button
              onClick={this.closeModal}
              className='px-9 border border-2 border-white rounded-[40px] py-0.5 font-medium text-[22px] tracking-tight'
            >
              Cancel
            </button>
          )
        }

        <button
          onClick={this.state.deleted ? this.closeModal : this.confirmDelete.bind(this)}
          className={`${this.state.deleted ? '' : 'ml-2.5'} bg-orange-1 text-gray-16 text-[22px] font-medium w-full rounded-[40px] py-1 tracking-tight`}
        >
          OK
        </button>
      </>
    )
  }
}

export default DeleteCollectionModal

import React, { Component, createRef } from 'react';
import DemoPlayButton from '../common/DemoPlayButton';
import filtersStore from '../../stores/filtersStore';
import history from '../common/history.js';

class BigTile extends Component {
  constructor(props) {
    super(props)
    this.playButtonRef = createRef()
  }

  redirectToProduct = (e) => {
    e.preventDefault()
    if (this.playButtonRef.current.contains(e.target)) return

    const { product } = this.props
    let params = filtersStore.toParams()
    history.push(`/product/${product.seo_product_id}?${params}`)
  }

  render() {
    const { product, lazy } = this.props

    return (
      <div
        onClick={this.redirectToProduct}
        className='flex cursor-pointer relative group h-full aspect-w-16 aspect-h-9'
      >
        <img
          src={product.banner_home || product.banner_huge || 'blank.gif'}
          title={product.title}
          alt={product.title}
          loading={lazy ? "" : "lazy"}
          className='w-full'
        />
        <DemoPlayButton product={product} elementRef={this.playButtonRef} />
      </div>
    )
  }
}

export default BigTile;

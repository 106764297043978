import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'

@inject('flashBoxStore')
@observer
class FlashBox extends Component {
  close = () => {
    this.props.flashBoxStore.visible = false
  }

  componentDidMount() {
    const { flashBoxStore } = this.props
    flashBoxStore.visible = true
    setTimeout(function() {
      flashBoxStore.visible = false
    }, 6000)
  }

  render() {
    const { flashBoxStore } = this.props
    if (!flashBoxStore.visible) return <></>

    return (
      <div
        onClick={this.close}
        className='w-full z-10 bg-orange-1 py-3'
      >
        <p className='text-gray-16 font-bold text-center text-xl'>Purchase completed successfully</p>
      </div>
    )
  }
}

export default FlashBox;

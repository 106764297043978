import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { loopUrl } from '../../config/api'
import { ReactComponent as DownloadDesktopBackground } from '#images/download-desktop-background.svg'
import CloseIcon from '#images/x.svg'

@inject('layerStore', 'modalStore')
@observer
class FreeLimitExceededModal extends Component {
  close = () => {
    this.props.modalStore.hide()
  }

  cloudLink(page) {
    return loopUrl + '/cloud/' + page;
  }
  click = () => {
    window.location = this.cloudLink('subscriptions/plans')
  }

  render() {
    return (
      <div className='fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2'>
        <div className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 bg-orange-1 rounded-md p-5 flex flex-col overflow-hidden min-w-[340px] max-w-[340px] text-gray-16'>
          <div
            className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2'
          >
            <DownloadDesktopBackground width='480' height='570' />
          </div>
          <div className='flex flex-col [&_*]:z-[1]'>
            <h3 className='font-bold text-[28px] pb-5 border-b border-gray-16'>
              You’ve reached the daily limit for non-subscriber previews
            </h3>
            <div className='pt-5'>
              <p className='text-lg font-bold'>Subscribe or take the free trial</p>
              <ul className='list-disc list-inside mt-5'>
                <li>You won't get charged until after your free month</li>
                <li>Get 300 credits to spend on over 4 million sounds</li>
                <li>We’ll send you a reminder email before you pay anything</li>
                <li>And hey, inspiration comes and goes. So you can cancel at any time</li>
                <li>Loopcloud free version will always work with your sound library</li>
              </ul>
            </div>
            <div className='flex justify-between pt-5'>
              <button
                type='button'
                className='w-full bg-gray-16 px-6 py-1 rounded-[36px]'
                onClick={this.click}
              >
                <span className='text-[22px] text-white font-medium'>Try Loopcloud Free</span>
              </button>
            </div>
          </div>
          <div className='absolute top-[15px] right-[15px] z-10 cursor-pointer'>
            <img src={CloseIcon} onClick={this.close} />
          </div>
        </div>
      </div>
    )
  }
}

export default FreeLimitExceededModal

import { action, extendObservable } from 'mobx';
import API from '../config/api';
import { API_ROUTES } from '../config/api_routes';
import qs from 'qs';

const initialState = {
  isLoading: false,
  items: [],
  parent: null,
  parents: [],
  root: null,
  name: null,
  uuid: null,
  view: 'list'
};

export class libraryMainStore{
  constructor() {
    extendObservable(this, initialState);
  }

  inspect = (where = null) => {
    console.debug("libraryMainStore#inspect(" + where + ") => name:", this.name, ", uuid:", this.uuid, ", parents:", this.parents.map(item => [item.name, item.uuid]))
  }

  @action toggleView = () => {
    this.view = (this.view === 'tile') ? 'list' : 'tile';
  }

  @action clear = () => {
    this.items = []
  }

  @action reset = () => {
    // Object.keys(initialState).forEach((key) => {
    //   this[key] = initialState[key];
    // });
  };

  @action fetch = async (params = {}) => {
    // console.warn('api pre ping, params: ', params.node_uuid, "current", this.currentNode)

    if (this.isLoading) return;
    if (params.node_uuid && params.node_uuid === this.uuid) return;

    params.ext = 'mp3'
    this.isLoading = true;
    try {
      const { data } = await API.get(API_ROUTES.LIBRARY_MAIN + '?'+qs.stringify(params));
      this.root = data.root;
      this.items = data.links;
      this.parent = data.parent;
      this.parents = data.parents || [];
      this.name = data.node_name;
      this.uuid = data.node_uuid;

      this.inspect('after api call')

    } catch (e) {
      console.error(e);

    } finally {
      this.isLoading = false;
    }
  };
}

export default new libraryMainStore();

import { action, extendObservable } from 'mobx'
import API from '../config/api'
import { API_ROUTES } from '../config/api_routes'
import collectionStore from './collectionStore'
import cloudItemsStore from './cloudItemsStore'
import modalStore from './modalStore'

const initialState = {
  isLoading: false,
  menu: [],
  itemMenu: [],
  itemUuid: null,
  remove: false,
  xPos: 0,
  yPos: 0,
  clientY: 0,
  error: null,
};

export class myCollectionsMenuStore {
  constructor() {
    extendObservable(this, initialState);
  }

  @action reset = () => {
    Object.keys(initialState).forEach((key) => {
      this[key] = initialState[key];
    });
  };

  @action cleanUp = () => {
    Object.keys(initialState).forEach((key) => {
      if (key !== "menu" && key !== "itemUuid") this[key] = initialState[key];
    });
  };

  @action fetch = async () => {
    this.isLoading = true;
    try {
      const { data } = await API.get(API_ROUTES.COLLECTIONS_MENU);
      this.menu.replace(data);
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }
  };

  @action add = async (colId) => {
    try {
      await API.post(API_ROUTES.COLLECTION + colId + "/mass_add.json", { uuid: this.itemUuid });
    } catch (e) {
      console.log(e);
    }
  };

  @action removeItem = async (colId) => {
    try {
      await API.post(API_ROUTES.COLLECTION + colId + "/mass_remove.json", { uuid: this.itemUuid });
      if (colId === collectionStore.id) {
        const refreshTags = cloudItemsStore.cloudItems.length <= 1
        collectionStore.fetch(colId, refreshTags)
      }
    } catch (e) {
      console.log(e);
    }
  };

  @action open = async (itemUuid, opts = {}) => {
    const { pageX, pageY, clientY, fetchProductItems } = opts
    this.itemUuid = itemUuid;
    try {
      const { data } = await API.get(API_ROUTES.COLLECTIONS_MENU_ITEM + "?uuid=" + itemUuid);
      this.itemMenu.replace(data);
    } catch (e) {
      console.log(e);
    }
    this.xPos = pageX;
    this.yPos = pageY;
    this.clientY = clientY;
    modalStore.show('cloudItemMenu', { fetchProductItems: fetchProductItems })
  };
}

export default new myCollectionsMenuStore();

import React from 'react'
import { inject, observer } from 'mobx-react'
import Modal from '../common/Modal'
import { loopUrl } from '../../config/api'

@inject('notEnoughPointsStore', 'infoStore', 'modalStore')
@observer
class NotEnoughPointsModal extends Modal {
  get title() {
    return 'Not enough points'
  }

  get content() {
    const { selection } = this.props.notEnoughPointsStore
    return (
      <p className='font-bold'>
        You need more points
        { selection && <> to buy this selection.</> }
        { !selection && <> to buy this product.</> }
      </p>
    )
  }

  get buttons() {
    const { subscription_level } = this.props.infoStore;
    return (
      <>
        <button
          className={`${subscription_level == 'free' ? 'px-3' : 'px-9'} border border-2 border-white rounded-[40px] py-0.5 font-medium text-[22px] tracking-tight`}
          onClick={this.close}
          type='button'
        >
          Cancel
        </button>
        <button
          className={`ml-2.5 ${subscription_level == 'free' ? 'px-2.5' : 'px-4'} rounded-[40px] py-0.5 font-medium bg-orange-1 text-gray-16 text-[22px] tracking-tight`}
          onClick={this.confirm}
          type='button'
        >
          { subscription_level == 'free' ? 'Update your plan' : 'Buy points' }
        </button>
      </>
    )
  }

  close = () => {
    this.props.modalStore.hide()
  }

  confirm = () => {
    const { subscription_level } = this.props.infoStore
    if (subscription_level === 'free') {
      return window.location.href = loopUrl + '/cloud/subscriptions/plans'
    } else {
      return window.location.href = loopUrl + '/cloud/shop/points'
    }
  }
}

export default NotEnoughPointsModal

import React from 'react'
import DesktopAppIcon from '#images/desktop-app-icon.svg'

export default function DesktopDownloadReminder({ findOutMoreLink }) {

  return (
    <li className='hidden 3xl:list-item p-2.5 mx-auto text-gray-4'>
      <div className='flex items-center'>
        <img src={DesktopAppIcon} />
        <span className='ml-2.5 font-semibold text-[11px]'>
          Do more with Loopcloud desktop version!
        </span>
        <a href={findOutMoreLink} className='ml-2.5 border rounded-[20px] px-2 py-1 text-xs'>
          Find out more
        </a>
      </div>
    </li>
  )
}

// todo: remove attribute `download` ?

const attachment = async (url, fileName) => {
  if (!url) {
    console.error("blank url for download")
    return
  }

  // Microsoft injected the word iPhone in IE11's userAgent in order to try and fool Gmail somehow.
  const isIos = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream

  const a = document.createElement('a')
  a.setAttribute('href', url)
  if (isIos) {
    a.setAttribute('target', '_blank');
    a.setAttribute('rel', 'noreferrer');
  }
  a.setAttribute('download', fileName)
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

const Download = { attachment }
export default Download

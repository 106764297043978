import React from 'react';

import BackToBeatportArrow from '#images/back-to-beatport-arrow.svg'
import BeatportLogo from '#images/beatport-logo.svg'

const BackToBeatport = ({ beatportUrl }) => {
  const goToBeatport = () => { window.location.href = beatportUrl }

  return (
    <div className='flex bg-black px-4 lg:px-12 py-1 border-b border-gray-12 w-full'>
      <div className='flex items-center cursor-pointer' onClick={goToBeatport}>
        <img src={BackToBeatportArrow} alt='Back arrow' className='h-[12px] w-[12px]' />
        <span className='text-[12px] mx-1.5 leading-none font-medium'>BACK TO</span>
        <img src={BeatportLogo} alt='Beatport logo' />
      </div>
    </div>
  )
}

export default BackToBeatport;

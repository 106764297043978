import { action, extendObservable } from 'mobx';
import API from '../config/api';
import { API_ROUTES } from '../config/api_routes';
import qs from 'qs';
import Collections from '../helpers/Collections';

const initialState = {
  isLoading: false,
  collections: [],
  // pagesCount: 0,
  totalEntries: 0,
  // page: 1,
  error: null,
};

const perPage = 10;
const maxEntries = 10

export class popularCollectionsStore {
  constructor() {
    extendObservable(this, initialState);
  }

  @action reset = () => {
    Object.keys(initialState).forEach((key) => {
      this[key] = initialState[key];
    });
  };

  @action fetch = async (page = 1) => {
    this.isLoading = true;
    const params = qs.stringify({ page: page, per_page: perPage });
    try {
      const { data } = await API.get(API_ROUTES.POPULAR_COLLECTIONS + '?' + params);
      Collections.normalize(data.collections, this);
      this.totalEntries = Math.min(data.total_count, maxEntries);
      // this.page = data.page;
      // this.pagesCount = data.pages_count;
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }
  };
}

export default new popularCollectionsStore();

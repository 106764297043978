import { action, extendObservable } from 'mobx'
import API from '../config/api'
import { APIFunctions } from '../config/api'
import { API_ROUTES } from '../config/api_routes'
import packsStore from './packsStore'
const initialState = {
  isLoading: false,
  collections: { title: 'Collections', list: [], page: 1, pageCount: 0, totalCount: 0, isLoading: true },
  labelFocus: { title: 'Label Focus', ids: [], products: [], page: 1, pageCount: 0, labelSeoId: null, isLoading: true },
  featuredReleases: { title: 'Featured Releases', ids: [], products: [], page: 1, pageCount: 0, isLoading: true },
  synthPresets: { title: 'Synth Presets', ids: [], products: [], page: 1, pageCount: 0, isLoading: true },
  recent: { title: 'Recent Releases', ids: [], products: [], page: 1, pageCount: 0, isLoading: true },
  artistSeries: { title: 'Artist Series Releases', ids: [], products: [], page: 1, pageCount: 0, isLoading: true },
  popularPacks: { title: "What's Hot", ids: [], products: [], page: 1, pageCount: 0, isLoading: true },
  drumPlugins: { title: 'Loopcloud Drum Packs', ids: [], products: [], page: 1, pageCount: 0, isLoading: true },
  playPlugins: { title: 'Loopcloud Play Packs', ids: [], products: [], page: 1, pageCount: 0, isLoading: true },
  userLabels: [],
  userGenres: [],
  error: null,
  page: 1,
  perPage: 60,
  pageCount: 0
}

export class homeStore{
  constructor() {
    extendObservable(this, initialState)
  }

  // Get one page of products from given category
  fetchPage(type, page, genreId = null) {
    if(genreId) {
      const genre = this.userGenres.find((g) => g.id === genreId)
      genre.page = page
      this.fetchProducts(type, genre, genreId)
    } else {
      this[type].page = page
      this.fetchProducts(type, this[type])
    }
  }

  fetchProducts = async (collectionName, collection, genreId = null) => {
    console.debug("homeStore#fetchProducts, ids:", collection.ids, "collectionName:", collectionName)

    if (!collection.ids.length) return

    const start = (collection.page - 1) * this.perPage
    const pageIds = collection.ids.slice(start, start + this.perPage)
    var genreIndex;

    if (collectionName === 'userGenres') {
      genreIndex = this[collectionName].findIndex(function(genre) { return genre.id === genreId })
      this[collectionName][genreIndex].products = []
    } else {
      this[collectionName].isLoading = true
      this[collectionName].products = []
    }

    await packsStore.fillProducts({ ids: pageIds })
    const products = pageIds.map(id => packsStore.byId[id]).filter(i => !!i)

    if (collectionName === 'userGenres') {
      this[collectionName][genreIndex].products = products
    } else {
      this[collectionName].products = products
      this[collectionName].isLoading = false
    }
  }

  fetchLabels = async (collectionName, labels) => {
    if(labels.length === 0) { return }

    var api = APIFunctions.createLoopAPI()
    try {
      let params = labels.map((label) => `ids[]=${label.id}`).join('&')
      let path = `${API_ROUTES.LABELS}?${params}`
      var { data } = await api.get(path)

      var loopLabel;
      var loopLabels = labels.map((label) => {
        loopLabel = data.find((l) => l.id === label.id)
        loopLabel.totalProducts = label.total_products
        return loopLabel
      })
      this[collectionName].replace(loopLabels)
    } catch (e) {
      console.log(e)
    }
  }

  @action reset = () => {
    Object.keys(initialState).forEach((key) => {
      this[key] = initialState[key]
    })
  }

  @action fetch = async (params = {}) => {
    this.isLoading = true

    if (params.perPage) {
      this.perPage = params.perPage
    }

    try {
      let path = `${API_ROUTES.HOME_PACKS}`
      const { data } = await API.get(path)

      this.recent.ids = data.recent
      this.recent.pageCount = this.calculatePagesCount(data.recent)
      this.fetchProducts('recent', this.recent)

      if (data.label_focus && data.label_focus.label) {
        this.labelFocus.ids = data.label_focus.products
        this.labelFocus.pageCount = this.calculatePagesCount(data.label_focus.products)
        this.labelFocus.title += ` - ${data.label_focus.label.name}`
        this.labelFocus.labelSeoId = data.label_focus.label.seo_id
        this.fetchProducts('labelFocus', this.labelFocus)
      }

      if (data.featured_releases && data.featured_releases.products) {
        this.featuredReleases.ids = data.featured_releases.products
        this.featuredReleases.pageCount = this.calculatePagesCount(data.featured_releases.products)
        this.featuredReleases.title = data.featured_releases.name || 'Featured Releases'
        this.fetchProducts('featuredReleases', this.featuredReleases)
      }

      if (data.synth_presets?.ids) {
        this.synthPresets.ids = data.synth_presets.ids
        this.synthPresets.pageCount = this.calculatePagesCount(data.synth_presets.ids)
        this.fetchProducts('synthPresets', this.synthPresets)
      }

      this.artistSeries.ids = data.artist_series;
      this.artistSeries.pageCount = this.calculatePagesCount(data.artist_series);
      this.fetchProducts('artistSeries', this.artistSeries);

      this.popularPacks.ids = data.popular_packs || []
      this.popularPacks.pageCount = this.calculatePagesCount(data.popular_packs)
      this.fetchProducts('popularPacks', this.popularPacks)

      this.drumPlugins.ids = data.drum_plugins
      this.drumPlugins.pageCount = this.calculatePagesCount(data.drum_plugins)
      this.fetchProducts('drumPlugins', this.drumPlugins)

      this.playPlugins.ids = data.play_plugins
      this.playPlugins.pageCount = this.calculatePagesCount(data.play_plugins)
      this.fetchProducts('playPlugins', this.playPlugins)

      this.collections.totalCount = data.collections_total_count;
      this.collections.list.replace(data.collections || []);
      this.collections.pageCount = data.collections_pages_count;

      var genres = data.user_genres;

      if (sessionStorage.getItem('beatport_ref')) {
        genres = data.beatport_tags;
      }

      this.userGenres.replace([]);
      genres.forEach((genre) => {
        genre.page = 1
        genre.pageCount = this.calculatePagesCount(genre.ids)
        genre.products = []
        this.userGenres.push(genre)
        this.fetchProducts('userGenres', genre, genre.id)
      })
      this.fetchLabels('userLabels', data.user_labels)
    } catch (e) {
      console.log(e)
    } finally {
      this.isLoading = false
    }
  }

  @action fetchGenre = async (id, params = {}) => {
    if (params.perPage) {
      this.perPage = params.perPage
    }

    try {
      let path = `${API_ROUTES.GENRE}${id}`
      const { data } = await API.get(path)

      data.page = 1
      data.pageCount = this.calculatePagesCount(data.ids)
      data.products = []
      this.userGenres.push(data)
      this.fetchProducts('userGenres', data, data.id)
    } catch (e) {
      console.log(e)
    }
  }

  calculatePagesCount(ids) {
    return Math.ceil(ids.length / this.perPage)
  }
}

export default new homeStore()

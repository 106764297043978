import React from "react";
import BigTile from "./BigTile";
import Browser from "../common/Browser";
import { inject, observer } from "mobx-react";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import { isMobile, isTablet, isBigDesktop } from "../../helpers/rwd.js";

import ArrowLeft from "#images/arrow-left-slider.svg";
import ArrowRight from "#images/arrow-right-slider.svg";

@inject("homeStore")
@observer
class HomepageRecentsSlider extends Browser {
  get products() {
    return this.props.homeStore["recent"].products.slice(0, 18);
  }

  get totalEntries() {
    return this.products.length;
  }

  calculateVisibleSlides() {
    if (isMobile()) {
      return 1;
    } else if (isTablet()) {
      return window.innerWidth / 475;
    } else if (isBigDesktop()) {
      return window.innerWidth / 640;
    } else {
      return 3;
    }
  }

  renderProducts() {
    if (this.totalEntries === 0) {
      return [0, 1, 2].map((placeholderIndex) => (
        <BannerPlaceholder key={placeholderIndex} />
      ));
    }

    return this.products.map((product, index) => (
      <Slide key={product.id} innerClassName='aspect-video'>
        <BigTile product={product} lazy={index > 2} />
      </Slide>
    ));
  }

  render() {
    return (
      <div className="flex w-full justify-center pb-3 2xl:mt-7">
        <div className="relative w-full max-w-[calc(1920px-48px-48px)]">
          <CarouselProvider
            isIntrinsicHeight={true}
            totalSlides={this.entries}
            visibleSlides={this.state.visibleSlides}
            step={this.state.visibleSlides}
            lockOnWindowScroll={true}
            dragEnabled={false}
            className="homepage-recents-slider"
          >
            <Slider
              trayProps={{
                onTouchStart: this.onTouchStart,
                onTouchMove: this.onTouchMove,
                onTouchEnd: this.onTouchEnd,
              }}
              classNameTrayWrap="min-w-[calc(100%+1px)]"
            >
              {this.renderProducts()}
            </Slider>
            <ButtonBack
              className="bg-gray-11 absolute inset-y-1/2 left-[20px] z-10 hidden h-[35px] w-[35px] -translate-y-1/2 cursor-pointer rounded-3xl p-2 disabled:cursor-default disabled:opacity-50 md:block"
              children={<img src={ArrowLeft} />}
            />
            <ButtonNext
              className="bg-gray-11 absolute inset-y-1/2 right-[20px] z-10 hidden h-[35px] w-[35px] -translate-y-1/2 cursor-pointer rounded-3xl p-2 disabled:cursor-default disabled:opacity-50 md:block"
              children={<img src={ArrowRight} />}
            />
          </CarouselProvider>
        </div>
      </div>
    );
  }
}

const BannerPlaceholder = () => (
  <Slide>
    <div className="flex">
      <div className="min-h-[200px] min-w-[300px]" title="Loading..."></div>
    </div>
  </Slide>
);

export default HomepageRecentsSlider;

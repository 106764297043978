import React, { Component, createRef } from 'react'
import { inject, observer } from 'mobx-react'
import UploadCoverPlaceholder from '#images/upload-cover.svg'

@inject('collectionStore')
@observer
class CoverUploader extends Component {
  constructor(props) {
    super(props)
    this.uploaderRef = createRef()
  }

  modalImageUrl = () => {
    const { editMode, modal_image_url, image_url } = this.props.collectionStore
    return (editMode ? modal_image_url || image_url : modal_image_url) || UploadCoverPlaceholder
  }

  changeImage = (e) => {
    e.preventDefault()
    this.uploaderRef.current.click()
  }

  handleImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      this.props.collectionStore.modal_image_url = URL.createObjectURL(event.target.files[0])
    }
  }

  render() {
    return (
    <div
      className='flex flex-col min-h-[200px] max-h-[200px] max-w-[200px] min-w-[200px] order-1 mb-5'
    >
      <img
        src={this.modalImageUrl()}
        onClick={this.changeImage}
        className='h-full w-full cursor-pointer max-w-full max-h-full object-cover'
      />
      <input
        type='file'
        id='image'
        name='collection[image]'
        onChange={this.handleImageChange}
        className='hidden'
        ref={this.uploaderRef}
      />
    </div>
  )
  }
}

export default CoverUploader
import { action, extendObservable } from 'mobx'
import API from '../config/api'
import { API_ROUTES } from '../config/api_routes'
import { APIFunctions } from '../config/api'
import WaveformHelper from './../helpers/WaveformHelper'


const initialState = {
  isLoading: false,
  id: null,
  name: null,
  logo: null,
  logo_grayscale: null,
  big: [],
  recentIds: [],
  recent: [],
  bestsellers: [],
  pageCount: 0,
};
const perPage = 60;

export class labelStore{
  constructor() {
    extendObservable(this, initialState);
  }

  @action fetch = async (id) => {
    this.isLoading = true;
    try {
      const { data } = await API.get(`${API_ROUTES.LABEL}${id}`);

      let label = data.label;
      this.id = label.id
      this.name = label.name
      this.logo = label.logo
      this.logo_grayscale = label.logo_grayscale
      this.recentIds = data.recent
      this.pageCount = Math.ceil(this.recentIds.length / perPage)

      this.fetchPage(0)
      this.fetchProducts('big', data.big);
      this.fetchProducts('bestsellers', data.bestsellers);
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }
  };

  fetchPage = async (page) => {
    this.recent = []
    const start = page * perPage
    const ids = this.recentIds.slice(start, start + perPage)
    this.fetchProducts('recent', ids)
  }

  fetchProducts = async (collectionName, ids, page = false) => {
    if(ids.length == 0) { return }
    this.isLoading = true;

    var api = APIFunctions.createLoopAPI();
    try {
      const params = { ids: ids }
      const { data } = await api.post(API_ROUTES.PACKS, params)
      const { waveforms } = (await WaveformHelper.forIds(ids)).data;

      let products = [];
      let product;
      ids.forEach(function(id) {
        product = data.products.find(function(product) {
          return id == product.id
        })
        const waveform = (waveforms || []).find(waveform => product.id == waveform.product_id);
        products.push(WaveformHelper.merge(product, waveform));
      })

      products = products.filter((p) => !!p);
      this[collectionName].replace(products)
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }
  }
}

export default new labelStore();

// example theme.js
var colors = {
  black: '#000',
  coal: '#2A2A2A',
  graphite: '#3E3E3E',
  iron: '#818181',
  slate: '#AEAEAE',
  lunar: '#CFCFCF',
  silver: '#D8D8D8',
  steam: '#DEDEDE',
  cloud: '#F0F0F0',
  fog: '#F9F9F9',
  white: '#fff',
  space: '#272B2D',
  airforce: '#565F66',
  blue: '#33B9EA',
  mist: '#B3C2CA',
  bluegray: '#CBCDCF',
  scrollbar: '#838383',
  blue: '#85d6ff',
  semiblack: '#191c1f',

  // Used http://chir.ag/projects/name-that-color/ to determine color name
  OuterSpace: '#323A41',
  OuterSpace2: '#2C343A',
  LimedSpruce: '#3B4550',
  BrightGray: '#3D454F',
  Supernova: '#FFC708',
  AthensGray: '#EBEFF2',
  EbonyClay: '#1C212B',
  Shark: '#222629',
  Shark2: '#181C20',
  Shark3: '#1b1d1f',
  Heather: '#BFCBD7',
  Heather2: '#BDCBD8',
  Heather3: '#B0BDC9',
  Heather4: '#B6C5D1',
  Anakiwa: '#85D6FF',
  PigeonPost: '#BCCCDF',
  RiverBed: '#454E59',
  BaliHai: '#8FA1B0',
  RegentGray: '#84919B',
  RegentGray2: '#7B8B97'

}
var theme = {
  breakpoints: [ '768px', '1025px', '1921px', '2500px' ],
  fontSizes: [
    10, 12, 14, 16, 20, 24, 32, 48, 64
  ],
  colors: colors,

  // Space is used for:
  // margin, margin-top, margin-right, margin-bottom, margin-left,
  // padding, padding-top, padding-right, padding-bottom, padding-left,
  // grid-gap, grid-column-gap, grid-row-gap
  space: [
    0, 4, 8, 16, 32, 64, 128, 256
  ],


  fonts: {
    sans: 'system-ui, sans-serif',
    mono: 'Menlo, monospace',
    Montserrat: 'Montserrat, sans-serif',
    mont: 'Montserrat, sans-serif',
    SFUIDisplay: 'SFUIDisplay, sans-serif',
    SFUIDisplayBold: 'SFUIDisplay-Bold, sans-serif',
    Roboto: 'Roboto, sans-serif'
  },

  variants: {
    oddRow: {
      background: '#2e353b'
    },
    evenRow: {
      background: '#292e32'
    },
  }
  /*shadows: {*/
  /*small: '0 0 4px rgba(0, 0, 0, .125)',*/
  /*large: '0 0 24px rgba(0, 0, 0, .125)'*/
  /*}*/
}

var labelButton = {
  padding: '4px 8px',
  marginRight: '4px',
  background: colors.OuterSpace,
  fontSize: '11px',
  color: colors.PigeonPost,
  fontFamily: 'Montserrat, sans-serif',
  borderRadius: '3px',
  lineHeight: 'normal',
  cursor: 'pointer',
}
labelButton.active = Object.assign({}, labelButton)
labelButton.active.color = colors.OuterSpace
labelButton.active.background = colors.Anakiwa

var filterLabel = Object.assign({}, labelButton)
filterLabel.backgroundColor = '#414f59'
filterLabel.color = '#beccde'
filterLabel.fontSize = '10px'
filterLabel.fontWeight = '600'
filterLabel.padding = '3px 7px'
filterLabel.cursor = 'default'
filterLabel.margin = '2px'
filterLabel.maxHeight = '19px'

var filterButton = {
  fontFamily: 'Montserrat, sans-serif',
  fontSize: '10px',
  fontWeight: 500,
  background: colors.Heather3,
  height: '23px',
  padding: '0 8px',
  marginRight: '6px',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#DEE7EF'
  }
}
filterButton.active = Object.assign({}, filterButton)
filterButton.active.background = colors.Anakiwa
filterButton.active['&:hover'] = { backgroundColor: '#a8e2fb' }

var smallFilter = {...filterButton};
smallFilter.height = '20px';
smallFilter.padding = '3px';
smallFilter.fontSize = '14px';
smallFilter.fontWeight = 'bolder';
smallFilter.background = 'transparent';
smallFilter.color = '#85D6FF';
smallFilter['&:hover'] = {}

theme.variants.filterLabel = filterLabel

theme.buttons = {
  label: labelButton,
  filter: filterButton,
  smallFilter: smallFilter,
  mobileFilter: {
    cursor: 'pointer',
    margin: '0px',
    background: '#2b3342',
    color: '#869cab',
    fontFamily: 'SFUIDisplay',
    height: '100%',
    width: '100%',
    borderRadius: '0px',
    padding: '2%',
    active: {
      height: '100%',
      width: '100%',
      cursor: 'pointer',
      margin: '0px',
      color: '#869cab',
      borderRadius: '0px',
      background: '#85d6ff',
      color: '#1c212b',
      fontFamily: 'SFUIDisplay',
      padding: '2%'
    }
  },
  swButton: {
    cursor: 'pointer',
    background: 'inherit',
    color: '#8b9daa',
    border: '1px solid',
    fontFamily: 'SFUIDisplay',
    padding: '3px',
    active: {
      cursor: 'pointer',
      background: '#85d6ff',
      color: '#1c212b',
      fontFamily: 'SFUIDisplay',
      padding: '3px'
    }
  },
  productItem: {
    cursor: 'pointer',
    margin: 'auto 0',
    background: 'transparent',
    border: '1px solid #8fa1b0',
    color: '#beccde',
    fontFamily: 'SFUIDisplay',
    height: '24px'
  },
  cancel: {
    cursor: 'pointer',
    background: 'transparent',
    border: '1px solid #565f66',
    color: 'white',
    height: 'auto',
    fontSize: '15.6px',
    fontWeight: 'bold',
    margin: '16px 5px 16px 16px',
    width: '49%'
  },
  action: {
    cursor: 'pointer',
    backgroundColor: colors.Supernova,
    border: 0,
    color: 'black',
    height: 'auto',
    fontSize: '15.6px',
    fontWeight: 'bold',
    margin: '16px 16px 16px 5px',
    width: '49%',
  },
  blue: {
    cursor: 'pointer',
    backgroundColor: '#85d6ff',
    border: 0,
    color: 'black',
    height: 'auto',
    fontSize: '15.6px',
    fontWeight: 'bold',
    margin: '16px 16px 16px 5px',
    width: '49%',
  },
  downloadItem: {
    cursor: 'pointer',
    margin: 'auto 0',
    border: '1px solid #8fa1b0',
    color: '#beccde',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: '#ffcf00',
      border: 0,
    }
  },
  yellowDownloadItem: {
    cursor: 'pointer',
    margin: 'auto 0',
    color: '#1c212b',
    backgroundColor: '#ffcf00'
  },
  round: {
    marginLeft: '5px',
    padding: '2px',
    border: '0px',
    borderRadius: '50%',
		outline: 'none'
  },
  expand: {
    cursor: 'pointer',
    fontWeight: 'bold',
    fontSize: '13px',
    borderRadius: '5px',
    border: 'solid 1px rgb(191, 203, 215)',
    padding: '4px 13px',
    background: 'transparent',
    fontFamily: 'Montserrat-Bold, sans-serif',
    color: 'rgb(191, 203, 215)',
    '&:hover': {
      color: 'white',
      borderColor: 'white'
    }
  },
  roundedYellow: {
    margin: 'auto 0',
    background: '#ffcf00',
    height: '37px',
    borderRadius: '35px',
    padding: '0 26px',
    fontSize: '15px',
    color: '#111111',
    fontWeight: 'bold',
    cursor: 'pointer',
    display: 'block'
  },
  roundedTransparent: {
    margin: 'auto 0',
    background: 'transparent',
    height: '37px',
    borderRadius: '35px',
    border: 'solid 1px #ffffff',
    padding: '0 26px',
    fontSize: '15px',
    color: 'white',
    fontWeight: 'bold',
    cursor: 'pointer',
    display: 'block'
  },
  grayTransparent: {
    margin: 'auto 0',
    border: 'solid 1px #8fa1b0',
    background: 'transparent',
    color: '#8fa1b0',
    borderRadius: '4px',
    height: '27px',
    padding: '0 12px',
    fontSize: '13px',
    fontWeight: 'bold',
    cursor: 'pointer',
    '&:hover': {
      color: 'white'
    }
  },
  redTransparent: {
    margin: '16px',
    border: 'solid 1px #565f66',
    background: 'transparent',
    color: '#eb413d',
    borderRadius: '4px',
    height: '27px',
    padding: '0 12px',
    fontSize: '15.6px',
    fontWeight: 'bold',
    cursor: 'pointer',
    '&:hover': {
      color: '#6c2f32'
    }
  }
}

export default theme

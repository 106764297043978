import { action, extendObservable, reaction } from 'mobx';
import API from '../config/api';
import { API_ROUTES } from '../config/api_routes';
import filtersStore from './filtersStore.js'
import cloudItemsStore from './cloudItemsStore.js'
import authStore from './authStore.js'

const initialState = {
  isLoading: false,
  items: [],
  tags: {},
  all: [],
  bpmRange: { min: 1, max: 999 },
  allPageGenreId: null,
  matchedItem: null,
  error: null,
};

export class menuStore {
  constructor() {
    extendObservable(this, initialState);
    this.fetchMenu()
  }

  byName(a, b) {
    if (a.name < b.name)
      return -1;
    if (a.name > b.name)
      return 1;
    return 0;
  }

  @action fetchMenu = async () => {
    this.isLoading = true;
    try {
      const { data } = await API(API_ROUTES.MENU);
      this.items = data.tags;
      this.tags = this.getKeys();
      this.bpmRange = data.bpm_range
      this.all = data.all;
      if (this.allPageGenreId) {
        this.setGenreTag();
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }
  };

  setGenreTag() {
    const tag = this.all.find(tag => tag.id === this.allPageGenreId);
    this.matchedItem = tag;
    const found = filtersStore.and_tags.find((tag) => tag === tag.uuid);
    if (tag && !found) {
      filtersStore.and_tags.push(tag.uuid);
    }
    this.allPageGenreId = null;
    cloudItemsStore.fetchCloudItems();
  }

  getItems(menu) {
    if(menu == 'Key and BPM') {
      menu = 'Key'
    }
    return this.items.filter(item => item.menu === menu).sort(this.byName)
  }

  getInstruments() {
    return this.getItems('Instruments')
  }

  getGenres() {
    return this.getItems('Genres')
  }

  getLabels() {
    return this.getItems('Labels')
  }

  getKeys() {
    var keyItems = this.items.filter(item => item.menu === 'Key');
    var result = {};
    keyItems.forEach((key) => {
      result[key.name] = key.uuid;
    });
    return result;
  }
}


export const menu = new menuStore();
export default menu;

import React, { Component } from 'react'
import UserAction from '#helpers/UserAction.js'
import cloudPageUrl from '#helpers/cloudPageUrl.js'

export default class DesktopMenu extends Component {
  render() {
    return (
      <div
        className='absolute bottom-4 right-[-1.75rem] translate-y-full min-w-[130px]'
      >
        <div className='pt-7 mt-[-1px]'></div>
        <ul className='text-base font-medium text-right'>
          <li className='px-3 py-3 bg-orange-1 cursor-pointer text-gray-16'>
            <a href={cloudPageUrl('myaccount')}>
              Your account
            </a>
          </li>
          <li className='px-3 py-3 bg-gray-12 cursor-pointer text-white'>
            <a onClick={() => UserAction.logout()}>
              Log out
            </a>
          </li>
        </ul>
      </div>
    )
  }
}

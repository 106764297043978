import React, { Component } from 'react'
import myCollectionsMenuStore from '../../stores/myCollectionsMenuStore'
import CustomCheckbox from '../common/CustomCheckbox'
import Loader from '#images/loader.gif'
import collectionStore from '../../stores/collectionStore'

class ContextMenuItem extends Component {
  constructor(props) {
    super(props);
    this.state = { checked: false, userAction: false, progress: false };
  }

  get checked() {
    if (this.state.userAction) {
      return this.state.checked;
    } else {
      return this.props.checked;
    }
  }

  async add(collection) {
    await myCollectionsMenuStore.add(collection.id)
    this.setState({ progress: false })
    this.props.closeAction()
  }

  async remove(collection) {
    const { removalCallback, closeAction } = this.props
    await myCollectionsMenuStore.removeItem(collection.id)
    this.setState({ progress: false })
    closeAction()
    if (collectionStore.id === collection.id) await removalCallback()
  }

  click = async (e) => {
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
    const { collection } = this.props
    this.setState(() => ({ progress: true }))
    if (this.checked) {
      this.setState(() => ({ checked: false, userAction: true }))
      await this.remove(collection)
    } else {
      this.setState(() => ({ checked: true, userAction: true }))
      await this.add(collection)
    }
  }

  render() {
    const { collection } = this.props;
    const { progress } = this.state
    const name = collection?.name ?? ""

    return (
      <div
        className='flex items-center min-h-6 mb-1 cursor-pointer hover:bg-beige-1 hover:text-gray-16 rounded text-lg md:text-base py-1 relative min-h-fit'
        onClick={this.click}
      >
        {
          progress ? (
            <div className='ml-1 w-fit min-h-[24px] max-h-[24px] min-w-[24px] max-w-[24px]'>
              <img
                src={Loader}
                className='min-h-[32px] max-h-[32px] min-w-[32px] max-w-[32px] -translate-x-1 -translate-y-1'
                alt='Loading'
              />
            </div>
          ) : (
            <div className='ml-1 w-fit'>
              <div>
                <CustomCheckbox
                  checked={this.checked}
                  readOnly={true}
                  width='24'
                  height='24'
                  fillClass='[&_path]:fill-gray-5 max-w-fit'
                />
              </div>
            </div>
          )
        }

        <span className='line-clamp-1 break-all ml-4'>{ name }</span>
      </div>
    )
  }
}

export default ContextMenuItem

import React from 'react';
import Browser from '../common/Browser';
import { Slide } from 'pure-react-carousel';
import { inject, observer } from 'mobx-react';
import { isMobile, isTablet, isMobileHorizontal } from '#helpers/rwd.js'
import HomeCollectionTile from '../collections/HomeCollectionTile';

@inject('homeStore')
@observer
class HomeCollectionsBrowser extends Browser {
  get collections() { return this.props.homeStore.collections }

  get totalEntries() { return this.collections.totalCount }

  get entries() { return this.collections.list.length }

  get hasOverflow() { return this.totalEntries > 4 }

  get showCounter() { return false }

  get showAllLink() { return "/collections" }

  calculateVisibleSlides() {
    if (window.innerWidth < 640) {
      return 1.05
    } else if (window.innerWidth <= 1024) {
      return 2.05
    } else if (window.innerWidth > 1024 && window.innerWidth < 1440) {
      return 3.05
    } else {
      return 3
    }
  }

  renderProducts() {
    return this.collections.list.map((collection, i) => {
      const { id } = collection
      return (
        <Slide key={id} index={i} className='slide home-collections-browser-slide'>
          <HomeCollectionTile id={id} index={i} collection={collection} />
        </Slide>
      )
    })
  }
}

export default HomeCollectionsBrowser

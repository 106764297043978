import React from 'react';
import Browser from '../common/Browser';
import LabelTile from './LabelTile';
import { Slide } from 'pure-react-carousel';
import { inject, observer } from 'mobx-react';
import { isMobile, isMobileHorizontal, isTablet, isDesktop, isBigDesktop } from '../../helpers/rwd.js'

@inject('homeStore')
@observer
class HomeLabelsBrowser extends Browser {
  get labels() { return this.props.homeStore.userLabels.filter( label => label.totalProducts ) }

  get totalEntries() { return this.labels.length }

  get entries() { return this.labels.length }

  get hasOverflow() { return this.totalEntries > 4 }

  get showCounter() { return false }

  calculateVisibleSlides() {
    if (isMobileHorizontal()) {
      return 2
    } else if (isMobile()) {
      return 1
    } else if (isTablet()) {
      return 3
    } else if (isBigDesktop()) {
      return 5
    } else {
      return window.innerWidth / 384
    }
  }

  renderProducts() {
    return this.labels.map((label, idx) =>
     <Slide key={idx} className='slide ml-2.5' index={idx}>
       <LabelTile index={idx} label={label} />
     </Slide>
    )
  }
}

export default HomeLabelsBrowser

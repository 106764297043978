import { createPortal } from 'react-dom'
import React, { Component, lazy, Suspense } from 'react'
import Header from './components/common/Header.js'
import SubHeader from './components/common/SubHeader.js'
import history from './components/common/history.js'
import BackToBeatport from './components/common/BackToBeatport.js'
import DownloadDesktopApp from './components/common/DownloadDesktopApp.js'
import HomePage from './components/home/HomePage.js'
import DownloadDesktopAppModal from './components/modals/DownloadDesktopAppModal.js'
import HomepageRecentsSlider from './components/home/HomepageRecentsSlider.js'
import Logout from './components/common/Logout'
import FlashBox from './components/common/FlashBox'
import { Router, Route } from "react-router-dom"
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import { inject, observer } from 'mobx-react'
import { observe } from 'mobx'
import { Cookies } from 'react-cookie'
import { APIFunctions } from './config/api'
import { clearPlaylist, setBeatportUrlAndCookie } from './helpers/UserAction.js'
import QuestionMark from "./images/question-mark.svg"
import { Helmet } from 'react-helmet'
import IdleTimer from 'react-idle-timer'

import ModalRenderer from './components/modals/ModalRenderer.js'

const FavouriteItems = lazy(() => import('./components/favourites/FavouriteItems'))
const Sidebar = lazy(() => import('./components/sidebar/Sidebar.js'))
const Footer = lazy(() => import('./components/common/Footer.js'))
const LabelPage = lazy(() => import('./components/labels/LabelPage.js'))
const LabelTitle = lazy(() => import('./components/labels/LabelTitle.js'))
const LabelBigBrowser = lazy(() => import('./components/labels/LabelBigBrowser.js'))
const AllProductsPage = lazy(() => import('./components/products/AllProductsPage.js'))
const MyCollectionsPage = lazy(() => import('./components/collections/MyCollectionsPage.js'))
const MyFiles = lazy(() => import('./components/library/MyFiles'))
const LibraryPacks = lazy(() => import('./components/library/LibraryPacks'))
const LibraryItems = lazy(() => import('./components/library/LibraryItems'))
const FavouritedProductsBrowser = lazy(() => import('./components/products/FavouritedProductsBrowser.js'))
const SearchPage = lazy(() => import('./components/products/SearchPage.js'))
const ProductPage = lazy(() => import('./components/products/ProductPage.js'))
const SearchRelated = lazy(() => import('./components/products/SearchRelated.js'))
const CollectionPage = lazy(() => import('./components/collections/CollectionPage.js'))
const CollectionsPage = lazy(() => import('./components/collections/CollectionsPage.js'))

const NoFallback = () => <></>

const modalOpenRootClasses = ['overflow-hidden', 'lg:overflow-visible', 'h-screen', 'lg:h-auto']

@inject('authStore', 'infoStore', 'flashBoxStore', 'modalStore')
@observer
class App extends Component {
  constructor(props) {
    super(props)
    this.idleTimer = null
    this.state = {
      demoAudio: null,
      isDemoPlaying: false,
      downloadDesktopAppVisible: false,
      rememberCloseDownloadDesktopApp: false
    }
    this.closeDownloadDesktopApp = this.closeDownloadDesktopApp.bind(this)
    this.toggleRememberNotification = this.toggleRememberNotification.bind(this)
    this.handleOnIdle = this.handleOnIdle.bind(this)
    this.modalHolder = props.modalHolder
    this.root = props.root
  }

  async componentDidMount() {
    const { authStore, infoStore } = this.props
    this.trackScroll()
    const cookies = new Cookies();
    const urlParams = new URL(document.location.href).searchParams
    if(cookies.get('_logout_cloud')) {
      APIFunctions.removeToken();
      await infoStore.reset();
    } else if(cookies.get('laccess_token') && cookies.get('lrefresh_token')) {
      var expires_at = parseInt(cookies.get('ltoken_expires_at') || 0);
      if(expires_at > Date.now() / 1000) {
        APIFunctions.setAuthenticationToken();
        // important - delete cookie
        var domain = APIFunctions.cookieDomain();
        cookies.remove('laccess_token', { domain });
        cookies.remove('lrefresh_token', { domain });
        cookies.remove('ltoken_expires_at', { domain });
        clearPlaylist();
      }
    } else if(urlParams.get('laccess_token')) {
      APIFunctions.setAuthenticationToken();
    }
    // important - delete cookie
    cookies.remove('_logout_cloud');

    if (authStore.isLoggedIn) {
      if (infoStore.cloudApp) {
        this.disposer3 = observe(infoStore, 'cloudApp', change => {
          this.setState({downloadDesktopAppVisible: !change.newValue && !localStorage.getItem('downloadDesktopAppKilledAt')})
        })
      } else {
        this.setState({downloadDesktopAppVisible: !localStorage.getItem('downloadDesktopAppKilledAt')})
      }
    }
  }
  componentWillUnmount() {
    if (this.disposer3) this.disposer3()
  }

  trackScroll() {
    // The debounce function receives our function as a parameter
    const debounce = (fn) => {
      // This holds the requestAnimationFrame reference, so we can cancel it if we wish
      let frame;
      // The debounce function returns a new function that can receive a variable number of arguments
      return (...params) => {
        // If the frame variable has been defined, clear it now, and queue for next frame
        if (frame) {
          cancelAnimationFrame(frame);
        }
        // Queue our function call for the next frame
        frame = requestAnimationFrame(() => {
          // Call our function and pass any params we received
          fn(...params);
        });
      }
    };
    // Reads out the scroll position and stores it in the data attribute
    // so we can use it in our stylesheets
    const storeScroll = () => {
      document.documentElement.dataset.scroll = window.scrollY;
    }
    // Listen for new scroll events, here we debounce our `storeScroll` function
    document.addEventListener('scroll', debounce(storeScroll), { passive: true });
    // Update scroll position for first time
    storeScroll();
  }

  closeDownloadDesktopApp() {
    this.setState({downloadDesktopAppVisible: false})
    if(this.state.rememberCloseDownloadDesktopApp) {
      localStorage.setItem('downloadDesktopAppKilledAt', Date.now())
    }
  }
  toggleRememberNotification() {
    this.setState({rememberCloseDownloadDesktopApp: !this.state.rememberCloseDownloadDesktopApp})
  }

  loadPapParams() {
    const params = new URLSearchParams(window.location.search);
    var papParams = ['a_aid', 'a_bid', 'a_cid', 'chan'];
    for (var i = 1; i < 6; i++) { papParams.push('data' + i); }

    const cookies = new Cookies();
    papParams.forEach((paramName) => {
      if (params.get(paramName) && !cookies.get(paramName))
        cookies.set(paramName, params.get(paramName), { domain: APIFunctions.cookieDomain(), path: '/' });
    });
  }

  handleOnIdle (event) {
    APIFunctions.removeToken()
    this.props.infoStore.reset()
  }

  render() {
    const labelPaths = ['/label/:id','/singomakers','/iqsamples','/classasamples']
    const beatportUrl = setBeatportUrlAndCookie();
    this.loadPapParams();

    if (this.props.modalStore.visibleModal) {
      this.root.classList.add(...modalOpenRootClasses)
    } else {
      this.root.classList.remove(...modalOpenRootClasses)
    }

    return (
      <Router history={history}>
        <IdleTimer ref={ref => { this.idleTimer = ref }} timeout={120 * 60 * 60 * 1000} onIdle={this.handleOnIdle} debounce={1000} />
        <Helmet defaultTitle='Sample Packs & Music Loops on Subscription | Loopcloud'>
          <title>Sample Packs & Music Loops on Subscription | Loopcloud</title>
          <meta name='description' content='Industry-leading library of royalty-free loops and samples. For professional producers and hobbyists alike. Check out these quality sounds now.'/>
        </Helmet>

        <Suspense fallback={<NoFallback />}>
          {
            this.props.modalStore.visibleModal && (
              createPortal(<ModalRenderer />, this.modalHolder)
            )
          }
          { createPortal(<DownloadDesktopAppModal />, this.modalHolder) }
        </Suspense>

        <a
          href='https://support.loopmasters.com/support/home'
          target='_blank'
          rel='noreferrer'
          className='hidden md:flex z-20 -rotate-90 fixed right-0 top-1/2 bg-gray-12 py-[3px] px-3 text-[22px] items-center translate-x-[35%] min-[1350px]:hover:bg-[#818181]'
        >
          <span>Support</span>
          <div className='pl-2.5'>
            <img src={QuestionMark} className='rotate-90' />
          </div>
        </a>
        <div className='flex flex-col items-center sticky z-30 top-0 w-full'>
          { beatportUrl && <BackToBeatport beatportUrl={beatportUrl} /> }
          {
            this.state.downloadDesktopAppVisible && !this.props.flashBoxStore.visible && (
              <DownloadDesktopApp
                onClose={this.closeDownloadDesktopApp}
                onToggleRemember={this.toggleRememberNotification}
                rememberNotToShowAgain={this.state.rememberCloseDownloadDesktopApp}
              />
            )
          }
          <Route path="/library/purchased" component={FlashBox} />
          <Header downloadDesktopAppVisible={this.state.downloadDesktopAppVisible} />
        </div>
        <SubHeader downloadDesktopAppVisible={this.state.downloadDesktopAppVisible} />

        <Route path="/" exact component={HomepageRecentsSlider} />
        <Suspense fallback={<NoFallback />}>
          <Route path={labelPaths} exact>
            <div className='w-full flex justify-center'>
              <div className='w-full relative max-w-[1920px]'>
                <LabelTitle />
                <LabelBigBrowser />
              </div>
            </div>
          </Route>
          <Route path="/logout" exact component={Logout} />
        </Suspense>
        <div className='flex w-full justify-center items-start'>
          <div className='flex justify-center w-full max-w-[1920px] pb-20'>
            <div className='w-full min-w-0'>
              <Route path="/" exact component={HomePage} />
              <Suspense fallback={<NoFallback />}>
                <Route path="/search/" component={SearchPage} />
                <Route path="/genre/:id" component={SearchPage} />
                <Route path="/label-samples/:id" component={SearchPage} />
                <Route path="/instrument/:id" component={SearchPage} />
                <Route path="/products" component={SearchRelated} />
                <Route path="/all/:genre" component={SearchRelated} />
                <Route path="/product/:id" component={ProductPage} />
                <Route path="/products-favourited" component={FavouritedProductsBrowser} />
                <Route path="/collection/:id" component={CollectionPage} />
                <Route path="/collections" component={CollectionsPage} />
                <Route path="/my-collections" exact component={MyCollectionsPage} />
                <Route path="/all_products/:type/:id?" component={AllProductsPage} />
                <Route path={labelPaths} component={LabelPage} />
                <Route path="/favourites" component={FavouriteItems} />
                <Route path="/library/:flash?" component={LibraryPacks} />
                <Route path="/library-files/:uuid?" component={MyFiles} />
                <Route path="/library-pack/:id" component={LibraryItems} />
              </Suspense>
            </div>
            <Suspense fallback={<NoFallback />}><Sidebar /></Suspense>
          </div>
        </div>
        <Suspense fallback={<NoFallback />}><Footer /></Suspense>
        {
          process.env.REACT_APP_AWS_ENVIRONMENT ? <div style={{ position: 'fixed', padding: 0.25+ 'rem', textAlign: 'center', bottom: 0, left: 0, right:0, background: 'orange', color: 'white', fontSize: 12 + 'px', zIndex: 1000}}>AWS Environment</div> : null
        }
      </Router>
    );
  }
}

export default App;

import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { inject, observer } from 'mobx-react'

import menuStore from '#stores/menuStore'

@inject('filtersStore', 'authStore')
@observer
class CategoryTileDropdownItem extends Component {
  constructor(props) {
    super(props)
    this.state = { lastPath: null }
  }

  componentDidMount() {
    this.setState({ lastPath: this.props.history.location.pathname })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.history.location.pathname !== this.state.lastPath) {
      this.setState({ lastPath: this.props.history.location.pathname })
    }
  }

  get shouldBeLink() {
    if (!this.props.authStore.isLoggedIn) return true

    let path = this.state.lastPath
    if (!path && document) path = document.location.pathname
    if (!path) return true

    const isSearch = path.match(/^\/search|^\/genre\/|^\/instrument|^\/label-samples/)
    return !isSearch
  }

  get itemUrl() {
    const { item } = this.props

    const seoId = `${item.id}-${item.name.replace(/[^a-z0-9\-_]+/ig, '-')}`
    let kind
    if (item.menu.match(/genre/i)) {
      kind = 'genre'
    } else if (item.menu.match(/label/i)) {
      kind = 'label-samples'
    } else if (item.menu.match(/instrument/i)) {
      kind = 'instrument'
    } else {
      return null
    }

    return `/${kind}/${seoId}`
  }

  addToTags = () => {
    if (this.shouldBeLink) return

    const { filtersStore, item, history } = this.props

    filtersStore.page = 1
    filtersStore.changeTags(item.uuid)
    let params = filtersStore.toParams()

    const blank = filtersStore.isBlank()
    let path = window.location.pathname
    if (blank) { path = '/' }
    if (!filtersStore.isCustomUrl()) { path = '/search' }
    history.push(`${path}?${params}`)
  }

  render() {
    const { isParent, upcaseParent } = this.props
    const { name } = this.props.item
    const formattedName = upcaseParent && isParent ? name.toUpperCase() : name
    return (
      <li
        onClick={this.addToTags}
        className={`py-2 cursor-pointer text-lg flex whitespace-nowrap w-full lg:text-[15px] 2xl:text-sm ${isParent ? 'font-bold text-orange-1 hover:text-orange-2' : 'text-gray-4 hover:text-white'}`}
      >
        {
          this.shouldBeLink ? (
            <Link to={this.itemUrl} className='line-clamp-1 whitespace-normal'>{ formattedName }</Link>
          ) : (
            <span className='line-clamp-1 whitespace-normal'>{ formattedName }</span>
          )
        }
      </li>
    )
  }
}

export default withRouter(CategoryTileDropdownItem);

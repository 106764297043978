import React, { Component } from 'react';
import ItemTile from '../products/ItemTile';
import filtersStore from '../../stores/filtersStore.js'
import { Link } from "react-router-dom"
import { isMobile, isMobileHorizontal } from '../../helpers/rwd.js'
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

import ArrowLeftIcon from '#images/arrow-left-slider.svg'
import ArrowRightIcon from '#images/arrow-right-slider.svg'

class Browser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visibleSlides: this.calculateVisibleSlides(),
      sliderEnabled: true
    };
  }

  calculateVisibleSlides() {
    return isMobileHorizontal() ? 2.99 : (isMobile() ? 2.25 : 5.99)
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowResize.bind(this))
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize)
  }

  handleWindowResize() {
    this.setState({
      visibleSlides: this.calculateVisibleSlides()
    })
  }


  get entries() { return this.products.length }

  beforeProductsHook() { return false }

  renderProducts() {
    const hookContent = this.beforeProductsHook()
    if (hookContent) {
      return hookContent
    }

    return this.products.map((product, i) =>
      <Slide
        key={product.id}
        index={i}
        className='opacity transition-opacity slide'
        classNameHidden={`${isMobile() ? 'opacity-30' : ''}`}
      >
        <ItemTile
          product={product}
          id={product.id}
          index={i}
          cover={product.cover_google_merchant}
          title={product.title || product.name}
          labelName={product.label_name}
          labelID={product.label_id}
        />
      </Slide>
    );
  }

  get showCounter() { return true }

  get hasOverflow() { return this.products && this.products.length > 6 }

  get visible() {
    return this.totalEntries >= (this.props.min || 1)
  }

  get showAllLink() {
    if (this.props.collectionName === "collections") {
      return "/collections"
    } else if(this.props.collectionName === "labelFocus"){
      return `/label/${this.props.labelSeoId}`
    } else if (this.props.collectionName)  { // Homepage stripes
      return '/all_products/' + this.props.collectionName
    } else if (this.props.genreSeoId && this.props.title === "Relevant") {
      return "/all/" + this.props.genreSeoId + "?" + filtersStore.releatedParams(1);
    } else if (this.props.genreSeoId) {
      return '/genre/' + this.props.genreSeoId
    } else { // Relevant products
      return "/products?" + filtersStore.shortParams()
    }
  }

  get title() { return this.props.title }

  onTouchStart = (e) => {
    this.touchStartX = e.touches[0].clientX
    this.touchStartY = e.touches[0].clientY
  }

  onTouchMove = (e) => {
    if (this.touchStartX && this.touchStartY) {
      const verticalMove = Math.abs(this.touchStartX - e.changedTouches[0].clientX)
      const horizontalMove = Math.abs(this.touchStartY - e.changedTouches[0].clientY)

      console.log('vertical: '+verticalMove+', horizontal: '+horizontalMove)

      // Detect whether move is horizontal or vertical
      if (verticalMove > horizontalMove) {
        document.body.style.overflow = 'hidden'
      } else if (horizontalMove > verticalMove) {
        this.setState({sliderEnabled: false})
      }

      // If move was diagonal, wait for next touchMove event
      if (verticalMove != horizontalMove) {
        this.touchStartX = null
        this.touchStartY = null
      }
    }
  }

  onTouchEnd = () => {
    document.body.style.overflow = 'auto'
    this.setState({sliderEnabled: true})
  }

  get label() { return 'Packs' }

  get step() { return Math.round(this.state.visibleSlides) }

  get containerClasses() { return 'mb-6 md:mb-10 2xl:mb-8' }

  render() {
    return this.visible ? (
      <div className={this.containerClasses}>
        <CarouselProvider
          isIntrinsicHeight={true}
          totalSlides={this.entries}
          visibleSlides={this.state.visibleSlides}
          step={this.step}
          dragStep={this.step}
          dragEnabled={false}
          touchEnabled={this.state.sliderEnabled}
        >
          <div
            className={`flex mb-4 md:mb-5 justify-between w-full ${this.label.length > 0 && this.showCounter ? 'items-end' : 'items-baseline'}`}
          >
            <div className='flex flex-col md:flex-row items-baseline'>
              <span className='font-bold text-[28px] md:text-[21px] 2xl:text-[23px]'>
                { this.title }
              </span>
              {
                this.showCounter && (
                  <span className='md:ml-1.5 text-gray-7'>
                    { this.totalEntries } { this.label.toLowerCase() }
                  </span>
                )
              }
            </div>
            {
              this.hasOverflow && (
                <div className='flex ml-auto items-center 2xl:justify-end'>
                  {
                    this.props.showAll && (
                      <Link to={this.showAllLink}>
                        <span className='mr-5 whitespace-nowrap text-lg md:text-base md:mr-2.5'>
                          Show All
                        </span>
                      </Link>
                    )
                  }
                  <ButtonBack
                    className='hidden md:block bg-gray-11 p-2 rounded-3xl disabled:opacity-50 disabled:cursor-default'
                    children={<img src={ArrowLeftIcon} />}
                  />
                  <ButtonNext
                    className='ml-1.5 hidden md:block bg-gray-11 p-2 rounded-3xl disabled:opacity-50 disabled:cursor-default mr-8 min-[1350px]:mr-0'
                    children={<img src={ArrowRightIcon} />}
                  />
                </div>
              )
            }
          </div>
          <Slider
            className='product-slider'
            classNameAnimation='transition-transform'
            trayProps={{
              onTouchStart: this.onTouchStart,
              onTouchMove: this.onTouchMove,
              onTouchEnd: this.onTouchEnd
            }}
          >
            {this.renderProducts()}
          </Slider>
        </CarouselProvider>
      </div>
    ) : null
  }
}

export default Browser

import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { Link } from 'react-router-dom'

import cloudPageUrl from '#helpers/cloudPageUrl'

import Piano from "../menu/Piano"
import BpmFilter from './BpmFilter'
import CategoryTileWithDropdown from './subheader/CategoryTileWithDropdown'
import CategoryTileDropdownItems from './subheader/CategoryTileDropdownItems'
import DesktopDownloadReminder from './subheader/DesktopDownloadReminder'

import CollectionsIcon from '#images/collections.svg'
import CollectionsYellowIcon from '#images/collections-yellow.svg'
import HeartIcon from '#images/heart-white.svg'
import HeartYellowIcon from '#images/heart-yellow.svg'
import UserIcon from '#images/user-2.svg'
import UserYellowIcon from '#images/user-2-yellow.svg'
import PointsIcon from '#images/point-white.svg'

import 'react-input-range/lib/css/index.css'

@inject('menuStore', 'infoStore', 'authStore', 'filtersStore')
@observer
class SubHeader extends Component {
  constructor(props) {
    super(props);
    this.menus = {};
    this.state = { openCategoryDropdown: null }
  }

  get upgradable() {
    const { infoStore } = this.props
    return infoStore.subscription_level && infoStore.subscription_level !== 'professional'
  }

  get upgradeUrl() {
    return process.env.REACT_APP_LOOP_API_URL + '/cloud/myaccount'
  }

  get loggedIn() {
    const { infoStore, authStore } = this.props
    return (authStore.isLoggedIn && !!infoStore.email);
  }

  get findOutMoreLink() {
    return this.loggedIn ? cloudPageUrl('subscriptions/download') : cloudPageUrl('subscriptions/plans')
  }

  get shouldRenderDesktopReminder() {
    const { authStore, infoStore } = this.props
    return authStore.isLoggedIn && !infoStore.cloudApp && localStorage.getItem('downloadDesktopAppModalKilledAt')
  }

  groupProductsByParents = (products) => {
    const { menuStore } = this.props

    const groupByParentId = (products) => {
      const map = new Map()
      products.forEach(product => {
        const id = product.parent_id || product.id
        const group = map.get(id) || []
        product.parent_id ? group.push(product) : group.unshift(product)
        map.set(id, group)
      })
      return map
    }

    const filterExistingParents = (map) => {
      const filteredMap = new Map()
      for (let [id, products] of map.entries()) {
        if (products[0].id.toString() === id.toString()) {
          filteredMap.set(id, products)
        }
      }
      return filteredMap
    }

    const sortProductsByName = (map) => {
      const entries = Array.from(map.entries())
      return entries.map(([parentId, products]) => {
        const [parent, ...rest] = products
        return [parentId, [parent, ...rest.sort(menuStore.byName)]]
      }).sort((entryA, entryB) => menuStore.byName(entryA[1][0], entryB[1][0]))
    }

    const parentIdsWithProducts = groupByParentId(products)
    const existingParentsWithProducts = filterExistingParents(parentIdsWithProducts)
    return sortProductsByName(existingParentsWithProducts)
  }

  setOpenCategory = (categoryDropdown) => {
    this.setState({ openCategoryDropdown: categoryDropdown })
  }

  render() {
    return (
      <div
        className='flex justify-center'
        onMouseLeave={() => this.setOpenCategory(null)}
      >
        <ul className='flex text-xl lg:px-8 max-w-[1920px] w-full bg-gray-12 relative'>
          <CategoryTileWithDropdown
            name='Instruments'
            shown={this.state.openCategoryDropdown === 'Instruments'}
            setOpenCategory={this.setOpenCategory}
          >
            <CategoryTileDropdownItems
              groupedItems={this.groupProductsByParents(this.props.menuStore.getInstruments())}
              upcaseParents={true}
            />
          </CategoryTileWithDropdown>

          <CategoryTileWithDropdown
            name='Genres'
            shown={this.state.openCategoryDropdown === 'Genres'}
            setOpenCategory={this.setOpenCategory}
          >
            <CategoryTileDropdownItems
              groupedItems={this.groupProductsByParents(this.props.menuStore.getGenres())}
              upcaseParents={true}
            />
          </CategoryTileWithDropdown>

          <CategoryTileWithDropdown
            name='Labels'
            shown={this.state.openCategoryDropdown === 'Labels'}
            setOpenCategory={this.setOpenCategory}
          >
            <CategoryTileDropdownItems
              groupedItems={this.groupProductsByParents(this.props.menuStore.getLabels())}
              categoryClass='font-normal text-gray-4'
            />
          </CategoryTileWithDropdown>

          <CategoryTileWithDropdown
            name='Key and BPM'
            tileClass='hidden lg:list-item'
            shown={this.state.openCategoryDropdown === 'Key and BPM'}
            setOpenCategory={this.setOpenCategory}
          >
            <div className='flex'>
              <Piano />
              <BpmFilter />
            </div>
          </CategoryTileWithDropdown>
          {
            this.shouldRenderDesktopReminder && (
              <DesktopDownloadReminder findOutMoreLink={this.findOutMoreLink} />
            )
          }
          {
            this.loggedIn && (
              <>
                <li className='hidden 2xl:list-item py-2.5 px-4 ml-auto group text-lg'>
                  <Link className='flex items-baseline cursor-pointer' to='/collections'>
                    <img src={CollectionsIcon} className='inline-block h-3.75 w-4.5 mr-1.5 group-hover:hidden' />
                    <img src={CollectionsYellowIcon} className='hidden h-3.75 w-4.5 mr-1.5 group-hover:inline-block' />
                    Collections
                  </Link>
                </li>
                <li className='hidden 2xl:list-item py-2.5 px-4 group text-lg'>
                  <Link className='flex items-baseline cursor-pointer' to='/products-favourited'>
                    <img src={HeartIcon} className='inline-block h-3.75 w-4.5 mr-1.5 group-hover:hidden' />
                    <img src={HeartYellowIcon} className='hidden h-3.75 w-4.5 mr-1.5 group-hover:inline-block' />
                    Favourites
                  </Link>
                </li>
                <li className='hidden 2xl:list-item py-2.5 px-4 group text-lg'>
                  <Link className='flex items-baseline cursor-pointer' to='/library'>
                    <img src={UserIcon} className='inline-block h-3.75 w-4.5 mr-1.5 group-hover:hidden' />
                    <img src={UserYellowIcon} className='hidden h-3.75 w-4.5 mr-1.5 group-hover:inline-block' />
                    Your library
                  </Link>
                </li>
                {
                  this.upgradable && (
                    <li className='hidden md:list-item ml-auto 2xl:ml-0 py-2.5 px-4'>
                      <a className='text-orange-1 underline cursor-pointer text-lg' href={this.upgradeUrl}>
                        Upgrade
                      </a>
                    </li>
                  )
                }
                <li className={`hidden md:list-item py-2.5 px-4 lg:px-0 2xl:ml-0 ${this.upgradable ? '' : 'ml-auto'}`}>
                  <div className='flex items-center text-[19px] 2xl:text-lg '>
                    {this.props.infoStore.points}
                    <img src={PointsIcon} className='ml-1' />
                  </div>
                </li>
              </>
            )
          }
        </ul>
      </div>
    )
  }
}

export default SubHeader;

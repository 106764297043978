import React from 'react'
import { ReactComponent as BottomRightArrow } from '#images/bottom-right-arrow.svg'
import UserIcon from '#images/user-3.svg'

export default function UserIconButton(props) {
  const { toggleDesktopMenu } = props
  return (
    <li
      className='group hidden relative pl-2.5 py-2.5 2xl:flex items-center'
      onClick={toggleDesktopMenu}
    >
      <button
        type='button'
        className='h-full cursor-pointer flex items-center justify-end'
      >
        <div className='flex items-baseline mb-0.5'>
          <img src={UserIcon} className='pl-9.5'/>
          <BottomRightArrow className='ml-1 text-gray-5 group-hover:text-orange-1' />
        </div>
      </button>

      { props.children }
    </li>
  )
}

import React from 'react'
import Modal from '../common/Modal'
import { ReactComponent as Loader } from '#images/loader.svg'

class ProcessingModal extends Modal {
  get title() {
    return 'Processing your order'
  }

  get content() {
    return (
      <p className='font-bold'>
        Please wait while Loopcloud processes<span className='hidden md:inline-block'> your purchase</span>
      </p>
    )
  }

  get buttons() {
    return (
      <div className='w-full flex'>
        <div className='animate-spin w-fit h-fit'>
          <Loader />
        </div>
      </div>
    )
  }
}

export default ProcessingModal

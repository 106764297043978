export const API_ROUTES = {
  CLOUD_ITEMS: '/api-web/v1/cloud_items',
  SEARCH: '/api-web/v1/cloud_items/search',
  AUDIO: '/api-web/v1/cloud_items/audio/',
  OWNED: '/api-web/v1/cloud_items/owned',
  USER_ITEMS: '/api-web/v1/users/owned',
  INFO: '/api-web/v1/users/info',
  LIBRARY_PACKS: '/api-web/v1/products/user_products',
  LIBRARY_MAIN: '/api-web/v1/users/uploads',
  PACKS: '/api/cloud/products/bulk_show',
  LABEL: '/api-web/v1/layouts/labels/',
  LABELS: '/api/labels/bulk_show',
  TAGS_FOR_PRODUCT: '/api-web/v1/tags/for_product',
  PACK_INFO: '/api-web/v1/products/pack_info',
  PACK: '/api-web/v1/products',
  MENU: '/api-web/v1/tags/menu',
  BUY_PRODUCT: '/api-web/v1/shop/buy_product',
  BUY: '/api-web/v1/shop/buy',
  HOME_PACKS: '/api-web/v1/layouts/home',
  GENRE: '/api-web/v1/layouts/genre/',
  SIDEBAR: '/api-web/v1/layouts/sidebar',
  CLOUD_USER: '/api/cloud/user',
  WAVEFORMS: '/api-web/v1/demo_songs/waveforms',
  COLLECTIONS: '/api-web/v1/collections/search',
  COLLECTIONS_MENU: '/api-web/v1/collections/menu',
  COLLECTIONS_MENU_ITEM: '/api-web/v1/collections/item_menu',
  COLLECTION: '/api-web/v1/collections/',
  MY_COLLECTIONS: '/api-web/v1/collections',
  COLLECTIONS_BULK_SHOW: '/api-web/v1/collections/bulk_show',
  POPULAR_COLLECTIONS: '/api-web/v1/collections/popular',
  FAVOURITED_PACKS: '/api-web/v1/products/favourites',
  UNFAVOURITES_PACKS: '/api-web/v1/products/unfavourite',
};

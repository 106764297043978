import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { Link } from 'react-router-dom'

import cloudPageUrl from '#helpers/cloudPageUrl.js'
import UserAction from '#helpers/UserAction.js'

import { ReactComponent as BottomRightArrow } from '#images/bottom-right-arrow.svg'

@inject('authStore')
@observer
class MobileMenu extends Component {
  logOut = () => {
    UserAction.logout()
    this.props.closeMenu()
  }

  componentDidMount() {
    document.body.style.overflow = 'hidden'
  }

  componentWillUnmount() {
    document.body.style.overflow = 'unset'
  }

  render() {
    const { isLoggedIn } = this.props.authStore
    return (
      <>
        <div className='relative'>
          <div className='absolute bottom-0 left-0 right-0 w-[24px] h-[30px] translate-y-full'>
        </div>

        </div>
        <div className='grow w-full h-screen absolute left-0 right-0 bg-black mt-4 py-2 px-5 lg:px-12'>
          <nav>
            <ul className='text-[22px] text-right'>
              <li className='py-2.5'>
                <a
                  className='group cursor-pointer flex items-baseline justify-end'
                  href={cloudPageUrl('features')}
                >
                  Features
                  <BottomRightArrow className='ml-1 text-gray-5 group-hover:text-orange-1' />
                </a>
              </li>
              <li className='py-2.5 mt-2.5'>
                <a
                  className='group cursor-pointer flex items-baseline justify-end'
                  href={cloudPageUrl('plugins')}
                >
                  Plugin Suite
                  <BottomRightArrow className='ml-1 text-gray-5 group-hover:text-orange-1' />
                </a>
              </li>
              <li className='py-2.5 mt-2.5'>
                <a
                  className='group cursor-pointer flex items-baseline justify-end'
                  href={cloudPageUrl('subscriptions/plans')}
                >
                  Pricing
                  <BottomRightArrow className='ml-1 text-gray-5 group-hover:text-orange-1' />
                </a>
              </li>
              <li className='py-2.5 mt-2.5'>
                <a
                  className='group cursor-pointer flex items-baseline justify-end'
                  href={cloudPageUrl('tutorials')}
                >
                  Tutorials
                  <BottomRightArrow className='ml-1 text-gray-5 group-hover:text-orange-1' />
                </a>
              </li>
              <li className='py-2.5 mt-2.5'>
                <a
                  className='group cursor-pointer flex items-baseline justify-end'
                  href={cloudPageUrl('blog')}
                >
                  Blog
                  <BottomRightArrow className='ml-1 text-gray-5 group-hover:text-orange-1' />
                </a>
              </li>
              <li className='py-2.5 mt-2.5'>
                <Link
                  className='group cursor-pointer flex items-baseline justify-end'
                  to='/'
                >
                  Sounds
                  <BottomRightArrow className='ml-1 text-gray-5 group-hover:text-orange-1' />
                </Link>
              </li>
              {
                isLoggedIn && (
                  <>
                    <li className='py-2.5 mt-2.5'>
                      <a
                        className='group cursor-pointer flex items-baseline justify-end'
                        href={cloudPageUrl('myaccount')}
                      >
                        My Account
                        <BottomRightArrow className='ml-1 text-gray-5 group-hover:text-orange-1' />
                      </a>
                    </li>
                    <li className='py-2.5 mt-2.5'>
                      <a
                        className='group cursor-pointer flex items-baseline justify-end'
                        onClick={this.logOut}
                      >
                        Log out
                        <BottomRightArrow className='ml-1 text-gray-5 group-hover:text-orange-1' />
                      </a>
                    </li>
                  </>
                )
              }
              {
                !isLoggedIn && (
                  <li className='py-2.5 mt-2.5'>
                    <a
                      className='group cursor-pointer flex items-baseline justify-end'
                      href={cloudPageUrl('login?lcw=true')}
                    >
                      Log in
                      <BottomRightArrow className='ml-1 text-gray-5 group-hover:text-orange-1' />
                    </a>
                  </li>
                )
              }
            </ul>
          </nav>
        </div>
      </>
    )
  }
}

export default MobileMenu

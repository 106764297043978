import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import history from '#components/common/history.js'

import MobileModalLayout from './MobileModalLayout.js'

import CancelCrossedCircle from '#images/crossed-circle.svg'

@observer
@inject('filtersStore')
export default class MobileSearchMenu extends Component {
  constructor(props) {
    super(props)
    this.state = { searchedText: '' }
    this.textInputRef = React.createRef()
  }

  get filtersStore() {
    return this.props.filtersStore
  }

  componentDidMount() {
    this.textInputRef.current.focus()
  }

  changeText = (e) => {
    const searchedText = e.target.value
    this.setState((state) => { return { ...state, searchedText } })
  }

  resetText = () => {
    this.setState((state) => { return { ...state, searchedText: '' } })
  }

  submitSearch = () => {
    if (!this.state.searchedText.trim().length > 0) return

    this.filtersStore.setPhrase(this.state.searchedText)

    this.resetText()

    const params = this.filtersStore.toParams()
    history.push(`/search?${params}`)
    this.props.closeMenu()
  }

  render() {
    return (
      <MobileModalLayout closeModal={this.props.closeMenu} >
        <>
          <div className='flex mt-11 py-2 px-4 border border-gray-11 bg-gray-15 rounded grow'>
            <input
              type='text'
              placeholder='Search for sounds'
              className='grow bg-gray-15 outline-0 outline-none'
              ref={this.textInputRef}
              value={this.state.searchedText}
              onChange={(e) => this.changeText(e)}
            />
            <img
              src={CancelCrossedCircle}
              onClick={() => this.resetText()}
              className='cursor-pointer'
            />
          </div>

          <div className='flex flex-row-reverse mt-6'>
            <button
              type='button'
              className='cursor-pointer text-lg py-1 px-4 rounded-3xl text-black font-bold bg-orange-1'
              onClick={() => this.submitSearch()}
            >
              Search
            </button>
          </div>
        </>
      </MobileModalLayout>
    )
  }
}

import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import CustomCheckbox from './CustomCheckbox'
import cloudPageUrl from '#helpers/cloudPageUrl';

import { ReactComponent as MacIcon } from '#images/mac.svg'
import { ReactComponent as WindowsIcon } from '#images/win.svg'
import CrossIcon from '#images/x.svg'

@inject('AppLinksStore', 'authStore')
@observer
class DownloadDesktopApp extends Component {
  componentDidMount() {
    const { AppLinksStore } = this.props
    if (!AppLinksStore.ready) AppLinksStore.update()
  }

  close = () => { this.props.onClose() }

  get loggedIn() { return this.props.authStore.isLoggedIn }

  get findOutMoreLink() {
    return this.loggedIn ? cloudPageUrl('subscriptions/download') : cloudPageUrl('subscriptions/plans')
  }

  render() {
    const { macApp, winApp} = this.props.AppLinksStore
    return (
      <div className='hidden lg:block px-12 py-1.5 bg-orange-1 text-gray-16 font-medium text-[14px] w-full'>
        <div className='flex max-w-[1920px] mx-auto'>
          <div className='grow flex justify-between'>
            <div className='flex items-center font-bold'>
              <span>Do even more with Loopcloud desktop version!</span>
              <a href={this.findOutMoreLink} className='ml-2.5 underline'>Find out more</a>
            </div>

            <div className='hidden xl:flex items-center'>
              <span className='font-bold'>Download the desktop app</span>

              <div
                className='flex items-center border border-black rounded-[36px] ml-3 py-1.5 cursor-pointer hover:bg-gray-16 hover:border-transparent hover:text-orange-1 group'
                onClick={() => { window.location.href = winApp }}
              >
                <div className='ml-3.5 mr-2'>
                  <WindowsIcon className='[&_path]:fill-gray-16 group-hover:[&_path]:fill-orange-1' />
                </div>
                <span className='min-w-fit text-[17px] leading-[17px] pr-3.5'>For Windows</span>
              </div>

              <div
                className='flex items-center border border-black rounded-[36px] ml-3 py-1.5 cursor-pointer hover:bg-gray-16 hover:border-transparent hover:text-orange-1 group'
                onClick={() => { window.location.href = macApp }}
              >
                <div className='ml-3.5 mr-2'>
                  <MacIcon className='[&_path]:fill-gray-16 group-hover:[&_path]:fill-orange-1' />
                </div>
                <span className='min-w-fit text-[17px] leading-[17px] pr-3.5'>For Mac</span>
              </div>
            </div>

            <div className='flex items-center relative'>
              <CustomCheckbox
                onChange={this.props.onToggleRemember}
                checked={this.props.rememberNotToShowAgain}
                width={14}
                height={14}
                color='#3B4550'
              />
              <span className='ml-2 font-bold'>Don't show this again</span>
            </div>
          </div>

          <button type='button' className='ml-9'>
            <img src={CrossIcon} onClick={this.close} className='w-[14px] h-[14px]' />
          </button>
        </div>
      </div>
    )
  }
}

export default DownloadDesktopApp;

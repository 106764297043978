import { extendObservable } from 'mobx';

const initialState = {
  visibility: false,
  notificationVisibility: false, // desktop app modal
  deleteCollectionId: null
};

export class layerStore{
  constructor() {
    extendObservable(this, initialState);
  }
}

export default new layerStore();

import React, { Component } from 'react'
import { ReactComponent as BottomArrow } from '#images/bottom-arrow.svg'

export default class CategoryTileWithDropdown extends Component {
  constructor(props) {
    super(props)
  }

  toggle = () => {
    if (this.props.shown) {
      this.props.setOpenCategory(null)
    } else {
      this.props.setOpenCategory(this.props.name)
    }
  }

  render() {
    const { tileClass, shown, name, children } = this.props
    return (
      <li
        className={`cursor-pointer group grow text-center md:grow-0 md:text-start ${shown ? 'bg-gray-15' : ''} ${tileClass || ''} ${shown ? 'bg-gray-15' : ''}`}
        onClick={this.toggle}
      >
        <div className='px-[min(4.75vw,20px)] py-[min(2.375vw,10px)] md:px-4'>
          <button
            type='button'
            className='flex items-baseline'
          >
            <span className='text-[19px] tracking-[-0.6px] 2xl:text-lg'>{ name }</span>
            <BottomArrow className={`ml-1.5 text-lg lg:group-hover:text-orange-1 ${shown ? 'text-orange-1' : 'text-gray-5'}`} />
          </button>
        </div>
        {
          shown && (
            <>
              <div className='relative'></div>
              <div className='absolute z-20 left-0 right-0 p-8 bg-gray-15'>
                {
                  children
                }
              </div>
            </>
          )
        }
      </li>
    )
  }
}

import React, { Component } from 'react'

import CancelCross from '#images/cancel-cross.svg'

export default class MobileModalLayout extends Component {
  componentDidMount() {
    document.body.style.overflow = 'hidden'
  }

  componentWillUnmount() {
    document.body.style.overflow = 'unset'
  }

  render() {
    return (
      <div className='fixed z-50 top-0 right-0 bottom-0 left-0 bg-gray-16'>
        <div className='flex flex-col pt-9 px-5'>
          <div className='flex flex-row-reverse'>
            <button type='button' onClick={this.props.closeModal}>
              <img src={CancelCross} className='cursor-pointer' />
            </button>
          </div>

          {
            this.props.children
          }
        </div>
      </div>
    )
  }
}

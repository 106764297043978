import { APIFunctions } from '../config/api'
import API from '../config/api'
import { API_ROUTES } from '../config/api_routes'
import cloudItemsStore from '../stores/cloudItemsStore'
import playlistStore from '../stores/playlistStore'
import infoStore from '#stores/infoStore'
import authStore from '#stores/authStore'
import { Cookies } from 'react-cookie'
import qs from 'qs'

const logout = () => {
  if (authStore.isLoggedIn) {
    APIFunctions.removeToken()
    infoStore.reset()
    cloudItemsStore.reset()
    window.location.href = '/'
    return true
  }
  return false
}

const isBeatportActive = () => {
  return !!sessionStorage.getItem('beatport_ref');
}

export const setBeatportUrlAndCookie = () => {
  const urlParams = qs.parse(window.location.search.replace('?', ''));
  const sessionRef = sessionStorage.getItem('beatport_ref');
  if ((urlParams['ref_src'] || '').toLowerCase() === 'beatport' || sessionRef) {
    var beatportRef = document.referrer.match(/^https?:\/\/(www.)?beatport.com/);
    beatportRef = (beatportRef && beatportRef[0]) ? beatportRef[0] : 'https://beatport.com';
    if(!sessionRef) sessionStorage.setItem('beatport_ref', beatportRef);
    const cookies = new Cookies();
    cookies.set('bp', '1', { domain: APIFunctions.cookieDomain(), path: '/' });
    cookies.set('bpweb', '1', { domain: APIFunctions.cookieDomain(), path: '/', maxAge: 1800 });
    return sessionRef || beatportRef;
  }

  return false;
}

export const clearPlaylist = async () => {
  if (!localStorage.getItem('playlist')) return false;
  const playlist = JSON.parse(localStorage.getItem('playlist'));
  if (!playlist.length) return false;

  const ids = playlist.map(item => item.id);
  const { data } = await API.post(API_ROUTES.USER_ITEMS, { ids });
  if (data.ids.length) {
    playlistStore.remove(data.ids);
    playlistStore.currentlyPlays = null;
    playlistStore.currentAudio = null;
  }
}

const UserAction = { logout, clearPlaylist, setBeatportUrlAndCookie, isBeatportActive }
export default UserAction;

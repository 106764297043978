import React, { Component, createRef } from 'react';
import history from '#components/common/history.js'
import filtersStore from '#stores/filtersStore'

import { ReactComponent as MagnifierIcon } from '#images/magnifier.svg'

class SearchInput extends Component {
  constructor(props) {
    super(props)
    this.state = { focus: false, text: '' }
    this.inputRef = createRef()
  }

  focus = () => {
    this.setState({ focus: true })
    this.inputRef.current.focus()
  }

  blur = () => { this.setState({ focus: false }) }

  handleSubmit = (e) => {
    e.preventDefault()

    if (!this.state.text.trim()) return

    filtersStore.randomize = false;
    filtersStore.setPhrase(this.state.text)
    this.setState((state) => { return { ...state, text: '' } })
    window.scrollTo(0,0)
    let params = filtersStore.toParams()
    history.push(`/search?${params}`)
  }

  changeText = (e) => {
    const text = e.target.value
    this.setState((state) => { return { ...state, text } })
  }

  render() {
    return (
      <div className='flex hidden md:block'>
        <form onSubmit={e => this.handleSubmit(e)}>
          <div
            className={`flex py-2 px-2.5 border rounded border-gray-11 w-[310px] ${this.state.focus ? 'bg-gray-2' : 'bg-gray-15'}`}
            onFocus={this.focus}
            onBlur={this.blur}
            tabIndex={0}
          >
            <input
              tabIndex={0}
              type='text'
              placeholder="Search for Samples, Genres or Instruments"
              onFocus={this.focus}
              onBlur={this.blur}
              value={this.state.text}
              onChange={this.changeText}
              ref={this.inputRef}
              className={`peer grow text-[13px] font-medium outline-0 outline-none ${this.state.focus ? 'bg-gray-2 text-gray-16 placeholder-gray-16' : 'bg-gray-15 text-gray-7'}`}
            />

            <MagnifierIcon
              width='20'
              height='20'
              className={`ml-1.5 ${this.state.focus ? 'text-gray-16' : 'text-white'}`}
              onClick={e => this.handleSubmit(e)}
            />
          </div>
        </form>
      </div>
    )
  }
}

export default SearchInput;

import React from 'react'

export default function LogInBtn({ href }) {
  return (
    <li className='2xl:order-1 2xl:ml-2.5 2xl:py-2.5'>
      <a
        href={href}
        className='cursor-pointer py-1.5 px-3.5 rounded-3xl font-bold border-2 border-white text-base'
      >
        Log In
      </a>
    </li> 
  )
}

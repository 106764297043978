import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import CustomCheckbox from '../common/CustomCheckbox'
import { loopUrl } from '../../config/api'
import { ReactComponent as Windows } from '#images/win.svg'
import { ReactComponent as Mac } from '#images/mac.svg'
import DownloadDesktopBackground from '#images/download-desktop-background.svg'
import DownloadDesktopLaptop from '#images/download-desktop-laptop.png'
import CloseIcon from '#images/x.svg'

@inject('infoStore', 'authStore', 'AppLinksStore')
@observer
class DownloadDesktopAppModal extends Component {
  constructor(props) {
    super(props);
    const notificationKilledAt = localStorage.getItem('downloadDesktopAppKilledAt')
    const modalKilled = !!localStorage.getItem('downloadDesktopAppModalKilledAt')
    const { isLoggedIn } = props.authStore
    this.state = {
      // Appears 24h after closing top notification
      visible: isLoggedIn && !modalKilled && notificationKilledAt && (Date.now() - notificationKilledAt) > 1000*60*60*24,
      remember: false
    }
  }

  componentDidMount() {
    const { AppLinksStore } = this.props
    if(!AppLinksStore.ready) { AppLinksStore.update() }
  }

  toggleRemember = () => { this.setState({remember: !this.state.remember}) }

  close = () => {
    this.setState({visible: false})
    if(this.state.remember) {
      localStorage.setItem('downloadDesktopAppModalKilledAt', Date.now())
    }
  }

  get loggedIn() { return this.props.authStore.isLoggedIn }

  cloudLink(page) { return loopUrl + '/cloud/' + page }

  get findOutMoreLink() {
    if(this.loggedIn) {
      return this.cloudLink('subscriptions/download')
    } else {
      return this.cloudLink('subscriptions/plans')
    }
  }

  get isDesktop() { return window.innerWidth > 1024 }

  render() {
    const { macApp, winApp } = this.props.AppLinksStore
    if (!this.isDesktop || this.props.infoStore.cloudApp || !this.state.visible) return null

    return (
      <div className='hidden lg:block fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2'>
        <div className='absolute w-[920px] left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 bg-orange-1 rounded-md p-9 flex overflow-hidden'>
          <img
            src={DownloadDesktopBackground}
            className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2'
          />
          <div className='flex flex-col text-gray-16 z-[1] max-w-[450px]'>
            <h3 className='font-bold text-[34px] leading-[34px]'>Do more with the Loopcloud desktop app!</h3>
            <ul className='list-disc list-inside mt-7'>
              <li>Build-in effects</li>
              <li>Multitrack</li>
              <li>DAW connection</li>
              <li>Preview samples at your project’s BPM</li>
              <li>Tag, upload and manage your own samples library</li>
              <li>Powerful sample manager and editor</li>
            </ul>
            <a href={this.findOutMoreLink} className='underline mt-7'>
              Find out more
            </a>
            <p className='text-xl font-bold mt-7'>Download Loopcloud for desktop</p>
            <div className='flex mt-6 text-white'>
              <a href={winApp}>
                <div className='flex items-center bg-gray-16 px-6 py-1 rounded-[36px]'>
                  <Windows width='17' height='17' className='[&_path]:fill-white' />
                  <span className='ml-3.5 text-2xl'>For Windows</span>
                </div>
              </a>
              <a href={macApp} className='ml-5'>
                <div className='flex items-center bg-gray-16 px-6 py-1 rounded-[36px]'>
                  <Mac width='17' height='17' className='[&_path]:fill-white' />
                  <span className='ml-3.5 text-2xl'>For Mac</span>
                </div>
              </a>
            </div>
            <label className='flex items-center mt-5'>
              <CustomCheckbox
                color='#101010'
                onChange={this.toggleRemember}
                width='20'
                height='20'
              />
              <span>Don't show this again</span>
            </label>
          </div>

          <div className='absolute top-[36px] right-[36px] h-[34px] flex items-center'>
            <img src={CloseIcon} onClick={this.close} />
          </div>

          <div className='absolute right-[-80px] top-1/2 -translate-y-1/2 flex flex-col justify-center z-[1]'>
            <img src={DownloadDesktopLaptop} className='min-w-max min-h-max'/>
          </div>
        </div>
      </div>
    )
  }
}

export default DownloadDesktopAppModal

import { action, extendObservable } from 'mobx';
import qs from 'qs';
import API from '../config/api';
import { API_ROUTES } from '../config/api_routes';
import Collections from '../helpers/Collections';

const initialState = {
  isLoading: false,
  collections: [],
  pageCount: 0,
  totalEntries: 0,
  order: "",
  q: "",
  genre: "",
  error: null,
  page: 1
};
const perPage = 31

export class collectionsStore {
  constructor() {
    extendObservable(this, initialState);
  }

  @action reset = () => {
    Object.keys(initialState).forEach((key) => {
      this[key] = initialState[key];
    });
  };

  @action fetch = async (page = 1, _ = false) => {
    this.isLoading = true;
    try {
      const params = qs.stringify({ page: page, per_page: perPage, order: this.order, q: this.q, genre: this.genre });
      const { data } = await API.get(API_ROUTES.COLLECTIONS + '?' + params);
      Collections.normalize(data.collections, this);
      this.pageCount = data.pages_count;
      this.totalEntries = data.total_entries;
      this.page = page
    } catch (e) {
      console.error(e);
    } finally {
      this.isLoading = false;
    }
  };
}

export default new collectionsStore();

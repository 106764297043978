import API from '../config/api';
import { API_ROUTES } from '../config/api_routes';

const forIds = (ids) => {
  const errorMock = { data: { waveforms: [] } };

  return API.post(API_ROUTES.WAVEFORMS, {ids: ids}).catch(e => { return errorMock });
}

const merge = (product, waveform) => {
  if (waveform) {
    return {...product, waveform_data: waveform.waveform_data};
  } else {
    return product;
  }
}

const WaveformHelper = { forIds, merge };
export default WaveformHelper;

import { action, extendObservable } from 'mobx';
import API from '../config/api';
import { API_ROUTES } from '../config/api_routes';
import qs from 'qs';
import Collections from '../helpers/Collections';
import myCollectionsMenuStore from './myCollectionsMenuStore';

const initialState = {
  isLoading: false,
  collections: [],
  pagesCount: 0,
  totalEntries: 0,
  page: 1,
  order: "",
  q: "",
  genre: "",
  error: null,
  perPage: 30,
  loaded: false,
};

export class myCollectionsStore {
  constructor() {
    extendObservable(this, initialState);
  }

  @action reset = () => {
    Object.keys(initialState).forEach((key) => {
      this[key] = initialState[key];
    });
  };

  @action fetch = async (useFilters = false) => {
    this.isLoading = true;

    var params, route;
    if (useFilters) {
      params = qs.stringify({owned: "true", page: this.page, per_page: this.perPage, order: this.order, q: this.q, genre: this.genre});
      route = API_ROUTES.COLLECTIONS + '?' + params
    } else {
      params = qs.stringify({ page: this.page, per_page: this.perPage });
      route = API_ROUTES.MY_COLLECTIONS + '?' + params
    }
    try {
      const { data } = await API.get(route);
      Collections.normalize(data.collections, this);
      this.totalEntries = data.total_count;;
      this.pagesCount = data.pages_count;
    } catch (e) {
      console.error(e);
    } finally {
      this.loaded = true
      this.isLoading = false;
    }
  };

  changePosition = async (collectionId, pos, fetchAfter = false) => {
    var tags = []
    const url = `${API_ROUTES.MY_COLLECTIONS}/${collectionId}.json`;
    try {
      const { data } = await API.get(url);
      tags = data.tags;
    } catch (e) {
      console.log(e);
    }
    try {
      const params = qs.stringify({ "collection": { "position": pos, "tags": tags.map((t) => t.uuid) } }, { arrayFormat: 'brackets' });
      const { data } = await API.put(url, params);
      if (fetchAfter) {
        this.collections.replace([]);
        this.fetch();
      }
      myCollectionsMenuStore.fetch();
    } catch (e) {
      console.log(e);
    }
  };

  @action itemUp = (id) => {
    const index = this.collections.findIndex((collection) => collection.id === id);
    if (index === 0 && this.page === 1) return;

    var pos = this.collections[index].position - 1;
    this.collections[index].position = pos;

    if (index > 0) {
      this.decPosition(index)
      const newColls = this.collections.slice(0);
      const temp = newColls[index-1];
      newColls[index-1] = newColls[index];
      newColls[index] = temp;
      this.collections.replace(newColls);
    }

    var reload = ((index === 0) && (this.page > 1));
    this.changePosition(id, pos, reload);
  }

  @action itemDown = (id) => {
    const index = this.collections.findIndex((collection) => collection.id === id);
    if (index === -1) return;
    if ((index === this.collections.length - 1) && (this.page === this.pagesCount)) return;

    var pos = this.collections[index].position + 1;
    this.collections[index].position = pos;

    if (index < this.collections.length - 1) {
      this.incPosition(index)
      const newColls = this.collections.slice(0);
      const temp = newColls[index+1];
      newColls[index+1] = newColls[index];
      newColls[index] = temp;
      this.collections.replace(newColls);
    }

    var reload = (index === this.collections.length - 1);
    this.changePosition(id, pos, reload);
  }

  decPosition = (index) => {
    var tmpPos = this.collections[index].position
    this.collections[index-1].position = tmpPos + 1;
  }

  incPosition = (index) => {
    var tmpPos = this.collections[index].position
    this.collections[index+1].position = tmpPos - 1;
  }
}

export default new myCollectionsStore();

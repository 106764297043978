import menuStore from '../stores/menuStore'
import qs from 'qs';

let tagsInfo
let specialTags

const searchParams = () => {
  return new URL(document.location.href).searchParams;
}

const generateQuery = async (params) => {
  console.debug('#generateQuery, params: ', params)

  await initTagsInfo()

  let groups = {}
  params.tags.forEach(uuid => addToGroup(groups, uuid))
  params.and_tags.forEach(uuid => addToGroup(groups, uuid))

  let q = []
  if (params.partial_query) q.push(params.partial_query)
  if (params.product_id) q.push(`product:${params.product_id}`)
  if (params.collection && !params.bpm.min) q.push(`collection:${params.collection}`)
  if (params.favs) q.push('favourites:true')
  if (params.favweb) q.push('favweb:true')
  Object.keys(groups).forEach(k => q.push(qGroup(groups[k], (k === 'key') ? 'AND' : 'OR')))
  if (params.wave) {
    const { wav, lcdk, lcpk } = specialTags;
    if (params.collection) {
      q.push(`(tag:${wav.uuid})`);
    } else {
      q.push(`(tag:${wav.uuid} OR tag:${lcdk.uuid} OR tag:${lcpk.uuid})`);
    }
  }
  if (params.drumOrPlay && !params.wave) {
    const { lcdk, lcpk } = specialTags;
    q.push(`(tag:${lcdk.uuid} OR tag:${lcpk.uuid})`);
  }
  if (params.loop) q.push(`tag:${specialTags.loops.uuid}`)
  if (params.oneshot) q.push(`tag:${specialTags.oneshots.uuid}`)
  if (params.bpm.min) q.push(`bpm:${params.bpm.min}-${params.bpm.max}`)
  if (params.user) q.push(`library:${params.shop ? 'both' : 'true'}`)

  q = q.join(' AND ')
  console.debug("#generateQuery, generated: ", q)

  var urlParams = { q, ext: 'mp3', page: params.page || 1 };
  if (params.randomize) { urlParams.seed = params.seed };
  var cToken = searchParams().get('token');
  if (cToken) { urlParams.ct = cToken }
  if (params.sort.filename) { urlParams.sort = { filename: params.sort.filename } }
  return qs.stringify(urlParams)
}

const qGroup = (uuids, separator = 'OR') => {
  const part = uuids.map(uuid => `tag:${uuid}`).join(' '+separator+' ')
  return uuids.length > 1 ? `(${part})` : part
}

const addToGroup = (groups, uuid) => {
  if (!uuid || !tagsInfo) return;

  const { name, kind } = tagsInfo[uuid]
  if (!kind) return;

  if (!groups[kind]) groups[kind] = [];
  groups[kind] = groups[kind].concat(uuid)
}


const initTagsInfo = async () => {
  if (!menuStore.all.length) {
    await menuStore.fetchMenu()
  }

  if (tagsInfo || menuStore.all.size == 0) return false;

  tagsInfo = {}
  specialTags = {}

  menuStore.all.forEach(item => {
    tagsInfo[item.uuid] = item

    if (item.uuid === "4eb5d41e-dcc0-438c-ba7c-159d5916cbdf") specialTags['loops'] = item;
    if (item.uuid === "19cd5f78-5555-4de3-913d-269a02abb1cd") specialTags['oneshots'] = item;
    if (item.uuid === "21fc4739-4222-42c9-91b6-6a27603a4fc7") specialTags['wav'] = item;
    if (item.uuid === "29c7da8a-419a-43bb-8b17-93be34e33261") specialTags['lcdk'] = item;
    if (item.uuid === "12a0ab04-e673-4499-8844-1789db99e9eb") specialTags['lcpk'] = item;
  })
}

export default generateQuery

import React from 'react'
import { Link } from 'react-router-dom'
import { ReactComponent as BottomRightArrow } from '#images/bottom-right-arrow.svg'

export default function DesktopLink({ href, text, anchorTag = 'a' }) {
  const arrow = <BottomRightArrow className='ml-1 text-gray-5 group-hover:text-orange-1' />
  return (
    <li className='p-2.5 hidden 2xl:block text-lg'>
      {
        anchorTag === 'a' && (
          <a href={href} className='cursor-pointer group flex items-baseline'>
            {text}
            {arrow}
          </a>
        )
      }
      {
        anchorTag === 'Link' && (
          <Link to={href} className='cursor-pointer group flex items-baseline'>
            {text}
            {arrow}
          </Link>
        )
      }
    </li>
  )
}

import React, { Component } from 'react';
import filtersStore from '../../stores/filtersStore';
import history from '../common/history.js';

class LabelTile extends Component {
  redirectToLabel = (e) => {
    const { label } = this.props
    e.preventDefault();
    let params = filtersStore.toParams();
    const labelId = label.seo_label_id || label.id
    history.push(`/label/${labelId}?${params}`);
  }

  render() {
    const { logo_grayscale, totalProducts, logo } = this.props.label
    return (
      <div
        onClick={this.redirectToLabel}
        className='flex flex-col items-center justify-between bg-gray-15 min-w-[230px] min-h-[120px] max-h-[120px] pt-6 pb-5 cursor-pointer px-2'
      >
        <div>
          <img src={logo_grayscale || logo || 'blank.gif'} />
        </div>
        <p className='text-center'>
          <span className='text-gray-3'>Packs </span>
          <span className='font-bold'>{totalProducts}</span>
        </p>
      </div>
    )
  }
}

export default LabelTile

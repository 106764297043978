import React, { Component } from 'react'

class Modal extends Component {
  render() {
    if (this.title === null && this.content === null) return (<></>)

    return (
      <div className='fixed inset-0'>
        <div className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 bg-gray-12 rounded-md p-5 flex flex-col min-w-[340px] max-w-[340px]'>
          {
            this.title && (
              <h3 className='font-bold text-[28px] pb-5 border-b border-gray-6 tracking-tighter'>{this.title}</h3>
            )
          }
          <div className='pt-5 tracking-tight'>
            { this.content }
          </div>
          <div className={`flex justify-between pt-5`}>
            { this.buttons }
          </div>
        </div>
      </div>
    )
  }
}

export default Modal

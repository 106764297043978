import { action, extendObservable, reaction } from 'mobx';
import API from '../config/api';
import { API_ROUTES } from '../config/api_routes';
import { APIFunctions } from '../config/api';
import qs from 'qs';
import authStore from './authStore'

const initialState = {
  isLoading: false,
  points: 0,
  subscription_level: null,
  email: null,
  purchased_item: false,
  cloudApp: true, // hide desktop app notification by default, until data about whether user opened app is loaded
  free_items_allowed_today: null,
  activeSubscription: null,
};

export class infoStore{
  constructor() {
    extendObservable(this, initialState);
    this.update({ initial: true })
    reaction(() => authStore.isLoggedIn, (isLoggedIn) => {
      this.update({ initial: true })
    })
  }

  freeLimitExceeded (n = 1) {
    return (this.free_items_allowed_today - n < 0);
  }

  @action reset = () => {
    this.email = null;
    this.points = 0;
    this.purchased_item = false;
    this.free_items_allowed_today = null;
  };

  @action update = async (params = {}) => {
    if (!(params.product_id || params.initial)) return
    if(!authStore.isLoggedIn) {
      this.cloudApp = false;
      return this;
    }

    this.isLoading = true;
    try {
      const { data } = await API.get(API_ROUTES.INFO+'?'+qs.stringify(params));

      this.points = data.points;
      this.subscription_level = data.subscription_level;
      if (params['product_id']) {
        this.purchased_item = data.purchased_item;
      }
      this.email = data.email;
      this.free_items_allowed_today = data.free_items_allowed_today;

    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false
    }

    try {
      var loopApi = APIFunctions.createLoopAPI();
      const { data } = await loopApi.get(API_ROUTES.CLOUD_USER)
      console.log(data)
      this.cloudApp = data.cloud_app
      this.activeSubscription = data.active_subscription
    } catch (e) {
      console.log(e);
    }
  };
}

export default new infoStore();

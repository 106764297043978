import { action, extendObservable } from 'mobx';
import API from '../config/api';
import { APIFunctions } from '../config/api';
import { API_ROUTES } from '../config/api_routes';
import WaveformHelper from './../helpers/WaveformHelper';
import qs from 'qs';

const initialState = {
  isLoading: false,
  name: '',
  page: 1,
  pages_count: 0,
  view: 'tile',
  ids: [],
  packs: []
};

const itemHeight = 256;
const itemHeightRow = 72;

export class libraryPacksStore{
  constructor() {
    extendObservable(this, initialState);
  }

  @action reset = () => {
    Object.keys(initialState).forEach((key) => {
      this[key] = initialState[key];
    });
  };

  @action toggleView = () => {
    this.view = (this.view === 'tile') ? 'list' : 'tile';
  }

  isTiles() {
    return this.view === 'tile'
  }
  isList() {
    return this.view === 'list'
  }

  @action fetch = async (params = {}) => {
    this.isLoading = true;
    var api = APIFunctions.createLoopAPI();

    try {
      var cloud = await API.get(API_ROUTES.LIBRARY_PACKS+`?page=${this.page || 1}`);
      this.ids = cloud.data.packs.map((prod) => prod.id).filter((pid) => pid != -1);
      this.pages_count = cloud.data.pages_count
      var loop = await api.post(API_ROUTES.PACKS, { ids: this.ids });
      const { waveforms } = (await WaveformHelper.forIds(this.ids)).data;

      var products = cloud.data.packs.map((p) => {
        var loopItem = loop.data.products.find((prod) => prod.id === p.id);
        if(loopItem && p.id != -1) {

          const waveform = (waveforms || []).find(waveform => p.id == waveform.product_id);
          return WaveformHelper.merge({...loopItem, ...p, cover: loopItem.cover_google_merchant}, waveform);
        } else {
          return null;
        }
      });
      products = products.filter((product) => product);
      this.packs.replace(products);
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }
    return this.packs;
  };

}

export default new libraryPacksStore();

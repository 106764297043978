import React, { Component, createRef } from 'react'
import { inject, observer } from 'mobx-react'
import Multiselect from 'multiselect-react-dropdown'
import layerStore from '../../stores/layerStore'
import myCollectionsStore from '../../stores/myCollectionsStore'
import history from '../common/history'
import CoverUploader from './CollectionModal/CoverUploader'
import DropdownIcon from './../../images/dropdown-icon.svg'

const Backdrop = props => (
  <div
    className='fixed inset-y-0 inset-x-0 flex justify-center items-center bg-[#000000B0] px-2.5 z-50 py-4'
  >
    {props.children}
  </div>
)

const Modal = props => (
  <div
    className={`flex flex-col sm:flex-row p-6 w-full max-h-full overflow-y-auto ${props.actionPerformed ? 'bg-gray-12 min-w-[340px] max-w-[340px] rounded-md' : 'bg-gray-15 md:min-w-[700px] md:max-w-[700px]'}`}
  >
    {props.children}
  </div>
)

const AfterActionMessage = ({ created, resetStore }) => (
  <div className='flex flex-col mt-5 text-center'>
    <p className='font-bold'>{ created ? 'Created' : 'Updated' }</p>
    <div>
      <button
        type='button'
        className='mt-5 px-5 rounded-[40px] bg-orange-1 text-black font-medium py-1 w-full text-[22px]'
        onClick={resetStore}
      >
        OK
      </button>
    </div>
  </div>
)

@inject('collectionStore', 'menuStore', 'modalStore')
@observer
class CollectionModal extends Component {
  constructor(props) {
    super(props)
    this.state = { error: '', processing: false }
    this.acl = 'private'
    this.formRef = createRef()
    this.multiselectRef = createRef()
  }

  get title() {
    const { editMode, updated } = this.props.collectionStore
    if (updated) return 'Collection'

    return editMode ? 'Edit Collection' : 'New Collection'
  }

  get options() {
    return this.props.menuStore.getGenres().map((genre) => {
      return { name: genre.name, uuid: genre.uuid }
    })
  }

  get typeOptions() {
    return ['private', 'public'].map((kind) => (
      <option key={kind} className='capitalize'>{kind}</option>
    ))
  }

  get collection() {
    return new FormData(this.formRef.current)
  }

  key = (k) => {
    const { collectionStore } = this.props
    if (!collectionStore.editMode) return null

    return collectionStore[k]
  }

  resetStore = () => {
    this.props.collectionStore.resetStore()
  }

  onAclSelect(event) {
    this.acl = event.target.value
  }

  onSelect = (selectedList) => {
    this.multiselectRef.current?.searchBox?.current?.blur()
    this.props.collectionStore.modalTags = selectedList
    document.getElementById('genre_select_input').setAttribute('placeholder', '')
    setTimeout(() => {
      this.multiselectRef.current?.searchBox?.current?.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }, 1)
  }

  onRemove = (selectedList) => {
    this.props.collectionStore.modalTags = selectedList
    if ((this.key('modalTags') ?? []).length === 0) {
      document.getElementById('genre_select_input').setAttribute('placeholder', 'Start typing to add tags')
    }
  }

  deleteCollection = (e) => {
    e.preventDefault()
    const { modalStore, collectionStore } = this.props

    layerStore.deleteCollectionId = collectionStore.id
    modalStore.show('deleteCollection')
  }

  cancel = () => {
    const { modalStore, collectionStore } = this.props
    this.setState({ error: '' })
    collectionStore.created = false
    collectionStore.updated = false
    modalStore.hide()
  }

  submit = async (e) => {
    e.preventDefault()

    this.setState({ error: '', processing: true })
    const { collectionStore } = this.props
    const oldSeoId = collectionStore.seo_id

    let result
    if (collectionStore.editMode) {
      result = await collectionStore.update(this.collection, this.acl)
    } else {
      result = await collectionStore.create(this.collection, this.acl)
    }

    this.setState({ processing: false })
    if (result.error?.message) {
      this.setState({ error: result.error.message })
    } else if (result.data.seo_id !== oldSeoId) {
      const searchParams = new URL(document.location.href).search
      history.push(`/collection/${result.data.seo_id}${searchParams}`)
      myCollectionsStore.fetch()
    }
  }

  multiselectClasses = () => [
    '[&_.search-wrapper]:border-none',
    '[&_.search-wrapper]:p-2.5',
    '[&_.search-wrapper]:mt-2.5',
    '[&_.search-wrapper]:bg-gray-12',
    '[&_.search-wrapper]:max-h-[88px]',
    '[&_.search-wrapper]:overflow-y-auto',
    '[&_.searchBox]:w-full',
    '[&_.searchBox]:bg-gray-12',
    '[&_.searchBox]:border-none',
    '[&_.searchBox]:placeholder:text-gray-7',
    this.props.collectionStore.modalTags.length > 0 ? '[&_.searchBox]:mt-2.5' : '[&_.searchBox]:mt-0',
    '[&_.optionListContainer]:bg-gray-12',
    '[&_.optionContainer]:border',
    '[&_.optionContainer]:border-gray-9',
    '[&_.chip]:bg-gray-8',
    '[&_.chip]:rounded-sm',
    '[&_ul_li:hover]:bg-gray-10',
    '[&_.highlightOption]:bg-gray-10',
  ].join(' ')

  render() {
    const { created, updated, name, editMode } = this.props.collectionStore
    const acl = this.key('acl') === 'public' ? 'public' : 'private'
    const tagPlaceholder = (this.key('modalTags') ?? []).length !== 0 ? '' : 'Start typing to add tags'
    this.acl = this.key('acl') === 'curated' ? null : (this.key('acl') || 'private')

    return (
      <Backdrop>
        <Modal actionPerformed={updated || created}>
          <form ref={this.formRef} className={`flex flex-wrap w-full ${updated || created ? 'justify-center' : ''}`}>
            <p
              className={`text-[19px] font-bold mb-2.5 sm:hidden order-2 w-full ${updated || created ? 'text-center' : ''}`}
            >
              {this.title}
            </p>
            { !updated && !created && <CoverUploader /> }
            <div className='flex flex-col sm:ml-5 md:max-w-[425px] grow order-3'>
              <p
                className={`text-[19px] font-bold hidden sm:block leading-none ${updated || created ? 'text-center' : ''}`}
              >
                {this.title}
              </p>

              {
                updated && <p className='text-center mt-2.5 font-bold'>{name}</p>
              }

              {
                updated || created ? (
                  <AfterActionMessage created={created} resetStore={this.resetStore} />
                ) : (
                  <>
                  <div className='flex flex-col grow'>
                    {
                      this.state.error && (
                        <p className='mt-2.5 text-red-1'>{this.state.error}</p>
                      )
                    }

                    <div className='flex flex-col mt-2.5'>
                      <label htmlFor='name'>Name</label>
                      <input
                        required={true}
                        placeholder='My Playlist'
                        id='name'
                        name='collection[name]'
                        defaultValue={this.key('name')}
                        className='p-2.5 mt-2.5 bg-gray-12 placeholder:text-gray-7 outline-0 outline-none'
                      />
                    </div>

                    {
                      this.acl && (
                        <div className='flex flex-col mt-2.5'>
                          <label htmlFor='type' className='pb-2.5'>Type</label>
                          <div className='relative w-full'>
                            <div
                              style={{"--image-url": `url(${DropdownIcon})`}}
                              className='after:h-6 after:w-6 after:[content:var(--image-url)] after:block after:absolute after:top-1/2 after:right-0 after:-translate-y-1/2 after:pointer-events-none'
                            >
                              <select
                                id='type'
                                onChange={this.onAclSelect.bind(this)}
                                defaultValue={acl}
                                className='p-2.5 bg-gray-12 capitalize outline-0 w-full outline-none appearance-none [-webkit-appearance:none] [-moz-appearance:none]'
                              >
                                { this.typeOptions }
                              </select>
                            </div>
                          </div>
                        </div>
                      )
                    }

                    <div className='flex flex-col mt-2.5'>
                      <label htmlFor='genre_select'>Genre</label>
                      <Multiselect
                        id='genre_select'
                        closeIcon='cancel'
                        displayValue='name'
                        options={this.options}
                        placeholder={tagPlaceholder}
                        selectedValues={this.key('modalTags')}
                        onSelect={this.onSelect}
                        onRemove={this.onRemove}
                        className={this.multiselectClasses()}
                        ref={this.multiselectRef}
                      />
                    </div>

                    <div className='flex flex-col mt-2.5'>
                      <label htmlFor='description'>Description</label>
                      <textarea
                        placeholder='Description (optional)'
                        id='description'
                        name='collection[description]'
                        defaultValue={this.key('description')}
                        className='outline-none bg-gray-12 p-2.5 mt-2.5 placeholder:text-gray-7 min-h-[4rem] lg:min-h-[8rem]'
                      />
                    </div>
                  </div>

                  <div className='flex mt-5'>
                    <button
                      onClick={this.cancel}
                      className='ml-auto px-5 border rounded-[40px] border-2 lg:text-lg font-medium py-0.5'
                    >
                      Cancel
                    </button>

                    {
                      editMode && (
                        <button
                          onClick={this.deleteCollection}
                          className='ml-2.5 px-5 rounded-[40px] bg-orange-1 text-black lg:text-lg font-medium py-0.5'
                        >
                          <p className='sm:hidden'>Delete</p>
                          <p className='hidden sm:block'>Delete collection</p>
                        </button>
                      )
                    }

                    <button
                      disabled={this.state.processing}
                      type='submit'
                      onClick={this.submit}
                      className='ml-2.5 px-5 rounded-[40px] bg-orange-1 text-black lg:text-lg font-medium py-0.5'
                    >
                      {editMode ? 'Update' : 'Create'}
                    </button>
                  </div>
                  </>
                )
              }
            </div>
          </form>
        </Modal>
      </Backdrop>
    )
  }
}

export default CollectionModal

import React from 'react'
import { inject, observer } from 'mobx-react'
import confirmationStore from '../../stores/confirmationStore'
import infoStore from '../../stores/infoStore'
import Modal from '../common/Modal'
import Redirect from '../../helpers/Redirect'
import history from '../common/history.js'
import PointIcon from '#images/point_icon.svg'

const Button = ({ onClick, className, type, children }) => (
  <button
    onClick={onClick}
    className={`${type === 'orange' ? 'bg-orange-1 text-gray-16 text-[22px] font-medium w-full rounded-[40px] py-1' : 'px-9 border border-2 border-white rounded-[40px] py-0.5 font-medium text-[22px]'} tracking-tight ${className}`}
    type='button'
  >
    { children }
  </button>
)

@inject('buyPackItemStore', 'modalStore', 'packStore')
@observer
class BuyPackItemModal extends Modal {
  get notEnoughPoints() {
    return this.props.packStore.pointsLeft > infoStore.points
  }

  get content() {
    const { packStore } = this.props
    const { buyPackItemStore } = this.props
    return <div className='font-bold'>
      {
        !packStore.isLoading && buyPackItemStore.freePack && <>
            <p>Subscribe to receive this pack.</p>
        </>
      }
      {
        !packStore.isLoading && !buyPackItemStore.packOnlyItem && !infoStore.activeSubscription && <>
          <p>Subscribe to purchase this item with points, or purchase the whole pack with cash.</p>
        </>
      }
      {
        !packStore.isLoading && buyPackItemStore.packOnlyItem && infoStore.activeSubscription && !this.notEnoughPoints && <>
          <p>This item is available only as the whole pack</p>
        </>
      }
      {
        !packStore.isLoading && buyPackItemStore.packOnlyItem && !infoStore.activeSubscription && !buyPackItemStore.freePack &&
          <p>
            This item is available only as the whole pack, subscribe to purchase pack with points, or purchase pack with cash.
          </p>
      }
      {
        !packStore.isLoading && infoStore.activeSubscription && this.notEnoughPoints && <>
          <p>Not enough points.</p>
          <p>Top up your account or buy the pack with cash.</p>
        </>
      }
      { packStore.isLoading && <p>Loading pack data...</p> }
    </div>
  }

  get buttons() {
    const { packStore } = this.props
    const { buyPackItemStore } = this.props
    return (
      <div className='flex flex-col w-full gap-y-4'>
        {
          !packStore.isLoading && !infoStore.activeSubscription &&
            <Button onClick={this.subscribe} type='orange'>
              Subscribe
            </Button>
        }
        {
          !packStore.isLoading && !infoStore.activeSubscription && !buyPackItemStore.freePack &&
            <Button onClick={this.buy} type='white'>
              Buy pack for £{packStore.price}
            </Button>
        }
        {
          !packStore.isLoading && !packStore.isPackInfoLoading && infoStore.activeSubscription && !this.notEnoughPoints &&
            <Button
              onClick={this.buyForPoints}
              className='flex items-center justify-center'
              type='orange'
            >
              Buy pack for {packStore.pointsLeft}
              <img src={PointIcon} className='ml-1.5' />
            </Button>
        }
        {
          !packStore.isLoading && infoStore.activeSubscription && this.notEnoughPoints && <>
            <Button
              onClick={this.topUp}
              className='flex items-center justify-center'
              type='orange'
            >
              Top up your account
              <img src={PointIcon} className='ml-1.5' />
            </Button>
            <Button
              onClick={this.buy}
              type='white'
            >
              Buy pack for £{packStore.price}
            </Button>
          </>
        }
        {
          !packStore.isLoading && buyPackItemStore.searchPage &&
            <Button
              onClick={this.redirectToProduct}
              type='white'
            >
              Visit the whole pack
            </Button>
        }
        <Button onClick={this.close} type='white'>Cancel</Button>
      </div>
    )
  }

  buy = () => {
    Redirect.toProduct(this.props.packStore)
  }

  subscribe = () => {
    Redirect.toPlan()
  }

  redirectToProduct = () => {
    history.push(`/product/${this.props.packStore.seo_product_id}`)
    this.close()
  }

  buyForPoints = () => {
    const { packStore } = this.props
    this.close()
    confirmationStore.points = packStore.pointsLeft;
    confirmationStore.files.replace(['Pack: ' + packStore.title])
    confirmationStore.pack = true
    confirmationStore.collection = false
    confirmationStore.buy(null, packStore.id);
  }

  topUp = () => {
    Redirect.toBuyPoints()
  }

  close = () => {
    this.props.modalStore.hide()
  }
}

export default BuyPackItemModal

import API from '../config/api';

const updateItem = (url, index, store) => {
  API.get(url).then((res) => {
    store.collections[index] = {...store.collections[index], image_url: res.data, huge_image_url: res.data }
  });
}

const normalize = (collections, store) => {
  collections.forEach((collection, index) => {
    if (collection.acl === "private" && collection.image_url) {
      updateItem(collection.image_url, index, store);
      collection['image_url'] = null;
      collection['huge_image_url'] = null;
      collections[index] = collection;
    }
  });
  store.collections = collections;
}

const Collections = { normalize, updateItem }
export default Collections

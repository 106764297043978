import React, { Component } from 'react'
import Loader from '#images/loader.gif'

class LoadingModal extends Component {
  render() {
    return (
      <div
        className='fixed inset-y-0 inset-x-0 flex justify-center items-center bg-[#000000B0] px-2.5 z-50'
      >
        <img src={Loader} className='max-w-[400px]' />
      </div>
    )
  }
}

export default LoadingModal

import { action, extendObservable } from 'mobx'
import CollectionModal from '#components/collections/CollectionModal'
import DeleteCollectionModal from '#components/collections/DeleteCollectionModal'
import LoadingModal from '#components/products/LoadingModal'
import NotEnoughPointsModal from '#components/products/NotEnoughPointsModal'
import BuyPackItemModal from '#components/modals/BuyPackItemModal'
import BuyFreeItemsExceededModal from '#components/modals/BuyFreeItemsExceededModal'
import BackendErrorModal from '#components/modals/BackendErrorModal'
import ConfirmationModal from '#components/products/ConfirmationModal'
import CloudItemMenu from '#components/menu/CloudItemMenu'
import FreeLimitExceededModal from '#components/modals/FreeLimitExceededModal'
import ProcessingModal from '#components/products/ProcessingModal'
import SuccessModal from '#components/products/SuccessModal'

const initialState = {
  visibleModal: null,
  props: {}
}

const MODALS = {
  'collection': CollectionModal,
  'deleteCollection': DeleteCollectionModal,
  'loading': LoadingModal,
  'notEnoughPoints': NotEnoughPointsModal,
  'buyPackItem': BuyPackItemModal,
  'buyFreeItemsExceeded': BuyFreeItemsExceededModal,
  'backendError': BackendErrorModal,
  'confirmation': ConfirmationModal,
  'cloudItemMenu': CloudItemMenu,
  'freeLimitExceeded': FreeLimitExceededModal,
  'processing': ProcessingModal,
  'success': SuccessModal,
}

export class modalStore {
  constructor() {
    extendObservable(this, initialState)
  }

  @action show(modal, props = {}) {
    this.visibleModal = modal
    this.props = props
  }

  @action hide() {
    this.visibleModal = null
    this.props = {}
  }
}

export default new modalStore()
export { MODALS }
import React, { Component } from 'react'
import { inject, observer } from 'mobx-react';

@inject('filtersStore', 'menuStore')
@observer
export default class ScaleFilterButton extends Component {
  filterColor() {
    const { type, menuStore, filtersStore } = this.props
    const uuid = menuStore.tags[type];
    const found = filtersStore.and_tags.find((tag) => { return tag === uuid; });
    return found ? 'text-orange-1 border-orange-1' : 'text-white'
  }

  changeFilters() {
    return () => {
      const { type, filtersStore, menuStore } = this.props

      const uuid = menuStore.tags[type]
      filtersStore.changeAndTags(uuid)
      const opposite = (type == 'Minor') ? 'Major' : 'Minor'
      const uOpposite = menuStore.tags[opposite]
      filtersStore.and_tags.remove(uOpposite)
      filtersStore.page = 1

      this.props.redirect()
      window.scrollTo(0,0)
    }
  }

  render() {
    return (
      <button
        type='button'
        className={`px-3 border rounded-3xl font-medium text-[13px] ${this.filterColor()}`}
        onClick={this.changeFilters()}
      >
        {this.props.type}
      </button>
    )
  }
}

import { action, extendObservable } from 'mobx';
import qs from 'qs';
import API from '../config/api';
import { API_ROUTES } from '../config/api_routes';
import cloudItemsStore from './cloudItemsStore';
import packStore from './packStore';
import packsStore from './packsStore';
import backendErrorStore from './backendErrorStore';
import modalStore from './modalStore';

const initialState = {
  items: [],
  currentlyPlays: null,
  currentAudio: null,
  isPlaying: false,
  rememberedVolume: 50,
  rewindMode: false,
  isLoading: false,
  traversingMode: 'playlist', // or 'itemlist',
  traversingIndex: null, // index of selected item on list - itemlist or playlist
  synced: false
};

export class playlistStore {
  constructor() {
    extendObservable(this, initialState);
    this.sync();
  }

  get points() {
    if (this.items.length) {
      var points = 0
      this.items.filter((item) => !item.owned).forEach(function(i) {
        points += i.points;
      })
      return points
    } else if (this.currentlyPlays) {
      return this.currentlyPlays.points
    } else {
      return 0
    }
  }

  updateCurrentlyPlaysIfBought(boughtItems) {
    if (this.currentlyPlays) {
      const boughtCurrentlyPlays = boughtItems.find((item) => item.id === this.currentlyPlays.id);
      if (!boughtCurrentlyPlays) return false;
      this.currentlyPlays = {...this.currentlyPlays, ...boughtCurrentlyPlays };
      this.currentAudio = this.currentlyPlays.audio
    }
  }

  updateCurrentlyPlaysIfProductBought(productId) {
    if (this.currentlyPlays && this.currentlyPlays.product_id == productId) {
      this.setCurrentlyPlays(null);
    }
  }

  isActive(itemId) {
    return this.traversingMode == 'itemlist' && this.currentlyPlays && this.currentlyPlays.id == itemId;
  }

  @action playDemo = (product) => {
    console.debug("playlistStore#playDemo, product:", product)

    this.currentlyPlays = {
      cover: product.cover_small || product.cover,
      demo_for: product.id,
      product_id: product.id, // redundancy to keep interface similar between demo and other plays
      points: null,
      encodedData: product.waveform_data,
      name: `${product.title} by ${product.label_name}`
    }

    let audio = product.demo_song_url
    if (audio[0] == '/') audio = `https://w1.loopcloud.com${audio}`
    this.currentAudio = audio

    // Trigger audio reload
    this.isPlaying = false;
    this.isPlaying = true;
  }

  @action play = (item = null, renderCover = true) => {
    if (item) {
      this.setCurrentlyPlays(item, renderCover)
    }

    this.ensureAudio()

    // Trigger audio reload
    this.isPlaying = false;
    this.isPlaying = true;
  };

  @action fetchAudio = async (item) => {
    this.isLoading = true;
    if (!item) { return }
    try {
      let path = `${API_ROUTES.AUDIO}${item.uuid}?ext=mp3`
      const { data } = await API.get(path);

      if (data.error) {
        this.setCurrentlyPlays(null)
        console.error("got: ", data.error)
        // guest limit modal
        if (data.error == 'Guest limit exceeded') {
          modalStore.show('freeLimitExceeded')
        } else if (data.error == 'No file') {
          backendErrorStore.showModal('This product is currenlty unavailable')
        } else {
          alert(data.error)
        }
        this.isPlaying = false

      } else {
        console.debug("Setting current audio to:", data.audio[item.uuid])
        this.currentAudio = data.audio[item.uuid]
      }

    } catch (e) {
      console.error(e);

    } finally {
      this.isLoading = false;
    }
  }

  @action pause = () => {
    this.isPlaying = false;
  }

  @action add = (item) => {
    var itemIndex = this.items.findIndex((i) => { return (i.id === item.id); });
    // Do not allow to add the same item twice
    if(itemIndex == -1) {
      item.cover = this.cover(item)
      var playlist = JSON.parse(localStorage.getItem('playlist') || '[]');
      if(playlist.length) {
        localStorage.setItem('playlist', JSON.stringify(playlist.concat([item])));
      } else {
        localStorage.setItem('playlist', JSON.stringify([item]));
      }
      this.items.push({...item});
    }
    // if(this.isPlaying) {
    if (item.hasAudio) {
      this.play(item)
    }
    // }
  };

  removeItems(array, toRemove) {
    var itemIndex;
    toRemove.forEach(item => {
      itemIndex = array.findIndex(i => i.id === item.id);
      if (itemIndex != -1) { array.splice(itemIndex, 1); }
    });
  }

  @action remove = (ids, options = {}) => {
    var filtered, playlist = JSON.parse(localStorage.getItem('playlist') || '[]');
    const filterClosure = i => {
      if (options.product_id) {
        return i.product_ids.includes(options.product_id);
      }
      return (ids.includes(i.id));
    }
    if (playlist.length) {
      filtered = playlist.filter(filterClosure);
      this.removeItems(playlist, filtered);
      localStorage.setItem('playlist', JSON.stringify(playlist));
    }

    filtered = this.items.filter(filterClosure);
    // assign to new variable to trigger changing playlist
    var newItems = this.items.slice(0);
    this.removeItems(newItems, filtered);
    this.items = newItems;
  }

  @action clear = () => {
    localStorage.setItem('playlist', '[]')
    this.items.replace([])
    // this.setCurrentlyPlays(null)
  }

  @action sync = () => {
    if(!this.synced) {
      this.synced = true
      var playlist = JSON.parse(localStorage.getItem('playlist') || '[]');
      if (playlist.length) {
        if (!this.items.length) this.items.replace(playlist);
        this.fetchCloudItems(playlist)
      }
    }
  };

  @action fetchCloudItems = async (playlist = []) => {
    this.isLoading = true;
    const ids = playlist.map(i => i.uuid)
    const stringify = qs.stringify({ids: ids, ext: 'mp3'}, { arrayFormat: 'brackets' });
    try {
      const path = `${API_ROUTES.AUDIO}?${stringify}`
      const { data } = await API.get(path);

      // Update audio link
      playlist = playlist.map(function(i) {
        i.audio = data.audio[i.uuid]
        return i
      })
      this.items.replace(playlist)
      if(this.items.length > 0 && this.items[this.items.length-1].hasAudio) {
        this.setCurrentlyPlays(this.items[this.items.length-1])
      }
      // packsStore.fetch({ ids: data.products });
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }
    return this.items;
  };

  @action fav = (item, val) => {
    var playlist = JSON.parse(localStorage.getItem('playlist') || '[]');
    if(playlist.length) {
      let itemIndex = playlist.findIndex((i) => { return (i.id === item.id); });
      if(itemIndex != -1) {
        playlist[itemIndex].fav = val;
        localStorage.setItem('playlist', JSON.stringify(playlist));
      }
    }
    var index = this.items.findIndex((i) => i.id === item.id);
    if(index > -1) {
      this.items[index] = {...this.items[index], fav: val};
    }
  }

  @action setCurrentlyPlays = (item, renderCover = true) => {
    if(renderCover && item && !item.cover) {
      item.cover = this.cover(item)
    }
    this.currentlyPlays = item ? {...item} : null;
    this.currentAudio = item ? item.audio : null
  }

  ensureAudio() {
    const item = this.currentlyPlays
    if (this.currentAudio || !item) return;

    // this.currentAudio = `${cloudUrl}${API_ROUTES.AUDIO}${item.uuid}.mp3`
    // console.debug("currentAudio redirect set to: ", this.currentAudio)

    this.fetchAudio(item);
  }

  cover(item) {
    if(packStore.id == item.product_id) {
      return packStore.cover
    } else {
      return packsStore.getCover(item.product_id)
    }
  }

  @action playPrevious = () => {
    this.playAnother(false)
  }
  @action playNext = () => {
    this.playAnother(true)
  }
  // next = true - play next
  // next = false - play previous
  @action playAnother = (next = true) => {
    let itemsList = []
    switch(this.traversingMode) {
      case 'playlist':
        itemsList = this.items
        break
     case 'itemlist':
        itemsList = cloudItemsStore.cloudItems
        break
    }
    if(!this.currentlyPlays || itemsList.length < 2) { return false }
    let itemIndex = itemsList.findIndex((i) => { return (this.currentlyPlays.id === i.id); });
    // set it in case it is first traversing since page load
    if(next) {
      itemIndex = itemIndex == itemsList.length - 1 ? 0 : itemIndex + 1
    } else {
      itemIndex = itemIndex == 0 ? itemsList.length - 1 : itemIndex - 1
    }
    this.traversingIndex = itemIndex
    this.setCurrentlyPlays(itemsList[itemIndex])

    this.ensureAudio()

    // Trigger audio reload
    this.isPlaying = false
    this.isPlaying = true
  }
}

export default new playlistStore();

import React, { Component } from 'react'
import { Link } from "react-router-dom"
import collectionIcon from '../../images/collection-icon.svg'

class HomeCollectionTile extends Component {
  get collectionUrl() {
    const { seo_id, id } = this.props.collection;
    return `/collection/${seo_id || id}`
  }

  render() {
    const { name, author, description, image_url } = this.props.collection
    return (
      <Link to={this.collectionUrl}>
        <div
          className='flex flex-col items-center bg-gray-15 hover:bg-gray-12 h-[365px] 2xl:h-[375px] px-5 text-center pb-2'
        >
          <div className='relative h-[220px]'>
            <img
              src={image_url}
              className='min-h-[220px] max-h-[220px] min-w-[220px] object-cover'
            />
          </div>

          <p className='mt-2 text-lg font-bold 2xl:text-[19px] line-clamp-1'>{name}</p>

          <div className='flex items-center mt-2.5 2xl:mt-1.5'>
            <img src={collectionIcon} className='max-w-[18px] max-h-[18px] mt-0.5' />
            <div className='ml-1.5 line-clamp-1 text-orange-1'>
              <span className='text-gray-3 leading-none'>by </span>
              <span className='font-bold break-all leading-none'>{author}</span>
            </div>
          </div>

          <p
            dangerouslySetInnerHTML={{__html: description}}
            className='mt-4 2xl:mt-3 max-w-[300px] text-gray-3 leading-5 line-clamp-2'
          >
          </p>
        </div>
      </Link>
    )
  }
}

export default HomeCollectionTile

import { action, extendObservable } from 'mobx'
import modalStore from './modalStore'
import BackendErrorModal from '#components/modals/BackendErrorModal'

const initialState = {
  buttons: null,
  text: null
};

export class backendErrorStore{
  constructor() {
    extendObservable(this, initialState);
  }

  @action showModal = (content, buttons = null) => {
    if (buttons) this.buttons = buttons
    this.text = content
    modalStore.show('backendError')
  };
}

export default new backendErrorStore();

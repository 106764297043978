import React, { forwardRef } from 'react'
import { ReactComponent as CheckboxChecked } from '#images/custom-checkbox-checked.svg'
import { ReactComponent as CheckboxUnchecked } from '#images/custom-checkbox-unchecked.svg'

export const CustomCheckbox = forwardRef(({
  ...props
}, ref) =>
  <div className={`${props.fillClass ? props.fillClass : '[&_path]:fill-gray-16'}`}>
    <input
      ref={ref}
      type='checkbox'
      className='absolute opacity-0 z-[1] w-full h-full overflow-hidden [&:checked_~_&]'
      checked={props.checked}
      onChange={props.onChange}
      readOnly={props.readOnly}
    />
    <CheckboxChecked
      width={props.width}
      height={props.height}
      className='hidden [input:checked_~_&]:block'
    />
    <CheckboxUnchecked
      width={props.width}
      height={props.height}
      className='[input:checked_~_&]:hidden'
    />
  </div>
)

export default CustomCheckbox
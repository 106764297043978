import { action, extendObservable } from 'mobx'
import modalStore from './modalStore'
import processingStore from './processingStore'

const initialState = {
  points: 0,
  files: [],
  pack: false,
  collection: false
};

export class confirmationStore{
  constructor() {
    extendObservable(this, initialState);
  }

  @action buy(uuid = null, productId = null) {
    processingStore.uuid = uuid;
    processingStore.productId = productId;
    modalStore.show('confirmation')
  }

  @action buyCollection(uuid) {
    processingStore.uuid = uuid;
    processingStore.collection = true;
    modalStore.show('confirmation')
  }

  @action cancel() {
    processingStore.uuid = null;
    processingStore.productId = null;
    processingStore.collection = false;
    modalStore.hide()
    // Do not clean it to show fade-out animation with content
    // this.points = 0;
    // this.files = [];
  }

  @action showProcessing() {
    modalStore.hide()
    this.points = 0;
    this.files = [];
    if(processingStore.collection) {
      processingStore.buy(true);
    } else if (processingStore.uuid) {
      processingStore.buy();
    } else if (processingStore.productId) {
      processingStore.buyProduct();
    }
  }
}

export default new confirmationStore();

import { action, extendObservable } from 'mobx'
import API from '../config/api'
import { API_ROUTES } from '../config/api_routes'
import myCollectionsStore from './myCollectionsStore'
import myCollectionsMenuStore from './myCollectionsMenuStore'
import filtersStore from './filtersStore'
import history from '../components/common/history'
import modalStore from './modalStore'

const initialState = {
  isLoading: false,
  id: null,
  seo_id: null,
  uuid: null,
  name: '',
  acl: 'private',
  author: null,
  description: '',
  image_url: null,
  modal_image_url: null,
  author_image_url: null,
  image: null,
  huge_image_url: null,
  user_image: null,
  updated_at: null,
  owned: false,
  points: null,
  favourited: false,
  tags: [],
  item_tags: [],
  modalTags: [],
  favourited_by_count: 0,
  created: false,
  updated: false,
  editMode: false
};

const specialTags =  [
  "4eb5d41e-dcc0-438c-ba7c-159d5916cbdf",
  "19cd5f78-5555-4de3-913d-269a02abb1cd",
  "21fc4739-4222-42c9-91b6-6a27603a4fc7",
  "bf8c4cd0-6b52-4d52-9c86-da5c28714e8d"
];

export class collectionStore{
  constructor() {
    extendObservable(this, initialState);
  }

  @action reset = () => {
    Object.keys(initialState).forEach((key) => {
      this[key] = initialState[key];
    });
  };

  @action resetStore = () => {
    this.updated = false;
    this.created = false;
    modalStore.hide()
  };

  get params() {
    return new URL(document.location.href).searchParams;
  }

  @action fetch = async (id, cleanUpTags = false) => {
    this.isLoading = true;
    let url = `${API_ROUTES.MY_COLLECTIONS}/${id}.json`;
    const token = this.params.get('token');
    if (token) url = url + "?token=" + token;
    try {
      const { data } = await API.get(url);
      this.id = data.id;
      this.seo_id = data.seo_id;
      this.uuid = data.uuid;
      this.name = data.name;
      this.author = data.author;
      this.acl = data.acl;
      this.description = data.description || '';
      this.user_image = data.user_image;
      this.updated_at = data.updated_at;
      this.tags = data.tags;
      this.item_tags = data.item_tags;
      this.modalTags = data.tags;
      this.owned = data.owned;
      this.points = data.points;
      this.favourited = data.favourited;
      this.author_image_url = data.author_image_url;
      this.modal_image_url = null;
      this.favourited_by_count = data.favourited_by_count;
      if (this.acl === "private" && data.image_url) {
        this.updateCover(data.image_url);
      } else {
        this.image_url = data.image_url;
        this.huge_image_url = data.huge_image_url;
      }
      if (cleanUpTags) {
        const newTags = data.item_tags.map((i) => i.uuid);
        this.resetTags(newTags, data.seo_id);
      }
      filtersStore.fetch = true
    } catch (e) {
      console.log(e);
      window.location.href = '/';
    } finally {
      this.isLoading = false;
    }
  };

  resetTags(newTags, id) {
    const includedTags = filtersStore.tags.filter((tag) => newTags.includes(tag) && !specialTags.includes(tag));
    filtersStore.page = 1;
    filtersStore.tags = includedTags;
    filtersStore.fetch = true;
    history.push(`/collection/${id}?${filtersStore.toParams()}`)
  }

  @action delete = async (id) => {
    this.isLoading = true

    let url = `${API_ROUTES.MY_COLLECTIONS}/${id}.json`
    const token = this.params.get('token')
    if (token) url = url + "?token=" + token
    const index = myCollectionsMenuStore.menu.findIndex(item => item.id === id);
    if (index !== -1) { myCollectionsMenuStore.menu.splice(index, 1); }

    try {
      await API.delete(url)
      await myCollectionsStore.fetch()
    } catch(e) {
      console.error(e)
      alert("Coudn't delete collection")
    } finally {
      this.isLoading = false
    }
  }

  updateCover = (url) => {
    API.get(url).then((res) => {
      this.image_url = res.data;
      this.huge_image_url = res.data;
    });
  }

  @action showCreateModal = () => {
    this.editMode = false
    this.modalTags = []
    modalStore.show('collection')
  }

  @action favourite = async () => {
    this.favourited_by_count += 1;
    this.favourited = true;
    try {
      let url = `${API_ROUTES.MY_COLLECTIONS}/${this.uuid}/favourite`;
      const token = this.params.get('token');
      if (token) url = url + "?token=" + token;
      const { data } = await API.post(url);
      var itemIndex = myCollectionsStore.collections.findIndex(i => i.id === data.id);
      if (itemIndex < 0) {
        var newCollections = [{...this}].concat(myCollectionsStore.collections.slice(0))
        myCollectionsStore.collections.replace(newCollections);
      }
    } catch (e) {
      console.log(e);
      this.favourited_by_count -= 1;
      this.favourited = false;
    }
  };

  @action unfavourite = async (collectionUuid = null) => {
    this.favourited_by_count -= 1;
    this.favourited = false;
    try {
      const { data } = await API.post(`${API_ROUTES.MY_COLLECTIONS}/${collectionUuid || this.uuid}/unfavourite`);
      var itemIndex = myCollectionsStore.collections.findIndex(i => i.id === data.id);
      var item = myCollectionsStore.collections[itemIndex];
      if ((item) && (!item.owned)) { myCollectionsStore.collections.splice(itemIndex, 1); }
    } catch (e) {
      console.log(e);
      this.favourited_by_count += 1;
      this.favourited = true;
    }
  };

  @action create = async (collection, acl = 'private') => {
    this.isLoading = true;
    try {
      this.modalTags.map(tag => collection.append("collection[tags][]", tag.uuid));
      this.modalTags.length === 0 && collection.append("collection[tags][]", []);
      collection.append("collection[acl]", acl);
      if (this.modal_image_url) collection.append("collection[user_image]", "1");
      let { data } = await API.post(API_ROUTES.MY_COLLECTIONS, collection)
      if (data.acl === "private" && data.image_url) {
        const res = await API.get(data.image_url)
        data.image_url = res.data
        data.huge_image_url = res.data
      }
      myCollectionsStore.collections.unshift(data)
      this.created = true;
      myCollectionsMenuStore.fetch()
      return { success: true, data: data }
    } catch (e) {
      console.log(e);
      return e;
    } finally {
      this.isLoading = false;
    }
  };

  @action update = async (collection, acl = 'private') => {
    this.isLoading = true;
    try {
      this.modalTags.map(tag => collection.append("collection[tags][]", tag.uuid));
      this.modalTags.length === 0 && collection.append("collection[tags][]", []);
      if (acl) collection.append("collection[acl]", acl);
      if (this.modal_image_url) collection.append("collection[user_image]", "1");
      const { data } = await API.put(`${API_ROUTES.MY_COLLECTIONS}/${this.id}`, collection);
      this.id = data.id;
      this.seo_id = data.seo_id;
      this.uuid = data.uuid;
      this.name = data.name;
      this.author = data.author;
      this.acl = data.acl;
      this.description = data.description || '';
      this.user_image = data.user_image;
      this.updated_at = data.updated_at;
      this.tags = data.tags;
      this.modalTags = data.tags;
      this.owned = data.owned;
      this.favourited = data.favourited;
      this.author_image_url = data.author_image_url;
      if (this.acl === "private" && data.image_url) {
        this.updateCover(data.image_url);
      } else {
        this.image_url = data.image_url;
        this.huge_image_url = data.huge_image_url;
      }
      this.updated = true;
      this.editMode = false;
      this.modal_image_url = null;
      myCollectionsStore.fetch()
      return { success: true, data: data }
    } catch (e) {
      console.log(e);
      return e;
    } finally {
      this.isLoading = false;
    }
  };
}

export default new collectionStore();

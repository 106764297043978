import React from 'react'
import { inject, observer } from 'mobx-react'
import { loopUrl } from '../../config/api'
import Modal from '../common/Modal'

@inject('backendErrorStore', 'modalStore')
@observer
class BackendErrorModal extends Modal {
  get title() { return 'Error' }

  get notFound() { return 'This product is currently unavailable' }

  get content() {
    const { backendErrorStore } = this.props;
    const text = backendErrorStore.text === 'Not found' ? this.notFound : backendErrorStore.text;
    return ( <p className='font-bold' dangerouslySetInnerHTML={{__html: text}} /> )
  }

  get buttons() {
    const { backendErrorStore } = this.props;
    const seePlansButton = backendErrorStore.buttons && backendErrorStore.buttons.see_plans;
    const okButton = (
      <button
          className={`${seePlansButton ? 'ml-2.5 px-4 grow' : 'w-full' } rounded-[40px] py-0.5 font-medium bg-orange-1 text-gray-16 text-[22px] tracking-tight`}
          onClick={this.close}
          type='button'
        >
        OK
      </button>
    )

    if (seePlansButton) {
      const plansLink = loopUrl + '/cloud/subscriptions/plans';
      return <>
        <button
          className={`px-4 grow border border-2 border-white rounded-[40px] py-0.5 font-medium text-[22px] tracking-tight`}
          onClick={() => { window.location = plansLink }}
          type='button'
        >
          See Plans
        </button>
        { okButton }
      </>
    }
    return okButton
  }

  close = () => {
    this.props.backendErrorStore.text = null
    this.props.backendErrorStore.buttons = null
    this.props.modalStore.hide()
  }
}

export default BackendErrorModal

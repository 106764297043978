import { action, extendObservable } from 'mobx';
import API from '../config/api';
import { API_ROUTES } from '../config/api_routes';
import { APIFunctions } from '../config/api';
import { AllHtmlEntities } from 'html-entities';
import sidebarStore from './sidebarStore';
import favouritedPacksStore from './favouritedPacksStore';
import menuStore from './menuStore';
import filtersStore from './filtersStore';
import WaveformHelper from './../helpers/WaveformHelper';

const entities = new AllHtmlEntities();

const initialState = {
  isLoading: false,
  isPackInfoLoading: false,
  id: null,
  seo_product_id: null,
  seo_label_id: null,
  title: '',
  label_name: 'Urban Toolkit',
  label_id: null,
  cover: '/sample/image.jpg',
  genre: '',
  points: 0,
  price: 0,
  pointsLeft: null,
  tags: [],
  description: '',
  demo_song_url: null,
  waveform_data: null,
  currentDescription: null,
  descriptionExpanded: false,
  tagsLoaded: false,
  owned_sum: null,
  highlight: false,
  error: null,
  cloud_shop: false,
  archived: null,
  favourited: false,
  temporaryPack: {},
  meta_title: null,
  meta_keyword: null,
  meta_description: null,
  purchased_product: false,
  download_token: null,
  from_cloud: false,
};

const dontResetKeys = [
  'archived', 'cloud_shop', 'favourited', 'temporaryPack'
]

export class packStore{
  constructor() {
    extendObservable(this, initialState);
  }

  @action toggleDescription = () => {
    this.descriptionExpanded = !this.descriptionExpanded;
  };

  @action reset = () => {
    Object.keys(initialState).forEach((key) => {
      if(!dontResetKeys.includes(key)) {
        this[key] = initialState[key];
      }
    });
  };

  desc() {
    if (this.descriptionExpanded) { return {__html: this.description}; }
    return { __html: this.convertTags(this.description, true, false) };
  }

  @action fetchPackInfo = async (params = {}) => {
    this.isPackInfoLoading = true;
    try {
      const errorMock = { data: { owned: null, points_left: null } };
      const { data } = await API.get(`${API_ROUTES.PACK_INFO}/${params.product_id}`).catch(e => { return errorMock });
      this.owned_sum = (data.owned || 0);
      this.pointsLeft = data.points_left;
      this.favourited = data.favourited || false;
      this.purchased_product = data.purchased_product;
      this.download_token = data.download_token
      this.from_cloud = data.from_cloud

    } catch (e) {
      console.log(e);
    } finally {
      this.isPackInfoLoading = false;
    }
    return this.owned_sum;
  };

  removeYouTubeIframe(xmlDoc) {
    if (!xmlDoc.documentElement.querySelector('iframe')) return xmlDoc;

    xmlDoc.documentElement.querySelectorAll('iframe').forEach(iframe => {
      if (iframe.getAttribute('src').includes('youtube.com')) iframe.remove();
    });
    return xmlDoc;
  }

  convertATags(xmlDoc) {
    if (!xmlDoc.documentElement.querySelector('a')) return xmlDoc;

    xmlDoc.documentElement.querySelectorAll('a').forEach(a => {
      const href = a.getAttribute('href');
      if (href.substring(0, 1) === '/') a.setAttribute('href', `https://www.loopmasters.com${href}`);
      a.setAttribute('target', '_blank');
      a.setAttribute('rel', 'noreferrer')
    });
    return xmlDoc;
  }

  convertTags(body, removeYouTube = false, convertAnchors = true) {
    const parser = new DOMParser();
    var xmlDoc = parser.parseFromString(`<!DOCTYPE html><html>${entities.decode(body)}</html>`, "text/html");
    if (!xmlDoc.documentElement.querySelector('iframe, a')) return body;

    if (removeYouTube) this.removeYouTubeIframe(xmlDoc);
    if (convertAnchors) this.convertATags(xmlDoc);
    return xmlDoc.documentElement.innerHTML;
  }

  @action fetch = async (id, fullFetch = true) => {
    var api = APIFunctions.createLoopAPI();

    this.isLoading = true;
    try {
      const { data } = await api.get(`${API_ROUTES.PACKS}?ids[]=${id}`);
      var waveforms;
      if (fullFetch) {
        waveforms = (await WaveformHelper.forIds([id])).data.waveforms;
      }

      const product = data.products[0];

      if (!product) {
        this.id = null
        return
      }

      this.id = product.id;
      this.archived = product.archived;
      this.seo_product_id = product.seo_product_id || product.id
      this.seo_label_id = product.seo_label_id || product.label_id
      this.title = product.title;
      this.label_name = product.label_name;
      this.label_id = product.label_id;
      this.cover = product.cover_small;
      this.cover_huge = product.cover_huge;
      this.genre = product.genre;
      this.cloud_shop = product.cloud_shop;
      this.points = product.points;
      this.price = product.price;
      this.description = this.convertTags(product.description);
      this.demo_song_url = product.demo_song_url;
      this.meta_title = product.meta_title
      this.meta_keyword = product.meta_keyword
      this.meta_description = product.meta_description


      if (fullFetch) {
        this.refreshSidebar();
        this.fetchTags();
        if (waveforms && waveforms[0]) { this.waveform_data = waveforms[0].waveform_data; }
      }
    } catch (e) {
      console.error(e);

    } finally {
      this.isLoading = false;
    }
  };

  @action fetchTemporary = async (id) => {
    var api = APIFunctions.createLoopAPI();

    this.isLoading = true;
    try {
      const { data } = await api.get(`${API_ROUTES.PACKS}?ids[]=${id}`);
      const product = data.products[0];
      if (!product) {
        this.id = null
        return
      }

      this.temporaryPack.id = product.id;
      this.temporaryPack.seo_product_id = product.seo_product_id || product.id
      this.temporaryPack.points = product.points;
      this.temporaryPack.price = product.price;
      this.temporaryPack.archived = product.archived;
      this.temporaryPack.cloud_shop = product.cloud_shop;
    } catch (e) {
      console.error(e);

    } finally {
      this.isLoading = false;
    }
  }

  @action fetchTemporaryPackInfo = async (params = {}) => {
    this.isPackInfoLoading = true;
    try {
      const errorMock = { data: { owned: null, points_left: null } };
      const { data } = await API.get(`${API_ROUTES.PACK_INFO}/${params.product_id}`).catch(e => { return errorMock });
      this.temporaryPack.owned_sum = (data.owned || 0);
      this.temporaryPack.pointsLeft = data.points_left;
    } catch (e) {
      console.log(e);
    } finally {
      this.isPackInfoLoading = false;
    }
    return this.owned_sum;
  };

  getPlayingPackInfo = (currentlyPlaysId) => {
    if(!currentlyPlaysId) {
      return {}
    }

    if (this.isLoading || this.isPackInfoLoading) {
      if(this.temporaryPack.id === currentlyPlaysId) {
        return this.temporaryPack
      }

      return this
    }

    let info = {}
    if(this.id === currentlyPlaysId) {
      info = this
    } else if(this.temporaryPack.id === currentlyPlaysId) {
      info = this.temporaryPack
    } else {
      this.fetchTemporaryPackInfo({product_id: currentlyPlaysId});
      this.fetchTemporary(currentlyPlaysId)
      info = this.temporaryPack
    }

    return info
  }

  refreshSidebar = () => {
    const genreTag = menuStore.all.find(tag => tag.name === this.genre);
    if (!genreTag) return false;

    if (genreTag.parent_id && genreTag.parent_id != 1) {
      const ancestor = menuStore.all.find(tag => tag.id === genreTag.parent_id);
      sidebarStore.refresh(ancestor.uuid);
    } else {
      sidebarStore.refresh(genreTag.uuid);
    }
  }

  @action fetchTags = async () => {
    if (!this.id || this.tagsLoaded) { return true; }
    this.isLoading = true;
    try {
      var url = `${API_ROUTES.TAGS_FOR_PRODUCT}?product_id=${this.id}`;
      const { data } = await API.get(`${API_ROUTES.TAGS_FOR_PRODUCT}?product_id=${this.id}`);
      this.tags.replace(data.tags);
      if (data.drum_play_tag) {
        filtersStore.drumOrPlay = true;
        filtersStore.wave = false;
        filtersStore.page = 1;
      } else {
        filtersStore.drumOrPlay = false;
        filtersStore.wave = (typeof(window.waveButton) !== "undefined") ? window.waveButton : true;
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.tagsLoaded = true;
      this.isLoading = false;
      filtersStore.fetch = true;
    }
  };

  @action favourite = async () => {
    try {
      if (favouritedPacksStore.ids.findIndex(i => i === this.id) >= 0) { return false }

      await API.post(`${API_ROUTES.PACK}/favourite`, { id: this.id });
      if (favouritedPacksStore.ids && favouritedPacksStore.ids.length > 0) {
        // only adding if favourites already fetched. otherwise it causes incorrect favourites page display
        favouritedPacksStore.ids.replace([this.id].concat(favouritedPacksStore.ids.slice(0)))
      }
      favouritedPacksStore.page = 1;
      favouritedPacksStore.fetchUserFavourites(1, true);
      this.favourited = true
    } catch (e) {
      console.log(e)
    }
  };

  @action unfavourite = async () => {
    try {
      favouritedPacksStore.unfavourite(this.id);
      this.favourited = false;
    } catch (e) {
      console.log(e);
    }
  };
}

export default new packStore();

import { action, extendObservable } from 'mobx';
import API from '../config/api';
import { APIFunctions } from '../config/api';
import { API_ROUTES } from '../config/api_routes';
import filtersStore from './filtersStore'
import menuStore from './menuStore'
import WaveformHelper from './../helpers/WaveformHelper';

const initialState = {
  isLoading: false,
  error: null,
  packs: [],
  labels: [],
  banners: [],
  tag: null // this sidebar is for this tag
};

export class sidebarStore{
  constructor() {
    extendObservable(this, initialState);
  }

  @action reset = () => {
    Object.keys(initialState).forEach((key) => {
      this[key] = initialState[key];
    });
  };

  fetchProducts = async (ids) => {
    this.isLoading = true;
    var api = APIFunctions.createLoopAPI();
    try {
      const { data } = await api.post(API_ROUTES.PACKS, {
        ids: ids
      });
      const { waveforms } = (await WaveformHelper.forIds(ids)).data;
      let packs = [];
      let product;
      ids.forEach(function(id) {
        product = data.products.find(function(product) {
          return id == product.id
        })
        if(product) {
          const waveform = (waveforms || []).find(waveform => product.id == waveform.product_id);
          packs.push(WaveformHelper.merge(product, waveform));
        }
      })
      this.packs.replace(packs)
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }
  }

  fetchLabels = async (labels) => {
    const findLabelTag = (uuid) => menuStore.all.find(t => t.uuid === uuid && t.kind === 'labels');
    if (filtersStore.tags.find(findLabelTag)) {
      this.labels.replace([]);
      return true;
    }
    this.isLoading = true;
    var api = APIFunctions.createLoopAPI();
    try {
      let params = labels.map((label) => `ids[]=${label.id}`).join('&');
      let path = `${API_ROUTES.LABELS}?${params}`
      const { data } = await api.get(path);

      const loopLabels = labels.map((label) => {
        return data.find((l) => l.id == label.id)
      })

      this.labels.replace(loopLabels);
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }
  }

  @action fetch = async (params = {}) => {
    this.isLoading = true;

    try {
      let path = `${API_ROUTES.SIDEBAR}`
      const { data } = await API.get(path, { params } );
      this.banners = data.banners
      this.fetchLabels(data.bestseller_labels)
      this.fetchProducts(data.bestsellers)

    } catch (e) {
      console.error(e);

    } finally {
      this.isLoading = false;
    }
  };

  // refresh sidebar if filter tags changed
  @action refresh = async (tag_uuid = null) => {
    const first = (t) => t.length && t[0] || null
    const uuid = tag_uuid || first(filtersStore.tags) || first(filtersStore.and_tags)
    const old = this.tag
    console.debug("sidebarStore#refresh, old: ", old, "new: ", uuid)

    if (old !== uuid) {
      console.debug("sidebarStore#refresh, refreshing because of a change")
      this.tag = uuid
      return this.fetch({ tag: uuid })
    }

    console.debug("sidebarStore#refresh, nothing changed, won't refresh the sidebar")
  }
}

export default new sidebarStore();

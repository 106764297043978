import React from 'react'

import HamburgerIcon from '#images/hamburger.svg'

export default function HamburgerButton(props) {
  const { toggleMobileMenuHover, toggleMobileMenu } = props
  return (
    <li
      className='2xl:hidden'
      onMouseEnter={toggleMobileMenuHover}
      onMouseLeave={toggleMobileMenuHover}
    >
      <div
        onClick={toggleMobileMenu}
      >
        <img
          src={HamburgerIcon}
          className='pl-9.5 cursor-pointer'
        />
      </div>

      { props.children }
    </li>
  )
}

import { action, extendObservable } from 'mobx'
import modalStore from './modalStore'

const initialState = {
  selection: false
};

export class notEnoughPointsStore{
  constructor() {
    extendObservable(this, initialState);
  }

  @action show = () => {
    this.selection = false
    modalStore.show('notEnoughPoints')
  }
}

export default new notEnoughPointsStore();

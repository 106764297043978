import axios from 'axios';
import { action, extendObservable } from 'mobx';

const initialState = {
  macApp: "https://www.loopmasters.com/cloud/autoupdate/mac/dev/versions/Install%20Loopcloud%205.0.32%20Mac.pkg.zip",
  winApp: "https://www.loopmasters.com/cloud/autoupdate/win/dev/versions/Install%20Loopcloud%205.0.32%20Windows.exe",
  ready: false
};

const appServer = { production: 'https://www.loopmasters.com' }

export class AppLinksStore{
  constructor() {
    extendObservable(this, initialState);
  }

  @action update = async () => {
    const parser = new DOMParser();
    const api = axios.create({ baseURL: appServer[process.env.NODE_ENV] || 'https://www.loopmasters.com' });
    var data, response, xmlDoc, url;
    try {
      response = await api.get("/cloud/autoupdate/win/release/appcast.xml");
      data = response.data;
      if (data && data.length) {
        xmlDoc = parser.parseFromString(data, "application/xml");
        this.winApp = xmlDoc.documentElement.querySelector('channel item enclosure').getAttribute('url');
      }
      response = await api.get("/cloud/autoupdate/mac/release/appcast.xml");
      data = response.data;
      if (data && data.length) {
        xmlDoc = parser.parseFromString(data, "application/xml");
        this.macApp = xmlDoc.documentElement.querySelector('channel item enclosure').getAttribute('url');
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.ready = true;
    }
  }
}

export default new AppLinksStore();

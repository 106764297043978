import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { seoPermalink } from '../../helpers/seo.js'

class GenreTile extends Component {
  get linkToGenre() {
    const { genre } = this.props

    return `/genre/${seoPermalink(genre)}`
  }

  get packsCount() {
    const { count, genre } = this.props
    return count || genre.total_products || genre.ids.length
  }

  render() {
    return (
      <Link to={this.linkToGenre}>
        <div
          className='flex flex-col items-center justify-between bg-gray-15 min-w-[230px] min-h-[120px] max-h-[120px] pt-6 pb-5 cursor-pointer px-2'
        >
          <p className='text-[22px] font-medium'>{this.props.genre.name}</p>
          <p className='text-center'>
            <span className='text-gray-3'>Packs </span>
            <span className='font-bold'>{this.packsCount}</span>
          </p>
        </div>
      </Link>
    )
  }
}

export default GenreTile;

import { action, extendObservable } from 'mobx'
import successStore from './successStore'
import playlistStore from './playlistStore'
import filtersStore from './filtersStore'
import cloudItemsStore from './cloudItemsStore'
import backendErrorStore from './backendErrorStore'
import packStore from './packStore'
import infoStore from './infoStore'
import collectionStore from './collectionStore'
import API from '../config/api'
import { API_ROUTES } from '../config/api_routes'
import modalStore from './modalStore'

const initialState = {
  collection: false,
  uuid: null,
  productId: null
};

export class processingStore{
  constructor() {
    extendObservable(this, initialState);
  }

  @action buy = async (collection = false) => {
    modalStore.show('processing')
    var paramsObj = {}
    if (collection) {
      paramsObj['collection_uuid'] = this.uuid;
    } else {
      paramsObj['cloud_item'] = { uuid: this.uuid };
    }
    try {
      const { data } = await API.post(API_ROUTES.BUY, paramsObj);
      let ids = data['cloud_items'].map((i) => i.id);
      successStore.items.replace(data.cloud_items);
      playlistStore.remove(ids);
      playlistStore.updateCurrentlyPlaysIfBought(data.cloud_items);
      modalStore.show('success')
      let packItem = data['cloud_items'].find((item) => item.product_id === packStore.id);
      if(packStore.id && packItem) {
        await packStore.fetchPackInfo({product_id: packStore.id});
        await infoStore.update({product_id: packStore.id});
      }
      if (collectionStore.id) {
        await collectionStore.fetch(collectionStore.id);
      }
    } catch (e) {
      console.log(e);
      backendErrorStore.showModal(e.error.message, e.error.options);
    } finally {
      this.uuid = null;
      this.collection = false;
    }
  };

  @action buyProduct = async () => {
    modalStore.show('processing')
    var paramsObj = {product_id: this.productId};
    try {
      const { data } = await API.post(API_ROUTES.BUY_PRODUCT, paramsObj);
      cloudItemsStore.cloudItems.replace([]);
      filtersStore.fetch = true;
      packStore.fetchPackInfo(paramsObj);
      infoStore.update(paramsObj);
      playlistStore.remove(null, paramsObj);
      playlistStore.updateCurrentlyPlaysIfProductBought(this.productId)
      playlistStore.sync();
      modalStore.show('success')
    } catch (e) {
      console.log(e);
      backendErrorStore.showModal(e.error.message, e.error.options);
    } finally {
      this.productId = null;
    }
  };
}

export default new processingStore();

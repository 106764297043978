import { extendObservable } from 'mobx';

const initialState = {
  productId: null,
  searchPage: false,
  packOnlyItem: true,
  freePack: false
};

export class buyPackItemStore {
  constructor() {
    extendObservable(this, initialState);
  }
}

export default new buyPackItemStore();
import { extendObservable } from 'mobx';

const initialState = {
  items: []
};

export class successStore{
  constructor() {
    extendObservable(this, initialState);
  }
}

export default new successStore();

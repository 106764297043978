import { action, extendObservable } from 'mobx';

const initialState = {
  visible: false
};

export class flashBoxStore {
  constructor() {
    extendObservable(this, initialState);
  }
}

export default new flashBoxStore();

import React, { Component } from 'react';
import history from "./history.js";
import { inject, observer } from 'mobx-react';
import InputRange from "react-input-range"
import "react-input-range/lib/css/index.css";

@inject('filtersStore', 'menuStore')
@observer
class BpmFilter extends Component {
  loc() {
    const path = window.location.pathname
    return path === '/favourites' ? path : '/search'
  }

  filter = () => {
    const { filtersStore } = this.props
    filtersStore.loadBpm()
    history.push(`/search?${filtersStore.toParams()}`)
  }

  clear = () => {
    const { filtersStore } = this.props
    filtersStore.clearBpm()

    const params = filtersStore.toParams()
    let path = this.loc()
    const blank = filtersStore.isBlank()
    if (blank && path === '/search') path = '/'
    history.push(`${path}?${params}`);
  }

  ensureValid(value) {
    const { bpmRange } = this.props.menuStore
    if (value < bpmRange.min) return bpmRange.min;
    if (value > bpmRange.max) return bpmRange.max;
    return value
  }

  setBpm(value) {
    this.props.filtersStore.bpm = {
      min: this.ensureValid(value.min),
      max: this.ensureValid(value.max)
    }
  }

  render() {
    const { bpmRange } = this.props.menuStore
    const { hasBpm, bpm } = this.props.filtersStore
    const currentBpmRange = {
      min: parseInt(hasBpm ? bpm.min : bpmRange.min),
      max: parseInt(hasBpm ? bpm.max : bpmRange.max)
    }

    return (
      <div className='flex flex-col ml-16 grow max-w-[40%]'>
        <p className='text-[23px] font-bold'>BPM</p>
        <div className='flex items-center'>
          <span className='text-base text-gray-3'>{ currentBpmRange.min }</span>
          <div className='flex grow py-8 mx-5'>
            <InputRange
              minValue={ parseInt(bpmRange.min) }
              maxValue={ parseInt(bpmRange.max) }
              onChange={ value => this.setBpm(value) }
              onChangeComplete={ this.filter }
              value={ currentBpmRange }
            />
          </div>
          <span className='text-base text-gray-3'>{ currentBpmRange.max }</span>
        </div>
        <div>
          {
            hasBpm && (
              <button type='button' onClick={this.clear} className='text-base font-normal text-orange-1'>
                Clear
              </button>
            )
          }
        </div>
      </div>
    )
  }
}

export default BpmFilter

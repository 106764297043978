import React, { Component } from 'react';
import UserAction from '../../helpers/UserAction';

class Logout extends Component {
  logout() {
    UserAction.logout();
  }

  render() {
    this.logout();
    return (<div></div>);
  };
}

export default Logout;

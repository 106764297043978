import React, { Component } from 'react';
import HomeBrowser from '../home/HomeBrowser';
import HomeLabelsBrowser from './HomeLabelsBrowser';
import HomeGenresBrowser from './HomeGenresBrowser';
import HomeCollectionsBrowser from './HomeCollectionsBrowser';
import { inject, observer } from 'mobx-react';
import sidebarStore from '../../stores/sidebarStore'
import { seoPermalink } from '../../helpers/seo.js'

@inject('homeStore')
@observer
class HomePage extends Component {
  componentDidMount() {
    this.props.homeStore.reset()
    this.props.homeStore.fetch({ perPage: 30 })
    window.scrollTo(0, 0)
    sidebarStore.refresh()
  }

  renderUserGenres(start = 0, end = 2) {
    return this.props.homeStore.userGenres.slice(start, end).map(genre =>
      <HomeBrowser
        genreId={genre.id}
        key={genre.id}
        showAll={true}
        min={2}
        genreSeoId={`${seoPermalink(genre)}`}
      />
    );
  }

  renderBeatportBrowsers() {
    return (
      <div className='flex flex-col pl-5 lg:pl-8 pt-5 min-[1350px]:pl-12 min-[1350px]:pt-8'>
        <HomeCollectionsBrowser title='Collections' showAll={true} min={2} />
        { this.renderUserGenres(0, 10) }
        <HomeLabelsBrowser title='Labels' min={2} />
        <HomeGenresBrowser title='Genres' min={2} />
      </div>
    );
  }

  render() {
    const { homeStore } = this.props

    if (sessionStorage.getItem('beatport_ref')) {
      return this.renderBeatportBrowsers();
    }

    return (
      <div className='flex flex-col pl-5 lg:pl-8 pt-5 min-[1350px]:pl-12 min-[1350px]:pt-8'>
        <HomeBrowser collectionName='popularPacks' showAll={true} min={2} />
        <HomeCollectionsBrowser title='Collections' showAll={true} min={2} />
        <HomeBrowser collectionName='labelFocus' showAll={true} min={2} labelSeoId={homeStore.labelFocus.labelSeoId } />
        <HomeBrowser collectionName='featuredReleases' showAll={true} min={2} />
        <HomeBrowser collectionName='synthPresets' showAll={true} min={2} />
        { this.renderUserGenres() }
        <HomeBrowser collectionName='artistSeries' showAll={true} min={2} />
        <HomeBrowser collectionName='drumPlugins' showAll={true} min={2} />
        <HomeBrowser collectionName='playPlugins' showAll={true} min={2} />
        { this.renderUserGenres(2, 4) }
        <HomeLabelsBrowser title='Labels' min={2} />
        <HomeGenresBrowser title='Genres' min={2} />
      </div>
    );
  }
}

export default HomePage;

import { action, extendObservable } from 'mobx';
import API from '../config/api';
import { API_ROUTES } from '../config/api_routes';
import packsStore from './packsStore';

const initialState = {
  ids: [],
  favs: [],
  isLoading: false,
  pageCount: 1,
  page: 1,
  loaded: false,
};

const perPage = 60;

export class favouritedPacksStore {
  constructor() {
    extendObservable(this, initialState);
  }

  @action reset = () => {
    Object.keys(initialState).forEach((key) => {
      this[key] = initialState[key];
    });
  };

  fetchUserFavourites = async (page = 1, force = false) => {
    try {
      this.isLoading = true;
      if (force || this.ids.length == 0) {
        const { data } = await API.get(API_ROUTES.FAVOURITED_PACKS);
        this.ids = data.products;
        this.pageCount = Math.ceil(this.ids.length / perPage)
      }
      const start = (page - 1) * perPage;
      const pageIds = this.ids.slice(start, start + perPage);
      await packsStore.fetchFavouritedById(pageIds);
      this.loaded = true

    } catch (e) {
      console.error(e);
    } finally {
      this.loaded = true
      this.isLoading = false;
    }
  }

  @action unfavourite = async (id) => {
    try {
      await API.post(`${API_ROUTES.UNFAVOURITES_PACKS}`, { id });

      let itemIndex = this.ids.findIndex(i => i === id);
      itemIndex >= 0 && this.ids.splice(itemIndex, 1)
      itemIndex = this.favs.findIndex(i => i.id === id);
      itemIndex >= 0 && this.favs.splice(itemIndex, 1);
      this.page = 1;
      this.fetchUserFavourites(1, true);
    } catch (e) {
      console.log(e);
    }
  };
}

export default new favouritedPacksStore();

import React from 'react'
import { inject, observer } from 'mobx-react'
import Modal from '../common/Modal'
import WhitePoint from '#images/white_point.svg'

@inject('confirmationStore', 'modalStore')
@observer
class ConfirmationModal extends Modal {
  get title() {
    return 'Confirm Purchase'
  }
  get content() {
    const { confirmationStore } = this.props
    return (
      <div className='flex flex-col text-lg'>
        { confirmationStore.pack && <span className='mb-5'>You are about to buy a Pack</span> }

        {
          !confirmationStore.collection && !confirmationStore.pack && (
            <span className='mb-5'>
              You are about to buy {this.files.length} item{this.files.length > 1 ? 's' : ''}
            </span>
          )
        }

        {
          confirmationStore.collection && (
            <div className='mb-5'>
              <p>You are about to buy a collection:</p>
              <p className='font-bold'>{confirmationStore.files[0]}</p>
            </div>
          )
        }
        <div className='flex'>
          <span>Price: <span className='ml-1 font-bold'>{confirmationStore.points}</span></span>
          <img src={WhitePoint} />
        </div>
      </div>
    )
  }

  get buttons() {
    return (
      <>
        <button
          className='px-9 border border-2 border-white rounded-[40px] py-0.5 font-medium text-[22px] tracking-tight'
          onClick={this.cancel}
          type='button'
        >
          Cancel
        </button>
        <button
          className='ml-2.5 px-9 rounded-[40px] py-0.5 font-medium bg-orange-1 text-gray-16 text-[22px] tracking-tight'
          onClick={this.showProcessing}
          type='button'
        >
          Buy
        </button>
      </>
    )
  }

  cancel = () => {
    this.props.confirmationStore.cancel()
  }

  showProcessing = () => {
    this.props.confirmationStore.showProcessing()
  }

  get files() {
    return this.props.confirmationStore.files
  }
}

export default ConfirmationModal

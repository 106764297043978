import { Provider } from 'mobx-react';
import * as stores from './stores';
import React from 'react';
import ReactDOM from 'react-dom';
import 'normalize.css';
import './index.css';

import App from './App';
import { ThemeProvider } from 'emotion-theming';
import theme from './theme';

import reportWebVitals from './reportWebVitals';

const root = document.getElementById('root')
const modalHolder = document.querySelector('#modal-root')

ReactDOM.render(
  <Provider {...stores}>
    <ThemeProvider theme={theme}>
      <App root={root} modalHolder={modalHolder} />
    </ThemeProvider>
  </Provider>,
  root
);

reportWebVitals()

import { action, extendObservable } from 'mobx';
import { APIFunctions } from '../config/api';
import { API_ROUTES } from '../config/api_routes';
import favouritedPacksStore from './favouritedPacksStore';
import WaveformHelper from './../helpers/WaveformHelper';

const initialState = {
  page: 1,
  products: [],
  product_ids: [],
  per_page: 60,
  pages_count: 0,
  bottomMargin: 0,
  isLoading: false,
  byId: {} // product id => product's data
};

const itemHeight = 256;

export class packsStore {
  constructor() {
    extendObservable(this, initialState);
  }

  calculateBottomMargin() {
    let per = this.per_page;
    this.pages_count = Math.ceil(this.product_ids.length / per);

    this.bottomMargin = (this.pages_count - this.page) * itemHeight * 3;
  }

  getCover(productId) {
    const data = this.byId[productId]
    if (data) {
      // console.debug("packsStore#getCover, got byId:", productId)
      return data.cover_small

    } else {
      // console.debug("packsStore#getCover, not found byId:", productId)
    }

    var product = this.products.find( (item) => { return item.id === productId });
    if (product) {
      return product.cover_small
    } else {
      return null;
    }
  };

  getSeoProductId(productId) {
    const data = this.byId[productId]
    const seoId = data && data.seo_product_id
    if (seoId) return seoId;

    const product = this.products.find(item => item.id === productId);
    return (product && product.seo_product_id) || productId
  }

  joinWaveforms(products, waveforms) {
    if (!waveforms) { return products; }
    return products.map((product) => {
      const waveform = (waveforms || []).find(waveform => product.id == waveform.product_id);
      return WaveformHelper.merge(product, waveform);
    });
  }

  @action reset = () => {
    Object.keys(initialState).forEach((key) => {
      this[key] = initialState[key];
    });
  };

  @action fetch = (params = {}) => {
    return this.products;
  };

  @action fetchFavouritedById = async (ids) => {
    if (ids.length === 0) return;

    const toFill = ids.filter(id => !this.byId[id])
    try {
      if (toFill.length) {
        const api = APIFunctions.createLoopAPI()
        const { waveforms } = (await WaveformHelper.forIds(toFill)).data;
        const { data } = await api.post(API_ROUTES.PACKS, { ids: toFill})
        const favs = this.joinWaveforms(data.products, waveforms);
        favs.forEach(item => this.byId[item.id] = item)
      }
      const temp = []
      ids.forEach(id => { if (this.byId[id]) temp.push({...this.byId[id]}) })
      favouritedPacksStore.favs.replace(temp)
    } catch (e) {
      console.error(e)
    } finally {
      favouritedPacksStore.isLoading = false;
    }
  }

  // ids - to byId map
  // products - to products list
  @action fillProducts = async (params = {}) => {
    console.debug("packsStore#fillProducts", params)
    const { ids = [], products = [] } = params

    this.isLoading = true;
    const set = new Set(ids.concat(products))

    const toFill = [...set].filter(id => !this.byId[id])
    try {
      if (toFill.length) {
        console.debug("packsStore#fillProducts, filling: ", toFill)
        const api = APIFunctions.createLoopAPI()
        const { waveforms } = (await WaveformHelper.forIds(toFill)).data;
        const { data } = await api.post(API_ROUTES.PACKS, {
          ids: toFill
        })
        const newProducts = this.joinWaveforms(data.products, waveforms);
        newProducts.forEach(item => this.byId[item.id] = item)
      }

      // fill products
      if (products.length) {
        console.debug("packsStore#fillProducts, setting products: ", products)
        const temp = []
        products.forEach(id => {
          if (this.byId[id]) temp.push(this.byId[id])
        })
        this.products.replace(temp)

        console.debug("packsStore#fillProducts, products set to: ", this.products)
      }

      this.calculateBottomMargin()
    } catch (e) {
      console.error(e)

    } finally {
      this.isLoading = false
    }
  }
}

export default new packsStore();
